import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocationsComponent } from "./components/locations/locations.component";
import { AscFooterComponent } from "./components/asc-footer/asc-footer.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FooterMetaComponent } from "./components/footer-meta/footer-meta.component";
import { FooterAthomeComponent } from "./components/footer-athome/footer-athome.component";
import { SharedModule } from "../shared/shared.module";
import { TrustIconsComponent } from "./components/trust-icons/trust-icons.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterDesignForceComponent } from "./components/footer-design-force/footer-design-force.component";
import { FooterAnthonyComponent } from "./components/footer-anthony/footer-anthony.component";

@NgModule({
	declarations: [
		LocationsComponent,
		AscFooterComponent,
		FooterComponent,
		FooterMetaComponent,
		FooterAthomeComponent,
		TrustIconsComponent,
		FooterDesignForceComponent,
		FooterAnthonyComponent,
	],
	imports: [CommonModule, NgbModule, SharedModule],
	exports: [FooterMetaComponent, LocationsComponent],
})
export class FooterModule {}

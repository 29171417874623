import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ListingService } from "@core/app/listing/services/listing.service";
import { RestService, Service } from "@core/app/rest.service";
import { IPageData } from "@model/page-data";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { filter, first, map, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class RvInventoryService {
	private service: Service;

	constructor(
		private listingService: ListingService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		rest: RestService,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.service = rest.init("statement", "Condition");
	}

	createState(useOtherListings = false) {
		return new RvFilterState(
			this.listingService,
			this.location,
			this.pageData,
			this.router,
			this.service,
			this.route,
			useOtherListings,
		);
	}
}

export class RvFilterState {
	private snapshot$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		startWith(null),
		map(() => {
			let child = this.route.firstChild;
			while (child) {
				if (child.firstChild) {
					child = child.firstChild;
				} else if (child.snapshot) {
					return child.snapshot;
				} else {
					return null;
				}
			}
			return null;
		}),
	);
	private statement$ = this.snapshot$.pipe(map((snapshot) => snapshot!.data.routeData.stmt));

	private metaInfo$ = this.statement$.pipe(
		map((statement) => (statement && statement.success ? statement.results[0] : {})),
	);

	// Map to routeData.params and add url
	readonly routeParams$ = this.snapshot$.pipe(
		map((snapshot) => {
			if (snapshot!.data.routeData) {
				const ret = { ...snapshot!.data.routeData.params, url: this.location.path().split("?")[0] };

				const brandid =
					snapshot!.data.routeData.stmt &&
					snapshot!.data.routeData.stmt.results &&
					snapshot!.data.routeData.stmt.results[0] !== null
						? snapshot!.data.routeData.stmt.results[0].brandid
						: null;
				if (brandid) {
					ret.brandid = brandid;
				}

				const model_numid =
					snapshot!.data.routeData.stmt &&
					snapshot!.data.routeData.stmt.results &&
					snapshot!.data.routeData.stmt.results[0] !== null
						? snapshot!.data.routeData.stmt.results[0].model_numid
						: null;
				if (model_numid) {
					ret.model_numid = model_numid;
				}

				return ret;
			} else {
				return {};
			}
		}),
	);

	readonly filters$ = combineLatest(this.snapshot$, this.metaInfo$, this.routeParams$).pipe(
		map(([snapshot, metaInfo, routeParams]) => {
			const filters = { ...snapshot!.queryParams, ...routeParams };

			if (filters.price) {
				filters.price_range_code = filters.price;
				delete filters.price;
			}

			if (filters.floorplan) {
				filters.vehicle_section = filters.floorplan;
				delete filters.floorplan;
			}

			// somehow brand interferes with the GetListings statement
			delete filters.brand;
			delete filters.vehicle_type;

			if (metaInfo) {
				if (metaInfo.conditionid) {
					filters.conditionid = metaInfo.conditionid;
				}

				if (metaInfo.brandid && !filters.brandid) {
					filters.brandid = metaInfo.brandid;
				}

				if (metaInfo.model_numid && !filters.model_numid) {
					filters.model_numid = metaInfo.model_numid;
				}

				if (metaInfo.vehicle_typeid && !filters.vehicle_typeid) {
					filters.vehicle_typeid = metaInfo.vehicle_typeid;
				}
			}

			if (!filters.condition && !filters.conditionid && !filters.promotion && !filters.promotionid) {
				filters.condition = "new";
			}

			return filters;
		}),
	);

	loadingListingsBS = new BehaviorSubject(false);
	readonly loadingListings$ = this.loadingListingsBS;

	private listingsResponseBS: ReplaySubject<IListingsResponse> = new ReplaySubject(1);

	private currentPageBS = new BehaviorSubject(1);
	readonly currentPage$ = this.currentPageBS.asObservable();

	private perPageBS = new BehaviorSubject(18);
	readonly perPage$ = this.perPageBS.asObservable();

	private fromBS = new BehaviorSubject(0);
	readonly from$ = this.fromBS.asObservable();

	private toBS = new BehaviorSubject(18);
	readonly to$ = this.toBS.asObservable();

	private totalBS = new BehaviorSubject(0);
	readonly total$ = this.totalBS.asObservable();

	response$: Observable<IListingsResponseContent> = this.listingsResponseBS.pipe(
		// If the total for the response is none, switches to other listings
		switchMap((res) => {
			const {
				queries: { GetListings: response },
			} = res;
			const { total, current_page } = response;
			if (total === 0 && current_page === 1 && this.useOtherListings) {
				return this.doLoadOtherListings();
			} else {
				return of(res);
			}
		}),
		// Pull out the GetListings query result
		map((res) => res.queries.GetListings),
		// Toggle loading to false
		tap(() => this.loadingListingsBS.next(false)),
		// Pump data into some of the state streams
		tap((res) => {
			this.currentPageBS.next(res.current_page);
			this.perPageBS.next(res.per_page);
			this.fromBS.next(res.from);
			this.toBS.next(res.to);
			this.totalBS.next(res.total);
		}),
		// Only want this to happen once, so we share replay it
		shareReplay(1),
	);

	lastPage$ = this.response$.pipe(map((res) => res.last_page));

	results$ = this.response$.pipe(
		// Prepare the results for the grid list
		map((response) => this.prepareResults(response.results)),
	);

	constructor(
		private listingService: ListingService,
		private location: Location,
		private pageData: IPageData,
		private router: Router,
		private service: Service,
		private route: ActivatedRoute,
		private useOtherListings: boolean,
	) {}

	doLoadOtherListings(): Observable<IListingsResponse> {
		return this.snapshot$.pipe(
			map((snapshot) => {
				const vars: any = { limit: 6 };
				const uniquePhotos = this.pageData.uniquePhotos;
				if (uniquePhotos === "true") {
					vars.uniquePhotos = true;
				}
				const routeParams = snapshot!.data.routeData.params;
				if (routeParams.conditionid) {
					vars.conditionid = routeParams.conditionid;
				} else if (routeParams.condition) {
					vars.condition = routeParams.condition;
				} else if (routeParams.promotion) {
					vars.promotion = routeParams.promotion;
				} else if (routeParams.promotionid) {
					vars.promotionid = routeParams.promotionid;
				}
				return vars;
			}),
			first(),
			switchMap((vars) => {
				return this.service
					.post$("GetListings", { vars: vars }, "NoResults:" + JSON.stringify({ vars: vars }))
					.pipe(
						map((res: any) => ({
							...res,
							queries: {
								GetListings: {
									results: [...res.results],
									current_page: 1,
									last_page: 1,
									per_page: 18,
									total: res.results.length,
									from: 1,
									to: res.results.length,
								},
							},
						})),
					);
			}),
		);
	}

	doSearch(limit = 18) {
		this.filters$
			.pipe(
				take(1),
				switchMap((filters) => {
					const filterData = { ...filters };

					let offset = 0;
					if (filterData.page !== undefined) {
						offset = (filterData.page - 1) * limit;
					}

					const dependentFilterData: any = {
						queries: [{ id: "GetListings", vars: { limit, offset, uniquePhotos: true } }],
						global: {
							vars: filterData,
						},
					};

					// Need to make sure the server checks for this same error. Can't allow the client
					// to control this logic. Must be reproduced on the server too.
					if (dependentFilterData.queries[0].page < 1) {
						dependentFilterData.queries[0].page = 1;
					}

					this.loadingListingsBS.next(true);

					return this.service.post$(
						"m",
						dependentFilterData,
						"Listings:" + JSON.stringify(dependentFilterData),
					);
				}),
				tap((res: any) => this.listingsResponseBS.next(res)),
			)
			.subscribe();
	}

	/**
	 * Copied this from previous code. Not sure why all the formatting.
	 *
	 * @param results: The results from `GetListings` statement.
	 */
	prepareResults(results: any) {
		const formattedResults = [];
		for (const row of results) {
			formattedResults.push(this.listingService.makeCardObject(row));
		}
		return formattedResults;
	}

	setPage(page: number) {
		this.currentPageBS.next(page);
	}
}

export interface IListingsResponseContent {
	current_page: number;
	from: number;
	last_page: number;
	per_page: number;
	results: any[];
	success: boolean;
	to: number;
	total: number;
}

export interface IListingsResponse {
	queries: {
		GetListings: IListingsResponseContent;
	};
}

import { Component, Input } from "@angular/core";

@Component({
	selector: "cm-address",
	template: `
		<span *ngIf="address.addressTitle" [innerHTML]="address.addressTitle"></span>
		<span>
			<span>{{ address.streetLine1 }}</span
			>,
			<span *ngIf="address.streetLine2"> {{ address.streetLine2 }}, </span>
			<br class="mobile-only" />
			<span>{{ address.city }}</span
			>, <span>{{ address.state }}</span
			>&nbsp; <span>{{ address.zip }}</span
			>,
			<span>{{ address.country }}</span>
		</span>
	`,
	styleUrls: ["./address.component.scss"],
})
export class AddressComponent {
	@Input() address: any = {};
}

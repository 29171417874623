import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";
import { IGetLocations } from "@model/stmt/GetLocations";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-footer-design-force",
	template: `
		<div class="footer-design-force" itemscope itemtype="http://schema.org/WPFooter">
			<img src="https://dms.rvimg.com/s3static/12/footer-piece.png" class="img-fluid v-align-initial" />
			<meta itemprop="copyrightYear" [content]="copyrightYear" />
			<meta itemprop="copyrightHolder" [content]="dealer" />
			<div class="container-fluid">
				<div class="row footer-content py-5">
					<div class="col-12 col-lg-10 offset-lg-1">
						<div class="row d-flex align-items-end">
							<div class="col-12 col-lg-6">
								<h5>Everything You Need</h5>
								<ul class="row list-unstyled align-items-center">
									<li class="col-6 d-flex align-items-center py-1" *ngFor="let point of bulletPoints">
										<fa-icon [icon]="faThumbsUp" class="theme-bg rounded text-center"></fa-icon>
										{{ point }}
									</li>
								</ul>
								<div class="row">
									<div class="col-4 col-lg-2 text-center" *ngFor="let partner of partners">
										<img [src]="partner" class="img-fluid" />
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-6 mt-4 mt-lg-0">
								<div class="row">
									<div class="col-12 text-center text-lg-right logo">
										<img
											src="https://dms.rvimg.com/s3static/12/long-white-001-m.png"
											class="img-fluid mb-3"
										/>
									</div>
									<div class="col-12 text-center text-lg-right">
										&copy; {{ dealer }} {{ copyrightYear }} | All Rights Reserved
									</div>
									<div class="col-12 text-center text-lg-right">
										<div class="address py-2">
											{{ locations[0].address1 }}
											<ng-container *ngIf="locations[0].address2">
												{{ locations[0].address2 }}
											</ng-container>
											{{ locations[0].city }}, {{ locations[0].abbrev }}
											{{ locations[0].postal_code }}<br />
											<a href="tel:{{ locations[0].org_phone_number }}">{{
												locations[0].org_phone_number | tel
											}}</a>
										</div>
									</div>
									<div class="col-12 text-center text-lg-right">
										<a routerLink="/privacy-policy">Privacy Policy</a> |
										<a routerLink="/terms-of-use">Terms of Use</a>
									</div>
									<div class="col-12 text-center text-lg-right py-2">
										<cm-social-links2></cm-social-links2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.footer-design-force {
				position: relative;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 0;
				font-size: 12px;
				color: white;
			}
			:host ::ng-deep .footer-design-force a {
				color: white;
			}
			.container-fluid {
				background: #003a70;
			}
			.rounded {
				width: 2em;
				height: 2em;
				line-height: 2em;
				vertical-align: middle;
				border-radius: 50% !important;
				margin-right: 5px !important;
			}
			.logo img {
				max-height: 35px;
			}
			.v-align-initial {
				vertical-align: initial;
				display: block;
			}
			@media print {
				.footer-design-force {
					margin-top: 0;
					background: 0 0 !important;
					color: #000 !important;
					box-shadow: none !important;
					text-shadow: none !important;
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterDesignForceComponent {
	copyrightYear = new Date().getFullYear();
	dealer: string;
	locations: IGetLocations[];
	faThumbsUp = faThumbsUp;
	bulletPoints = [
		"Web Design & Development",
		"Photography & Videography",
		"Reputation Management",
		"Print Materials",
		"Advertising Management",
		"Social Media Management",
		"Logos & Branding",
	];
	partners = [
		"https://dms.rvimg.com/s3static/12/bing-m.png",
		"https://dms.rvimg.com/s3static/12/facebook-partners-m.png",
		"https://dms.rvimg.com/s3static/12/google-partner-m.png",
	];
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {
		this.dealer = this.pageData.appInfo.data.siteName;
		this.locations = this.pageData.locations;
	}
}

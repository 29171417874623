import { Component, ViewEncapsulation } from "@angular/core";
import { RestService } from "../../../../src/app/rest.service";

@Component({
	selector: "cm-page-404",
	templateUrl: "./page-404.component.html",
	styleUrls: ["./page-404.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class Page404Component {
	settingService: any = null;
	pageContent: any = null;

	constructor(rest: RestService) {
		rest.init("admin/setting")
			.one("find/404Message")
			.then((response: any) => {
				if (undefined !== response.admin_setting) {
					this.pageContent = response.admin_setting;
				} else {
					this.pageContent = "Not Found";
				}
			});
	}
}

/// <reference types="googlemaps" />
import { Component, ViewChild, Input, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "cm-map",
	templateUrl: "./map.component.html",
	styles: [":host { display: block; }"],
})
export class MapComponent implements OnInit {
	@Input() mapHeight: any = "400px";
	@Input() mapZoom: any = 3;
	@Input() mapCenter: any = {};
	@Input() mapData: any = [];
	markersArray: any = [];
	service: any;
	opts: any;
	googleMapsUrl: string = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA";
	@ViewChild("googleMap", { static: true }) gmapElement: any;

	constructor(@Inject(DOCUMENT) private document: any) {}

	addMapsScript(): void {
		if (!this.document.querySelectorAll(`[src="${this.googleMapsUrl}"]`).length) {
			this.document.body.appendChild(
				Object.assign(this.document.createElement("script"), {
					type: "text/javascript",
					src: this.googleMapsUrl,
					onload: () => this.initMap(),
				}),
			);
		} else {
			this.initMap();
		}
	}

	/**
	 * Create google map at the #imgMapId container and add pins on it.
	 */
	clearOverlays(): void {
		for (const marker of this.markersArray) {
			marker.setMap(null);
		}
		this.markersArray.length = 0;
	}

	initMap(): void {
		if (typeof window === "undefined" || !window.hasOwnProperty("google")) {
			return;
		}

		const map = new google.maps.Map(this.gmapElement.nativeElement, {
			zoom: Number(this.mapZoom),
			center: this.mapCenter,
		});

		this.clearOverlays();

		for (const row of this.mapData) {
			const marker = new google.maps.Marker({
				position: row.latLng,
				map: map,
			});

			this.markersArray.push(marker);

			if (row.Text !== null) {
				const infoWindow = new google.maps.InfoWindow({
					content: row.Text,
				});

				marker.addListener("click", () => {
					infoWindow.open(map, marker);
				});
			}
		}
	}

	ngOnInit() {
		this.addMapsScript();
	}
}

<cm-header-meta></cm-header-meta>
<span itemscope [attr.itemtype]="webPageSchema">
	<div class="site-wrapper" itemscope itemtype="http://schema.org/WebPageElement" itemprop="mainContentOfPage">
		<router-outlet></router-outlet>
	</div>
</span>
<cm-promotion-popup *ngIf="!landingPage && isDealership()"></cm-promotion-popup>
<cm-footer-meta></cm-footer-meta>
<cm-quick-bar *ngIf="!landingPage && quickBar != 'false' && isDealership()"></cm-quick-bar>
<div toastContainer></div>

<div class="cm-floor-plan-types">
	<div *ngIf="featuredFloorPlanTypes.length > 0" class="row">
		<div class="floor-plan-container col-12 col-md-4" *ngFor="let type of featuredFloorPlanTypes">
			<a href="{{ type.link }}" class="theme floor-plan-type">
				<div class="type-header theme-base">{{ type.title }} Floor Plan</div>
				<div class="type-content">
					<cm-image [obj]="type.floorPlanImg" class="floorplan"></cm-image>
					<cm-image [obj]="type.trailerImg" class="trailer"></cm-image>
					<p [innerHTML]="type.description"></p>
				</div>
				<div class="type-footer theme-bg theme-fg">
					<span class="theme theme-bg-link">
						Browse ({{ type.count }})
						<fa-icon [icon]="faChevronRight"></fa-icon>
					</span>
				</div>
			</a>
		</div>
	</div>
	<h3>Other Features</h3>
	<div *ngIf="floorPlanTypes.length > 0" class="row">
		<div class="floor-plan-container other-features col-6 col-md-3" *ngFor="let type of floorPlanTypes">
			<a href="{{ type.link }}" class="floor-plan-type">
				<div class="type-header theme-base">{{ type.title }}</div>
				<div class="type-content">
					<cm-image [obj]="type.floorPlanImg" class="floorplan"></cm-image>
				</div>
				<div class="type-footer theme-base-light">
					<span class="theme theme-bg-link">
						Browse ({{ type.count }})
						<fa-icon [icon]="faChevronRight"></fa-icon>
					</span>
				</div>
			</a>
		</div>
	</div>
</div>

<ng-container>
	<div>
		<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	</div>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<input
		#input
		type="file"
		[id]="id"
		[name]="name"
		[disabled]="disabled"
		[required]="required"
		(change)="onChange()"
	/>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>

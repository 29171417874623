import { Inject, Injectable } from "@angular/core";
import { IPageData } from "@model/page-data";

@Injectable({
	providedIn: "root",
})
export class MenuHelperService {
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}

	getSubMenu(menus: any, depth: any) {
		let menu: any = null;
		for (let i = 1; i <= depth; i += 1) {
			if (menu) {
				menu = menu.hasOwnProperty("items") ? menu.items[menu.items.length - 1] : menu;
			} else {
				menu = menus[menus.length - 1];
			}
		}
		return menu;
	}

	getMenu() {
		let menus = null;
		if (this.pageData.headerData) {
			menus = this.pageData.headerData.queries.GetMainMenu.reduce((acc: any, row: any) => {
				const menu: any = {
					text: row.app_menu_item,
					link: row.custom_url,
					megaMenu: row.app_menu_item.toLowerCase().indexOf("new") !== -1,
					visible: false,
					items: [],
				};

				if (row.sub_menu_stmtid !== null) {
					menu.items = [];
					menu.stmtid = row.sub_menu_stmtid;
					menu.cssClasses = "stmtmenu";
					menu.items = this.pageData.headerData.menuItems[row.sub_menu_stmtid].map((subRow: any) => ({
						text: subRow.name,
						link: subRow.content_page_url,
						megaMenu: subRow.name.toLowerCase().indexOf("new") !== -1,
						visible: false,
						items: [],
					}));
				}

				if (row.of_depth > 0) {
					const subMenu = this.getSubMenu(acc, row.of_depth);
					if (subMenu) {
						subMenu.items.push(menu);
					}
				} else {
					acc.push(menu);
				}
				return acc;
			}, []);
		}
		return menus;
	}
}

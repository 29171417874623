declare const __DEFINE_SITE_CONFIGID: number; // provided by DefinePlugin

import { IEnvironment } from "./environment-types";
import { environment as environment1 } from "./environment.1";
import { environment as environment2 } from "./environment.2";
import { environment as environment3 } from "./environment.3";
import { environment as environment4 } from "./environment.4";
import { environment as environment5 } from "./environment.5";
import { environment as environment6 } from "./environment.6";
import { environment as environment7 } from "./environment.7";
import { environment as environment8 } from "./environment.8";
import { environment as environment9 } from "./environment.9";
import { environment as environment10 } from "./environment.10";
import { environment as environment11 } from "./environment.11";
import { environment as environment12 } from "./environment.12";
import { environment as environment13 } from "./environment.13";
import { environment as environment14 } from "./environment.14";
import { environment as environment15 } from "./environment.15";
import { environment as environment16 } from "./environment.16";
import { environment as environment17 } from "./environment.17";
import { environment as environment20 } from "./environment.20";
import { environment as environment22 } from "./environment.22";
import { environmentDefault } from "./environment.default";

const envs = [
	environment1,
	environment2,
	environment3,
	environment4,
	environment5,
	environment6,
	environment7,
	environment8,
	environment9,
	environment10,
	environment11,
	environment12,
	environment13,
	environment14,
	environment15,
	environment16,
	environment17,
	environment1,
	environment1,
	environment20,
	environment1,
	environment22,
];

export { IEnvironment } from "./environment-types";

export const environment: IEnvironment = {
	...environmentDefault,
	...envs[__DEFINE_SITE_CONFIGID - 1],
	production: true,
};

import { Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";
import { IGetLocations } from "@model/stmt/GetLocations";

@Component({
	selector: "cm-footer-anthony",
	template: `
		<div class="container-fluid">
			<div class="row justify-content-center align-items-center text-center">
				<div class="col-12 col-lg-10 text-center">
					<div class="row my-3">
						<div class="col-12 col-lg-6 mb-4 text-lg-right">
							<a routerLink="/">
								<img src="https://dms.rvimg.com/s3static/16/al-1-s.png" class="img-fluid" />
							</a>
						</div>
						<div class="col-12 col-lg-6 mb-4 text-lg-left">
							<a href="https://homelakeshore.com/" target="_blank">
								<img src="https://dms.rvimg.com/s3static/16/home-1-s.png" class="img-fluid" />
							</a>
						</div>
					</div>
					<div>
						<a href="tel:231-670-6724">(231) 670-6724</a><br />
						<a href="mailto:anthony@homelakeshore.com">Anthony@HomeLakeshore.com</a><br />
						<div *ngFor="let loc of locations" class="address">
							{{ loc.address1 }}
							<ng-container *ngIf="loc.address2"> {{ loc.address2 }} </ng-container>
							{{ loc.city }}, {{ loc.abbrev }} {{ loc.postal_code }}
						</div>
					</div>
					<div class="my-3">
						<cm-social-links2></cm-social-links2>
					</div>
					<div class="mb-1"><a href="https://designforcemarketing.com/">Powered By Design Force</a></div>
					<div class="mb-1">All Right Reserved &copy; {{ year }}</div>
					<div>
						<a [routerLink]="'/terms-of-use'">Terms of Use</a> |
						<a [routerLink]="'/privacy-policy'">Privacy Policy</a>
					</div>
					<cm-trust-icons class="my-2"></cm-trust-icons>
					<img src="https://dms.rvimg.com/s3static/16/al-footer-green-line.png" class="mb-2 img-fluid" />
					<div class="font-sedgwick my-2" style="font-size:1.2rem;">Where Real Estate Meets Community</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ["./footer-anthony.component.scss"],
})
export class FooterAnthonyComponent {
	year = new Date().getFullYear();
	locations: IGetLocations[];
	siteName = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {
		this.locations = this.pageData.locations;
	}
}

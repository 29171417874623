import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ISearchFilterOption } from "@model/search-filter-option";

@Component({
	selector: "cm-select-selector",
	templateUrl: "./select-selector.component.html",
	styleUrls: ["./select-selector.component.scss"],
	host: {
		"[class.filter-row]": "true",
	},
})
export class SelectSelectorComponent implements OnChanges {
	// @ts-ignore
	@Input() filter: ISearchFilterOption;
	@Output() select = new EventEmitter();

	value = "";

	ngOnInit() {
		this.value =
			this.filter.queryParams[`${this.filter.name}`] && this.filter.queryParams[`${this.filter.name}`] !== null
				? this.filter.queryParams[`${this.filter.name}`]
				: "";
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filter && changes.filter.currentValue) {
			this.value =
				this.filter.queryParams[`${this.filter.name}`] &&
				this.filter.queryParams[`${this.filter.name}`] !== null
					? this.filter.queryParams[`${this.filter.name}`]
					: "";
		}
	}

	doChange(value: string) {
		this.filter.queryParams[`${this.filter.name}`] = value;
		this.select.emit(value);
	}
}

import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { cloneDeep } from "@common/util";
import { iterFiles } from "@common/iter";
import { FileBase64, FieldFile } from "@core/app/form/field";

@Component({
	selector: "cm-field-service-issue",
	templateUrl: "./field-service-issue.component.html",
})
export class FieldServiceIssueComponent implements OnInit {
	defaultIssue = {
		issueText: "",
		issueFile: "",
		partText: "",
		partQty: 0,
		partFile: "",
	};
	rows: any[] = [];

	@Output() valueChange = new EventEmitter<any[]>();

	ngOnInit() {
		this.rows = [];
		this.rows.push(cloneDeep(this.defaultIssue));
	}

	createNew(event: Event) {
		event.preventDefault();
		this.rows.push(cloneDeep(this.defaultIssue));
	}

	removeIssue(issue: number, event: Event) {
		event.preventDefault();
		this.rows.splice(issue, 1);
	}

	valueChanged() {
		this.valueChange.next(this.rows);
	}

	async fileValueChanged(issue: any, field: any, $event: any) {
		issue[field] = await this.getValuesAsBase64($event);
		this.valueChanged();
	}

	getValuesAsBase64(field: FileList): Promise<FileBase64[] | null> {
		if (field === null) {
			return Promise.resolve(null);
		}

		return Promise.all(
			iterFiles(field)
				.map(async (file) => new FileBase64(file.name, await FieldFile.getBase64(file)))
				.toArray(),
		);
	}
}

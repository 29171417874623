<div class="gen-menu">
	<ng-container *ngIf="menu.type === 'grid'">
		<div class="grid-menu">
			<div class="grid-menu-column" *ngFor="let column of menu.columns; let index = index">
				<div *ngIf="column.type === 'list'" class="list {{ column.class }}">
					<a
						[cmHref]="item.link"
						class="grid-menu-list-item"
						*ngFor="let item of column.items; let index = index"
					>
						<cm-image *ngIf="item.image" [obj]="item.image" class="menu-image"></cm-image>
						<span *ngIf="item.text" [innerHTML]="item.text"></span>
					</a>
				</div>
				<div *ngIf="column.type === 'content'" class="content {{ column.class }}">
					<h2 [innerHTML]="column.heading"></h2>
					<p [innerHTML]="column.text"></p>
					<cm-button [obj]="column.button"></cm-button>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="menu.type === 'linear'">
		<div class="linear-menu">
			<ul>
				<li *ngFor="let item of menu.items; let index = index">
					<a [cmHref]="item.link"
						><span>{{ item.text }}</span></a
					>
				</li>
			</ul>
		</div>
	</ng-container>
	<ng-container *ngIf="menu.type === 'mobile'">
		<div class="menu-toggle">
			<fa-icon [icon]="faBars" [fixedWidth]="true" (click)="toggleMenu()"></fa-icon>
		</div>
		<div class="mobile-menu-overlay" [ngClass]="{ active: !hidden }" (click)="toggleMenu()"></div>
		<div class="mobile-menu" [ngClass]="{ active: !hidden }">
			<!--User/Guest Login-->
			<div class="scroll-area">
				<div class="user clearfix">
					<div class="avatar-block">
						<fa-icon *ngIf="!(imageObj$ | async)" [icon]="faUser" [fixedWidth]="true"></fa-icon>
						<cm-image *ngIf="imageObj$ | async as imageObj" [obj]="imageObj"></cm-image>
					</div>
					<div class="welcome-block">
						<div class="name" *ngIf="!(userService.loggedIn$ | async)"><strong>HELLO,</strong> GUEST</div>
						<div class="name" *ngIf="userService.loggedIn$ | async">
							<a routerLink="/my-account"><strong>HELLO,</strong> {{ userService.fullName$ | async }}</a>
						</div>
						<div class="sign-in-out theme-accent-bg-as-fg" (click)="loginClick()">
							<fa-icon class="mr-1" *ngIf="!(userService.loggedIn$ | async)" [icon]="faSignIn"></fa-icon>
							<fa-icon class="mr-1" *ngIf="userService.loggedIn$ | async" [icon]="faSignOut"></fa-icon>
							<span>{{ (userService.loggedIn$ | async) ? "Sign Out" : "Sign In" }}</span>
						</div>
					</div>
					<div class="close-menu" (click)="toggleMenu()">
						<fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
					</div>
				</div>
				<!--Menu Buttons -->
				<cm-button-list
					*ngIf="userService.loggedIn$ | async"
					class="menu-buttons"
					[buttons]="menu.buttons"
				></cm-button-list>
				<!--Search / Shopping Cart-->
				<div class="search">
					<cm-autocomplete [searchItems]="searchValues$ | async" [options]="searchOptions"></cm-autocomplete>
				</div>
				<!--Menu-->
				<ul class="menu">
					<ng-container *ngFor="let item of menu.items; let index = index">
						<li>
							<ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
						</li>
					</ng-container>
				</ul>
			</div>
			<!--Social Links-->
			<cm-button-list class="social-links" [buttons]="menu.socialLinks.buttons"></cm-button-list>
		</div>
	</ng-container>
</div>

<ng-template #menuItem let-item="item">
	<a [cmHref]="item.link" (click)="menu.itemClick($event, item)">
		<fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
		<cm-image *ngIf="item.image" [obj]="item.image" [intersectionPollInterval]="500" class="menu-image"></cm-image>
		<span class="text">{{ item.text }}</span>
	</a>
	<fa-icon
		*ngIf="item.items.length > 0 && !item.showItems"
		[icon]="faChevronDown"
		(click)="item.showItems = !item.showItems"
	></fa-icon>
	<fa-icon
		*ngIf="item.items.length > 0 && item.showItems"
		[icon]="faChevronUp"
		(click)="item.showItems = !item.showItems"
	></fa-icon>
	<ul *ngIf="item.showItems && item.items.length > 0" class="sub-menu" [ngClass]="{ active: item.showItems }">
		<ng-container *ngFor="let subItem of item.items; let index = index">
			<li>
				<ng-container *ngTemplateOutlet="menuItem; context: { item: subItem }"></ng-container>
			</li>
		</ng-container>
	</ul>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
	<a [cmHref]="r.href">
		<span [innerHTML]="r.label"></span>
	</a>
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="loginModal">Sign In/Sign Up</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<cm-login></cm-login>
	</div>
</ng-template>

import { Component, Input } from "@angular/core";
import {
	Field,
	FieldCheckbox,
	FieldDate,
	FieldFile,
	FieldMonthYear,
	FieldRadio,
	FieldSelect,
	FieldText,
	FieldTextarea,
	FieldNumber,
	FieldServiceIssue,
	FieldTextMultiple,
	FieldCannedResponse,
	FieldCompare,
	FieldFileMultiple,
	FieldListItem,
	FieldUserAuthGroups,
	FieldDateTime,
} from "@core/app/form/field";

@Component({
	selector: "cm-field",
	templateUrl: "./field.component.html",
})
export class FieldComponent {
	@Input() field!: Field;

	isCheckbox() {
		return this.field instanceof FieldCheckbox;
	}
	isDate() {
		return this.field instanceof FieldDate;
	}
	isDateTime() {
		return this.field instanceof FieldDateTime;
	}
	isFile() {
		return this.field instanceof FieldFile;
	}
	isFileMultiple() {
		return this.field instanceof FieldFileMultiple;
	}
	isListItem() {
		return this.field instanceof FieldListItem;
	}
	isMonthYear() {
		return this.field instanceof FieldMonthYear;
	}
	isRadio() {
		return this.field instanceof FieldRadio;
	}
	isSelect() {
		return this.field instanceof FieldSelect;
	}
	isText() {
		return this.field instanceof FieldText;
	}
	isTextMultiple() {
		return this.field instanceof FieldTextMultiple;
	}
	isNumber() {
		return this.field instanceof FieldNumber;
	}
	isTextarea() {
		return this.field instanceof FieldTextarea;
	}
	isServiceIssue() {
		return this.field instanceof FieldServiceIssue;
	}
	isUserAuthGroups() {
		return this.field instanceof FieldUserAuthGroups;
	}
	isCannedResponse() {
		return this.field instanceof FieldCannedResponse;
	}
	isCompare() {
		return this.field instanceof FieldCompare;
	}

	onChangeFile(files: any) {
		this.field.setValue(files);
	}
}

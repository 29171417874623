/// <reference types="googlemaps" />
import { Component, Inject, ViewChild, OnInit } from "@angular/core";
import { RestService } from "../rest.service";
import { DOCUMENT } from "@angular/common";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-live-tracker",
	templateUrl: "./live-tracker.component.html",
})
export class LiveTrackerComponent implements OnInit {
	mapHeight: any = "400px";
	mapZoom: any = 3;
	mapCenter: any = {};
	mapData: any = [];
	markersArray: any = [];
	service: any;
	opts: any;
	googleMapsUrl: string = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA";
	@ViewChild("googleMap", { static: true }) gmapElement: any;

	constructor(
		private restService: RestService,
		@Inject(DOCUMENT) private document: Document,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.service = this.restService.init("service");
	}

	addMapsScript(): void {
		if (!this.document.querySelectorAll(`[src="${this.googleMapsUrl}"]`).length) {
			this.document.body.appendChild(
				Object.assign(this.document.createElement("script"), {
					type: "text/javascript",
					src: this.googleMapsUrl,
					onload: () => this.initMap(),
				}),
			);
		} else {
			this.initMap();
		}
	}

	/**
	 * Create google map at the #imgMapId container and add pins on it.
	 */
	clearOverlays(): void {
		for (const marker of this.markersArray) {
			marker.setMap(null);
		}
		this.markersArray.length = 0;
	}

	initMap(): void {
		if (typeof window === "undefined" || !window.hasOwnProperty("google")) {
			return;
		}

		this.clearOverlays();

		const map = new google.maps.Map(this.gmapElement.nativeElement, {
			zoom: Number(this.mapZoom),
			center: this.mapCenter,
		});

		this.request(map);
	}

	/**
	 * Request data from API
	 *
	 * @param map
	 */
	request(map: any): void {
		// Load data from API
		let devicesStatusInfo = [];

		this.service
			.custom("POST", "geotab", {
				method: "Get",
				typeName: "DeviceStatusInfo",
			})
			.then((response: any) => {
				devicesStatusInfo = response.result;
				if (devicesStatusInfo) {
					for (const deviceStatusInfo of devicesStatusInfo) {
						this.mapData.push({
							latLng: {
								lat: deviceStatusInfo.latitude,
								lng: deviceStatusInfo.longitude,
							},
						});
					}
				}

				this.clearOverlays();

				for (const row of this.mapData) {
					const marker = new google.maps.Marker({
						position: row.latLng,
						map: map,
					});
					this.markersArray.push(marker);
				}
			});
	}

	async ngOnInit() {
		this.mapHeight = "400px";
		this.mapZoom = 3;
		this.mapCenter = {};
		this.mapData = [];
		const data = this.pageData.locations[0];
		this.mapCenter.lat = Number(data.latitude);
		this.mapCenter.lng = Number(data.longitude);
		this.addMapsScript();
	}
}

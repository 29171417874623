import { Component, ViewEncapsulation } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { ImageService } from "@core/app/image.service";
import { RestService, Service } from "@core/app/rest.service";

@Component({
	selector: "cm-reviews",
	templateUrl: "./reviews.component.html",
	styleUrls: ["./reviews.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ReviewsComponent {
	reviews: Array<any> = [];
	maxPage: number = 0;
	currentPage: number = 1;
	loading: boolean = true;
	service: Service;
	trying: number = 1;
	sendInfo = { vars: { limit: 12 } };

	constructor(
		private imageService: ImageService,
		private restService: RestService,
		private transfer: TransferRxService,
	) {
		this.service = this.restService.init("statement");
	}

	ngOnInit(): void {
		this.sendInfo = { vars: { limit: 12 } };
		this.getReviews();
	}

	getReviews(): void {
		this.loading = true;
		this.transfer
			.transfer$("GetReviews" + JSON.stringify(this.sendInfo), () =>
				this.service.post$("GetReviews", this.sendInfo),
			)
			.subscribe((response: any) => {
				if (response.success) {
					this.maxPage = response.last_page;
					this.currentPage = response.current_page;

					for (const row of response.results) {
						let city = "";

						if (null !== row.city && row.city !== "" && null !== row.stateprov && row.stateprov !== "") {
							city = " from " + row.city + ", " + row.stateprov;
						} else if (null !== row.city && row.city !== "") {
							city = " from " + row.city;
						}
						this.reviews.push({
							image: this.imageService.make(row, { size: "s" }),
							headingText: row.model_year + " " + row.brand + " " + row.model_num,
							rating: row.rating,
							quote: row.first_name + city,
							bodyText: row.review,
							datePublished: row.review_date,
							dateModified: row.review_date,
							publisher: true,
							reviewsCount: row.reviewsCount,
							attrs: {
								itemscope: "",
								itemtype: "http://schema.org/Review",
							},
						});
					}
				}

				this.loading = false;
			});
	}

	loadMore() {
		if (this.currentPage + 1 <= this.maxPage && this.trying !== this.currentPage + 1) {
			(this.sendInfo.vars as any).offset = this.currentPage * this.sendInfo.vars.limit;
			this.trying = this.currentPage + 1;
			this.getReviews();
		}
	}
}

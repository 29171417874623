import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VideoComponent } from "./components/video/video.component";
import { YoutubeComponent } from "./components/youtube/youtube.component";

@NgModule({
	declarations: [VideoComponent, YoutubeComponent],
	imports: [CommonModule],
	exports: [VideoComponent, YoutubeComponent],
})
export class DmsCommonModule {}

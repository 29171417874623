import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ImageModule } from "@core/app/image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ListingPromotionComponent } from "./components/listing-promotion/listing-promotion.component";
import { ListingTileComponent } from "./components/listing-tile/listing-tile.component";
import { RvListingSlideshowComponent } from "./components/rv-listing-slideshow/rv-listing-slideshow.component";
import { InventoryModule } from "../inventory/inventory.module";
import { SliderModule } from "../slider/slider.module";

@NgModule({
	declarations: [ListingTileComponent, ListingPromotionComponent, RvListingSlideshowComponent],
	imports: [CommonModule, RouterModule, ImageModule, InventoryModule, SliderModule, NgbModule, FontAwesomeModule],
	exports: [ListingTileComponent, ListingPromotionComponent, RvListingSlideshowComponent],
})
export class ListingModule {}

<fieldset [disabled]="disabled" [ngClass]="{ additive: additive }">
	<legend *ngIf="legend">{{ legend }}</legend>

	<ng-container *ngFor="let entry of entries; index as entryI; trackBy: trackEntry">
		<div class="entry-content">
			<div *ngIf="additive"><button mat-raised-button (click)="removeEntry($event, entryI)">X</button></div>
			<div *ngFor="let row of entry.rows" class="row">
				<div
					*ngFor="let field of row.fieldsVisible()"
					class="col w-full lg:w-{{ Math.floor(12 / row.fields.length) }}/12"
					[ngClass]="{ 'mb-3': !field.field.type || field.field.type !== 'hidden' }"
				>
					<cm-field [field]="field.field" *ngIf="field.visible"></cm-field>
				</div>
			</div>
		</div>
	</ng-container>

	<button mat-raised-button type="submit" color="warn" *ngIf="additive" (click)="addEntry()">Add {{ legend }}</button>
</fieldset>

<ng-container>
	<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	<small *ngIf="displayFormat" class="text-muted ml-1">({{ format }})</small>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<div class="input-group">
		<input
			#input
			type="text"
			class="form-control"
			[id]="id"
			[name]="name"
			[disabled]="disabled"
			[required]="required"
			[(ngModel)]="value"
			(ngModelChange)="valueChange.emit(value)"
			(blur)="validate()"
		/>
		<div class="input-group-append">
			<button
				type="button"
				class="btn btn-outline-secondary btn-sm"
				[disabled]="disabled"
				(click)="onPickerToggle($event)"
				[ngClass]="{ active: pickerVisible }"
			>
				<fa-icon [icon]="faCalendarAlt"></fa-icon><fa-icon [icon]="faClock"></fa-icon>
			</button>
		</div>
	</div>
	<div *ngIf="pickerVisible" class="relative" (click)="$event.stopPropagation()">
		<div class="position-absolute flex flex-col items-center bg-light border shadow p-3 z-3">
			<input type="date" class="form-control" [(ngModel)]="myDate" />
			<input type="time" class="form-control" [(ngModel)]="myTime" />
			<button mat-raised-button color="primary" class="btn mt-3" (click)="onSubmit()">Submit</button>
		</div>
	</div>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>

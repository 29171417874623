import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "terrytownrv",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#01105c",
				bgHover: "#02146F",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				highlight: {
					bg: "#c30000",
					bgHover: "#d40202",
					fg: "#FFF",
				},
				homeUrl: "https://dms.rvimg.com/s3static/7/homepage-background.jpg",
				footerUrl: "s3static/7/footer-img-s.png",
			},
		},
	},
};

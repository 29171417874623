declare const CallTrk: any;
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RestService } from "@core/app/rest.service";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { ImagePipe } from "@common/pipes/image";

@Component({
	selector: "cm-sales-staff",
	templateUrl: "./sales-staff.component.html",
	styleUrls: ["./sales-staff.component.scss"],
	providers: [ImagePipe],
})
export class SalesStaffComponent implements OnInit {
	@Input() app: any = null;

	salesStaff: Array<any> = [];
	service: any = null;

	faUserCircle = faUserCircle;

	constructor(
		private rest: RestService,
		private route: ActivatedRoute,
		private imagePipe: ImagePipe,
		private transfer: TransferRxService,
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((data) => {
			const sendInfo = {
				vars: data,
			};
			this.transfer
				.transfer$("GetLocationSalesStaff", () =>
					this.rest.init("statement").post$("GetLocationSalesStaff", sendInfo),
				)
				.subscribe((response: any) => {
					for (const staff of response.results) {
						this.salesStaff.push({
							name: staff.first_name + " " + staff.last_name,
							phoneNumber: staff.user_phone_number,
							email: staff.user_email,
							image:
								null !== staff.img_dir
									? {
											src: this.imagePipe.transform(staff.img_dir + staff.img_file, "t"),
											alt: staff.first_name + " " + staff.last_name,
									  }
									: null,
						});
					}
					setTimeout(() => {
						if (typeof CallTrk !== "undefined") {
							CallTrk.swap();
						}
					}, 100);
				});
		});
	}
}

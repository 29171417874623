import { Component, HostBinding, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";

let nextIdNum = 0;

@Component({
	selector: "cm-field-file",
	templateUrl: "./field-file.component.html",
	styles: [":host { display: block; }"],
})
export class FieldFileComponent {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;

	@Output() valueChange = new EventEmitter<FileList | null>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	idNum: number = 0;
	error: string | null = null;
	validated: boolean = false;

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id() {
		return `field-file-${this.idNum}`;
	}

	onChange() {
		this.error = null;

		const files = this.input.nativeElement.files;

		if (this.required && (files === null || files.length === 0)) {
			this.error = "Please fill out this field.";
		} else if (files !== null) {
			for (let i = 0; i < files.length; i++) {
				const file = files.item(i) as File;

				if (file.size > 1024 * 1024 * 25) {
					// 25 MiB
					this.error = "File is too large. (max 25 MiB)";
					break;
				}
			}
		}

		this.validated = true;

		this.valueChange.emit(files);
	}
}

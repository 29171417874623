<cm-label *ngIf="label && options" [text]="label" class="block"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div
	*ngFor="let opt of options || [{ value: 'true', label: label }]; index as i"
	class="form-check form-check-inline inline-block mx-1"
>
	<input
		type="checkbox"
		id="check-{{ id }}-{{ i }}"
		class="form-check-input"
		name="{{ name }}-{{ i }}"
		[checked]="valueSplit.has(opt.value)"
		[disabled]="disabled"
		[value]="opt.value"
		(change)="onCheckClicked($event.target.value, $event.target.checked)"
	/>
	<label for="check-{{ id }}-{{ i }}" class="form-check-label">{{ opt.label }}</label>
</div>

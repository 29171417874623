import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	font: {
		family: "Roboto,sans-serif",
	},
	dealerInfo: {
		name: "fnsrv",
		homeBreadcrumb: 'RV Dealer',
		themeData: {
			dealerAccent: {
				bg: "#015091",
				bgHover: "#015091",
				fg: "#0a518f",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#e9282d",
					bgHover: "#e9282d",
					fg: "#FFF",
				},
				homeUrl: "",
				footerUrl: "",
			},
		},
	},
};

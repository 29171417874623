<div class="asc-header" [ngClass]="{ small: header.small }">
	<div class="row">
		<div class="asc-header-logo col-12 col-md-4 text-right">
			<cm-image [obj]="header.logo"></cm-image>
		</div>
		<div class="asc-header-contact col-12 col-md-4 text-center">
			<p class="body-text-1">Free Project Consultation</p>
			<a
				class="body-text-1 theme-accent-bg-as-fg"
				(click)="linkClicked('Link Click', 'Success', 'Phone Call')"
				href="tel:{{ header.phone }}"
				rel="nofollow"
			>
				<strong>{{ header.phone | tel }}</strong>
			</a>
		</div>
		<div class="asc-header-user col-12 col-md-3">
			<div class="user" *ngIf="header.loggedIn">
				<div class="avatar-block">
					<fa-icon *ngIf="!header.user.imageObj" [icon]="faUsers" [fixedWidth]="true"></fa-icon>
					<cm-image *ngIf="header.user.imageObj" [obj]="header.user.imageObj"></cm-image>
				</div>
				<div class="welcome-block">
					<div class="name">
						<a [cmHref]="'/my-account'">
							<strong>HELLO,</strong> {{ header.user.first_name }} {{ header.user.last_name }}
						</a>
					</div>
					<div class="sign-in-out theme-accent-bg-as-fg" (click)="logout()">
						<fa-icon [icon]="faSignOut"></fa-icon>
						<span>Sign Out</span>
					</div>
				</div>
			</div>
		</div>
		<div class="asc-header-menu col-12 col-md-1 text-right">
			<cm-gen-menu [obj]="header.menu"></cm-gen-menu>
		</div>
	</div>
</div>

import { Component, Input, Inject } from "@angular/core";
import { TrackingService } from "../tracking.service";

@Component({
	selector: "cm-phone-number",
	template: `
		<a href="tel:{{ phone }}" (click)="placeCall()" itemprop="url" rel="nofollow">{{ phone | tel }}</a>
	`,
})
export class PhoneNumberComponent {
	@Input() phone!: string;

	constructor(@Inject(TrackingService) private trackingService: TrackingService) {}

	placeCall(): void {
		this.trackingService.trackVisit({ actionName: "Call" });
	}
}

import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { RestService, Service } from "./rest.service";

@Injectable({
	providedIn: "root",
})
export class AdminService {
	service: Service;
	statementService: Service;
	notifications: any = {
		updated: "Status Updated",
		updateError: "Status Update Error",
	};

	openAdminSubject = new Subject();

	constructor(private rest: RestService, private toastrService: ToastrService) {
		this.service = this.rest.init("admin/content");
		this.statementService = this.rest.init("statement");
	}

	createActionButton(options: any) {
		const hooks: any = options.hasOwnProperty("hooks") && options.hooks;

		return Object.assign(
			{
				class: "btn-primary",
				clicked: (tab: any, button: any) => {
					const row: any = options.row || {};
					const idField: any = tab.section.fields.find(({ field }: any) => field === options.fieldId);

					row[options.fieldId] = idField[options.fieldId];

					if (options.field) {
						row[options.field.field] = options.field[options.field.field];
					}

					if (hooks && hooks.hasOwnProperty("beforeAction")) {
						hooks.beforeAction(tab, button, row, idField);
					}

					if (options.hasOwnProperty("action") && options.action) {
						return this.service
							.post("process", Object.assign(row, { action: options.action }))
							.then((response: any) => {
								if (hooks && hooks.hasOwnProperty("afterAction")) {
									hooks.afterAction(response);
								} else if (response.success) {
									this.toastrService.success(this.notifications.updated);
								} else {
									this.toastrService.error(this.notifications.updateError);
								}
							});
					}
				},
			},
			options,
		);
	}

	createScreen(title: any) {
		const screen: any = {
			title: title,
			tabs: [],
		};
		const builder: any = {
			get: () => {
				return screen;
			},
			addTab: (id: any, options: any) => {
				screen.tabs.push(
					Object.assign(
						{
							id: id,
							defaultView: "detail",
							view: "detail",
						},
						options,
					),
				);

				return builder;
			},
		};

		return builder;
	}

	process(options: any) {
		const hooks = options.hasOwnProperty("hooks") && options.hooks;

		return this.service.post("process", options.post).then((response: any) => {
			if (response.success) {
				if (hooks && hooks.hasOwnProperty("success")) {
					if (hooks.success.type === "statement") {
						this.statementService.post(hooks.success.id, hooks.success.data).then(hooks.success.callback);
					} else if (hooks.success.type === "callback") {
						hooks.success.callback(response);
					}
				}
			}
		});
	}

	remove(id: any, data: any) {
		return this.service.remove(id, data);
	}

	openModal() {
		this.openAdminSubject.next();
	}

	onOpenAdmin() {
		return this.openAdminSubject;
	}
}

import { DOCUMENT } from "@angular/common";
import {
	AfterContentInit,
	AfterViewChecked,
	ComponentRef,
	Directive,
	ElementRef,
	Inject,
	Input,
	NgModuleRef,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	Type,
} from "@angular/core";
import { BrandsComponent } from "../brands/brands.component";
import { iterObj } from "../common/iter";
import { option } from "../common/option";
import { evalWithContext } from "../common/util";
import { ConfigService } from "../config.service";
import { CoreModule } from "../core.module";
import { FloorPlanTypesComponent } from "../floor-plan-types/floor-plan-types.component";
import { ImageService } from "../image.service";
import { ImageComponent } from "../image/components/image/image.component";
import { LiveTrackerComponent } from "../live-tracker/live-tracker.component";
import { RestService } from "../rest.service";
import { ReviewsComponent } from "../reviews/reviews.component";
import { SalesStaffComponent } from "../shared/components/sales-staff/sales-staff.component";
import { ShortCodeService } from "../short-code.service";
import { StoreMapComponent } from "../store-map/store-map.component";

@Directive({ selector: "cm-translator" })
export class TranslatorComponent implements AfterViewChecked, AfterContentInit, OnChanges, OnDestroy {
	@Input() inputText!: string;
	@Input() ctx?: any;

	components: ComponentRef<any>[] = [];
	service: any = null;
	sendInfo: any = {};
	images: any = [];
	stmtValues: any = [];

	constructor(
		private shortCodeService: ShortCodeService,
		private imageService: ImageService,
		private restService: RestService,
		private config: ConfigService,
		@Inject(ElementRef) private el: ElementRef<HTMLDivElement>,
		@Inject(NgModuleRef) private module: NgModuleRef<CoreModule>,
		@Inject(DOCUMENT) private document: any,
	) {
		this.service = this.restService.init("statement", "Translate");
		this.sendInfo = { site_configid: this.config.getAppToken().site_configid };
	}

	ngAfterContentInit() {
		this.translate();
	}

	ngAfterViewChecked() {
		this.components = this.components.filter((c) => !c.hostView.destroyed);

		for (const component of this.components) {
			component.hostView.detectChanges();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputText && !changes.inputText.firstChange) {
			this.translate();
		}
	}

	ngOnDestroy() {
		this.destroyComponents();
	}

	translate() {
		if (this.inputText === undefined) {
			return;
		}

		const shortCodes = this.shortCodeService.parse(this.inputText);
		let str = this.inputText.replace(/(\r\n|\n|\r)/gm, "");
		let stmtid: any = null;
		let firstImage = true;
		let imgIndex = 0;

		for (const shortCode of shortCodes) {
			if (!shortCode.type && !shortCode.image) {
				return;
			}

			if (!shortCode.image) {
				let attributes = "";
				for (const [key, value] of iterObj(shortCode.params)) {
					if (key === "stmtid") {
						stmtid = value;
						attributes += " [data]=\"this.stmtValues['stmt" + value + "']\"";
					} else {
						attributes += " [" + key + ']="' + value + '"';
					}
				}
				for (const [key, value] of iterObj(shortCode.params)) {
					if (key === "stmtid") {
						stmtid = value;
						attributes += " [data]=\"this.stmtValues['stmt" + value + "']\"";
					} else {
						attributes += " [" + key + ']="' + value + '"';
					}
				}

				str = str.replace(
					shortCode.original,
					"<" + shortCode.type + attributes + ">" + shortCode.innerText + "</" + shortCode.type + ">",
				);
			} else {
				let thumbSize = "t";

				if (shortCode.params.hasOwnProperty("width")) {
					if (shortCode.params.width < 200) {
						thumbSize = "i";
					} else if (shortCode.params.width < 400) {
						thumbSize = "t";
					} else if (shortCode.params.width < 640) {
						thumbSize = "s";
					} else if (shortCode.params.width < 1004) {
						thumbSize = "m";
					} else if (shortCode.params.width < 1920) {
						thumbSize = "l";
					} else if (shortCode.params.width < 1960) {
						thumbSize = "o";
					} else {
						thumbSize = "a";
					}
				}

				const m = /(?:https:\/\/.*\.com\/)(.*)/.exec(shortCode.params.src);
				const src =
					null !== m
						? m[1].replace(/(-\d+x\d+\.)/g, ".").replace("../", "")
						: shortCode.params.src.replace(/(-\d+x\d+\.)/g, ".").replace("../", "");
				const url = shortCode.params.hasOwnProperty("href") ? shortCode.params.href : "";
				const img = this.imageService.make({
					id: src,
					size: thumbSize,
					class: shortCode.params.class,
					width: shortCode.params.width,
					height: shortCode.params.height,
					alt: shortCode.params.alt,
					altText: shortCode.params.alt,
					title: shortCode.params.title || shortCode.params.alt,
					titleText: shortCode.params.title || shortCode.params.alt,
					link: url || null,
				});

				if (firstImage) {
					firstImage = false;
				}

				this.images["img" + imgIndex] = img;
				str = str.replace(
					shortCode.original,
					"<cm-image [obj]=\"this.images['img" + imgIndex + "']\"></cm-image>",
				);
				imgIndex += 1;
			}
		}

		if (stmtid !== null) {
			this.service.post(stmtid, this.sendInfo).then((response: any) => {
				this.stmtValues["stmt" + stmtid] = response.results;
				this.setInnerHtml(str);
			});
		} else {
			this.setInnerHtml(str);
		}
	}

	private setInnerHtml(html: string) {
		this.el.nativeElement.innerHTML = html;

		this.destroyComponents();

		this.replaceTags("script");
		this.replaceTags("style");
		this.buildComponents("cm-brands", BrandsComponent, []);
		this.buildComponents("cm-floor-plan-types", FloorPlanTypesComponent, ["data"]);
		this.buildComponents("cm-image", ImageComponent, ["obj"]);
		this.buildComponents("cm-live-tracker", LiveTrackerComponent, []);
		this.buildComponents("cm-reviews", ReviewsComponent, []);
		this.buildComponents("cm-sales-staff", SalesStaffComponent, ["app"]);
		this.buildComponents("cm-store-map", StoreMapComponent, []);
	}

	private replaceTags(tagName: string) {
		const divs = this.el.nativeElement.querySelectorAll<HTMLScriptElement>(tagName);
		// tslint:disable-next-line: prefer-for-of
		for (let i = 0; i < divs.length; ++i) {
			const el = divs[i];
			const parent = el.parentElement!;
			const next = el.nextElementSibling;

			el.remove();

			const newTag = this.document.createElement(tagName);
			newTag.textContent = el.textContent;
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < el.attributes.length; i++) {
				const attr = el.attributes[i];

				newTag.setAttribute(attr.name, attr.value);
			}
			parent.insertBefore(newTag, next);
		}
	}

	private buildComponents<T>(selector: string, component: Type<T>, inputs: string[]) {
		const componentFactory = this.module.componentFactoryResolver.resolveComponentFactory(component);

		for (const componentEl of this.el.nativeElement.querySelectorAll<HTMLElement>(selector)) {
			const component = componentFactory.create(this.module.injector, [], componentEl, this.module);

			for (const input of inputs) {
				(component.instance as any)[input] = this.getAttributeValue(componentEl, input);
			}

			component.hostView.detectChanges();

			this.components.push(component as ComponentRef<any>);
		}
	}

	private destroyComponents() {
		for (const component of this.components) {
			component.destroy();
		}
		this.components = [];
	}

	private getAttributeValue(el: HTMLElement, name: string): any {
		return option(el.getAttribute(`[${name}]`))
			.map((str) => evalWithContext(`(() => (${str}))()`, this))
			.orElse(() => option(el.getAttribute(name)))
			.unwrapOr(undefined);
	}
}

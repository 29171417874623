<div class="row">
	<div class="col w-full">
		<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
		<div *ngIf="intro" [innerHTML]="intro"></div>
		<div class="w-full mb-1">
			<input
				#input
				type="number"
				[id]="id"
				[name]="name"
				[disabled]="disabled"
				[required]="required"
				[attr.autocomplete]="autocomplete"
				[attr.pattern]="pattern"
				[(ngModel)]="value"
				(ngModelChange)="valueChange.emit($event)"
				[ngStyle]="inputStyle"
				class="form-control"
				step="any"
				(blur)="validate()"
			/>
		</div>
		<label class="text-xs text-red-700" *ngIf="error">{{ error }}</label>
	</div>
</div>

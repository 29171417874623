import { Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-footer-meta",
	template: `
		<ng-container [ngSwitch]="footerTemplate">
			<cm-asc-footer *ngSwitchCase="'asc'"></cm-asc-footer>
			<cm-footer-athome *ngSwitchCase="'athome'" class="pb-3"></cm-footer-athome>
			<cm-footer-design-force *ngSwitchCase="'designforce'"></cm-footer-design-force>
			<cm-footer-anthony *ngSwitchCase="'anthony'"></cm-footer-anthony>
			<cm-footer *ngSwitchDefault [template]="footerTemplate"></cm-footer>
		</ng-container>
	`,
})
export class FooterMetaComponent {
	footerTemplate = this.pageData.footerTemplate || "default";

	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}
}

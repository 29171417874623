import { Component, Inject, OnInit } from "@angular/core";
import { ImageService } from "@core/app/image.service";
import { ConfigService } from "@core/app/config.service";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-asc-footer",
	templateUrl: "./asc-footer.component.html",
	styleUrls: ["./asc-footer.component.scss"],
})
export class AscFooterComponent implements OnInit {
	headerData: any = {};
	footer: any = {};

	constructor(
		private config: ConfigService,
		private imageService: ImageService,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.headerData = this.pageData.headerData;
		this.footer = {
			logo: this.imageService.make({
				src: this.config.staticUrl + "footer-logo.png",
				size: "i",
				class: "footer-logo",
			}),
			menu: {
				type: "linear",
				items: [],
			},
		};
	}

	addMenuItem(item: any, menu: any) {
		const menuItem = {
			text: item.app_menu_item,
			link: item.custom_url,
		};

		menu.push(menuItem);
	}

	ngOnInit() {
		for (const item of this.headerData.queries.GetMainMenu) {
			if (item.of_depth === 0 && item.app_menu_item_ofid === null) {
				this.addMenuItem(item, this.footer.menu.items);
			}
		}
	}
}

import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "transport.integrityrvtransport",
		homeBreadcrumb: "Transport",
		themeData: {
			dealerAccent: {
				bg: "#9a121d",
				bgHover: "#9a121d",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#7d7d7d",
					bgHover: "#898989",
					fg: "#FFF",
				},
				homeUrl: "",
				footerUrl: "",
			},
		},
	},
};

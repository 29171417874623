import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FacebookModule } from "../facebook/facebook.module";
import { StepsTabsComponent } from "../finance/step-tabs/steps-tabs.component";
import { ImageModule } from "../image/image.module";
import { InstagramModule } from "../instagram/instagram.module";
import { InventoryModule } from "../inventory/inventory.module";
import { LayoutModule } from "../layout/layout.module";
import { Map2Module } from "../map2/map2.module";
import { Pagination2Module } from "../pagination2/pagination2.module";
import { PinterestModule } from "../pinterest/pinterest.module";
import { SearchModule } from "../search/search.module";
import { EmpGridAllComponent } from "../shared/components/emp-grid-all/emp-grid-all.component";
import { EmpGridComponent } from "../shared/components/emp-grid/emp-grid.component";
import { TwitterModule } from "../twitter/twitter.module";
import { AthomeExploreComponent } from "./athome-explore/athome-explore.component";
import { AthomeInventoryHomePageComponent } from "./athome-inventory-home-page/athome-inventory-home-page.component";
import { AthomeInventorySectionComponent } from "./athome-inventory-section/athome-inventory-section.component";
import { AthomeInventorySoldComponent } from "./athome-inventory-sold/athome-inventory-sold.component";
import { AthomeListingTileComponent } from "./athome-listing-tile.component";
import { AthomeLogoComponent } from "./athome-logo";
import { AthomeSearchComponent } from "./athome-search/athome-search.component";
import { AthomeBuysellGradientComponent } from "./components/athome-buysell-gradient/athome-buysell-gradient.component";
import { AthomeSearchFilterComponent } from "./components/athome-search-filter/athome-search-filter.component";
import { AthomeBlogCardsComponent } from "./components/blog/cards";
import { AthomeBlogPostComponent } from "./components/blog/post";
import { AthomeBuysellTocComponent } from "./components/buysell-toc/buysell-toc.component";
import { NumbgComponent } from "./components/numbg/numbg.component";

@NgModule({
	declarations: [
		AthomeListingTileComponent,
		AthomeInventorySectionComponent,
		AthomeLogoComponent,
		AthomeExploreComponent,
		AthomeSearchComponent,
		AthomeInventoryHomePageComponent,
		AthomeSearchFilterComponent,
		AthomeInventorySoldComponent,
		NumbgComponent,
		AthomeBuysellGradientComponent,
		AthomeBuysellTocComponent,
		EmpGridAllComponent,
		EmpGridComponent,
		StepsTabsComponent,
		AthomeBlogCardsComponent,
		AthomeBlogPostComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		Map2Module,
		InventoryModule,
		NgbModule,
		FormsModule,
		RouterModule,
		SearchModule,
		LayoutModule,
		Pagination2Module,
		ImageModule,
		PinterestModule,
		FacebookModule,
		InstagramModule,
		TwitterModule,
	],
	exports: [
		AthomeListingTileComponent,
		AthomeInventorySectionComponent,
		AthomeLogoComponent,
		AthomeExploreComponent,
		AthomeSearchComponent,
		AthomeInventoryHomePageComponent,
		AthomeSearchFilterComponent,
		AthomeInventorySoldComponent,
		NumbgComponent,
		AthomeBuysellGradientComponent,
		AthomeBuysellTocComponent,
		EmpGridAllComponent,
		EmpGridComponent,
		PinterestModule,
		StepsTabsComponent,
		FacebookModule,
		InstagramModule,
		TwitterModule,
		AthomeBlogCardsComponent,
		AthomeBlogPostComponent,
	],
})
export class AthomeModule {}

<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngIf="cannedResponses; else loading">
	<div>
		<cm-label text="Select Canned Response"></cm-label>
		<select class="form-control">
			<ng-container *ngFor="let cat of cannedResponseCats">
				<option disabled>
					<h6>{{ cat }}</h6>
				</option>
				<ng-container *ngFor="let response of cannedResponses.get(cat).unwrap()">
					<option (click)="onCannedResponseClick(response.value); d.close()" value="response.text">
						{{ response.text }}
					</option>
				</ng-container>
			</ng-container>
		</select>
	</div>
</div>

<textarea
	#input
	[name]="name"
	[disabled]="disabled"
	[required]="required"
	[attr.minlength]="minLen"
	[attr.maxlength]="maxLen"
	class="form-control mt-2"
	[(ngModel)]="value"
	(ngModelChange)="valueChange.emit($event)"
	(focus)="focused = true"
	(blur)="validate()"
></textarea>
<div *ngIf="!hideCount && focused && maxLen" class="small">{{ input.value.length }} / {{ maxLen }}</div>
<div class="invalid-feedback">{{ error }}</div>

<ng-template #loading>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

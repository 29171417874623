import { NgModule } from "@angular/core";
import { TwitterSlideshowComponent } from "./components/twitter-slideshow/twitter-slideshow.component";
import { SliderModule } from "../slider/slider.module";
import { CommonModule } from "@angular/common";

@NgModule({
	declarations: [TwitterSlideshowComponent],
	imports: [CommonModule, SliderModule],
	exports: [TwitterSlideshowComponent],
})
export class TwitterModule {}

import { Injectable, Inject } from "@angular/core";
import { ConfigService } from "@core/app/config.service";
import { ImageService } from "@core/app/image.service";
import { IPageData } from "@model/page-data";
import { IGetListingTiles } from "@model/stmt/GetListingTiles";
import { IListingTile } from "@model/listing-tile";
import { IHomeTile } from "@model/home-tile";
import { IGetHomes } from "@model/stmt/GetHomes";
import { format } from "date-fns";

@Injectable({
	providedIn: "root",
})
export class ListingService {
	appToken: any = {};
	noImage = "/s3static/" + this.pageData.appInfo.data.siteConfigId + "/no-photos.jpg";
	customBuild = "/s3static/" + this.pageData.appInfo.data.siteConfigId + "/still-at-factory.jpg";

	constructor(
		config: ConfigService,
		private imageService: ImageService,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.appToken = config.getAppToken();
	}

	makeCardObject(listing: any) {
		const listingTitle =
			(listing.hide_year === 1 ? "" : listing.model_year + " ") + listing.brand + " " + listing.model_num;
		const shortVin = listing.vin ? listing.vin.substr(-6) : "";
		const promotionImages: any = [];
		const url: any = null !== listing.promotionImgs ? listing.promotionImgs.split(",") : [];
		const lastChar = listing.vin ? listing.vin[listing.vin.length - 1] : null;
		const odd = lastChar ? parseInt(lastChar) % 2 === 1 : true;
		const images = [listing.img_dir + listing.img_file, listing.img_dir3 + listing.img_file3];
		for (const item of url) {
			promotionImages.push(item);
		}

		if (!images[0] && !images[1]) {
			if (images[0] === null) {
				images[0] = {};
			}

			if (images[1] === null) {
				images[1] = {};
			}

			images[0] = this.noImage;
		}

		if (!images[1]) {
			images[1] = this.noImage;
		}

		if (!images[0] && images[1]) {
			if (listing.on_order === 1) {
				images[0] = this.customBuild;
			} else {
				images[0] = this.noImage;
			}
		}

		return {
			vehicle_type: listing.vehicle_type,
			listingid: listing.listingid,
			condition: listing.condition,
			sectionHeader:
				listing.brand +
				(listing.parent_vehicle_type ? " " + listing.parent_vehicle_type : "") +
				" " +
				listing.vehicle_type,
			shortVin: shortVin,
			location:
				listing.location ||
				(listing.on_order === 1 && listing.sold !== 1
					? "ON ORDER"
					: this.pageData.headerData.queries.GetDealerInfo[0].city),
			unitName: listingTitle,
			heading: listingTitle + (listing.hide_year === 0 && shortVin ? " - " + shortVin : ""),
			msrp: listing.msrp,
			estMonthly:
				0 < listing.est_monthly_payment && null !== listing.est_monthly_payment
					? listing.est_monthly_payment
					: false,
			price: listing.price,
			promotionImages: promotionImages !== [] ? promotionImages : null,
			images: images,
			imageCount: listing.imgCount,
			currentImage: images[0] ? images[0] : images[1],
			link: listing.content_page_url,
			showPrice: listing.show_price === 1,
			sold: listing.sold === 1,
			videos: listing.videos === 1,
			virtualTour: listing.virtualTour === 1,
			onOrder: listing.on_order === 1,
			map: listing.map,
			callNow: listing.phone_number !== null,
			phoneNumber: listing.phone_number,
			tagline: listing.tagline || "Call Today",
			shippingTagline: odd ? this.pageData.settings.shipping_tagline : null,
			shippingIcon: odd
				? (this.pageData.shippingIcon as any).img_dir + (this.pageData.shippingIcon as any).img_file
				: null,
			priceTagLine: this.pageData.settings.listing_card_tagline,
			priceDropIcon: !odd
				? (this.pageData.priceDropIcon as any).img_dir + (this.pageData.priceDropIcon as any).img_file
				: null,
			promotionImage: listing.promotion_img_dir + listing.promotion_img_file,
		};
	}

	makeTileObject(data: IGetListingTiles): IListingTile {
		return {
			content_page_url: data.content_page_url,
			model_year: data.hide_year !== 1 ? data.model_year : null,
			brand: data.brand,
			model_num: data.model_num,
			floorPlan: this.makeImg(data, "floorPlan_", "s"),
			mainPhoto: this.makeImg(data, "main_", "s"),
			vin: data.hide_year !== 1 ? data.vin : null,
			financingText: "10% Down, 6.99% APR, 144 Mos. WAC.",
			msrp: data.msrp,
			price: data.price,
			difference: parseFloat(data.msrp) - parseFloat(data.price),
			est_monthly_payment: data.est_monthly_payment,
		};
	}

	makeHomeTileObject(data: IGetHomes): IHomeTile {
		return {
			content_page_url: data.content_page_url,
			address1: data.address1,
			city: data.city,
			abbrev: data.abbrev,
			postal_code: data.postal_code,
			beds_total: data.beds_total,
			baths_total: data.baths_total,
			square_feet: data.square_feet,
			img_file: data.img_file,
			img_dir: data.img_dir,
			latitude: parseFloat(data.latitude),
			longitude: parseFloat(data.longitude),
			msrp: parseFloat(data.msrp),
			highlight: false,
			sold: data.sold,
			pending_sale: data.pending_sale,
			open_house_date: data.open_house_date,
			timeOnSite: data.timeOnSite,
			start_time: data.start_time,
			end_time: data.end_time,
			property_type: data.property_type,
			lot_acres: data.lot_acres,
			img_count: data.img_count,
			openHouse: this.openHouse(data),
			cssClass: this.cssClass(data),
			header: this.headerMessage(data),
			listingid: data.listingid,
		};
	}

	openHouse(data: IGetHomes): string | null {
		let returnValue: string | null = null;
		if (data.open_house_date && data.start_time) {
			const date = format(data.open_house_date + " " + data.start_time, "ddd., M/D");
			const start = this.time24To12(data.start_time);
			returnValue = "Open House " + date + " @ " + start;
		}
		return returnValue;
	}

	time24To12(a: string): string {
		return new Date("1955-11-05T" + a + "Z").toLocaleTimeString("bestfit", {
			timeZone: "UTC",
			hour12: !0,
			hour: "numeric",
			minute: "numeric",
		});
	}

	cssClass(data: IGetHomes): string {
		if (data.sold === 1) {
			return "stripe-gray";
		} else if (data.pending_sale === 1) {
			return "stripe-gray";
		} else if (data.timeOnSite <= 3) {
			return "stripe-red";
		} else {
			return "stripe-green";
		}
	}

	headerMessage(data: IGetHomes): string {
		if (data.sold === 1) {
			return "Sold";
		} else if (data.pending_sale === 1) {
			return "Pending";
		} else if (data.timeOnSite <= 3) {
			return "Hot Listing!";
		} else {
			return "Active";
		}
	}

	makeImg(data: IGetListingTiles, prefix: string, size: string) {
		return this.imageService.make({
			img: data[prefix + "img"],
			img_width: data[prefix + "img_width"],
			img_height: data[prefix + "img_height"],
			img_alt: data[prefix + "img_alt"],
			img_file: data[prefix + "img_file"],
			img_dir: data[prefix + "img_dir"],
			size,
		});
	}
}

import {
	Component,
	HostBinding,
	Input,
	ElementRef,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
} from "@angular/core";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";

let nextIdNum = 0;

@Component({
	selector: "cm-field-file-multiple",
	templateUrl: "./field-file-multiple.component.html",
	styleUrls: ["./field-file-multiple.component.scss"],
})
export class FieldFileMultipleComponent {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;

	@Output() valueChange = new EventEmitter<(FileList | null)[]>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChildren("inputs") inputs!: QueryList<ElementRef<HTMLInputElement>>;

	faPlus = faPlus;
	faTimes = faTimes;

	idNum: number = 0;
	error: string | null = null;
	focused: boolean = false;
	validated: boolean = false;
	dummy: null[] = [];
	value: (FileList | null)[] = [null];

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-text-multiple-${this.idNum}`;
	}

	onAddClick() {
		this.value.push(null);
		this.onChange();
	}

	onDeleteClick(index: number) {
		this.value.splice(index, 1);
		this.onChange();
	}

	onFileChange(index: number, files: FileList) {
		this.value[index] = files;
		this.onChange();
	}

	onChange() {
		this.valueChange.emit(this.value);
	}

	trackByFn(index: number, _item: any): any {
		return index;
	}

	validate() {
		for (const el of this.inputs.toArray()) {
			if (!el.nativeElement.checkValidity()) {
				this.error = el.nativeElement.validationMessage;
			} else {
				this.error = null;
			}
		}

		this.validated = true;
	}
}

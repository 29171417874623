import { Component, Input, OnInit, OnChanges, SimpleChange, Inject, ViewEncapsulation } from "@angular/core";
import { ConfigService } from "../config.service";

@Component({
	selector: "cm-card",
	templateUrl: "./card.component.html",
	styleUrls: ["./card.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit, OnChanges {
	appToken: any = {};
	siteName: string = "";
	template: string = "card";
	siteLogo: any = {};
	siteUrl: string = "";
	showQuick: boolean = false;
	@Input() options: any = null;

	constructor(@Inject(ConfigService) private config: ConfigService, @Inject("PAGE_DATA") pageData: any) {
		this.appToken = this.config.getAppToken();
		this.siteUrl = "https://" + pageData.host + "/";
	}

	ngOnInit() {
		this.siteName = this.appToken.siteName;
		this.siteLogo = this.appToken.siteLogo;
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.options) {
			this.options = changes.options.currentValue;

			if (!this.options.hasOwnProperty("alignButtons")) {
				this.options.alignButtons = "text-center";
			} else {
				this.options.alignButtons = "text-" + this.options.alignButtons;
			}

			if (!this.options.hasOwnProperty("author")) {
				this.options.author = true;
			}

			if (!this.options.hasOwnProperty("publisher")) {
				this.options.publisher = true;
			}

			if (this.options.layoutRow) {
				this.template = "cardRow";
			} else {
				this.template = "card";
			}
		}
	}
}

import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "soldbystevo",
		homeBreadcrumb: "Sold By Stevo",
		themeData: {
			dealerAccent: null,
		},
	},
};

import { DOCUMENT } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnDestroy,
	Renderer2,
	ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { RvFilterState, RvInventoryService } from "@core/app/inventory/services/rv-inventory.service";
import { UserService } from "@core/app/user.service";
import {
	faBars,
	faCaretDown,
	faChevronDown,
	faCogs,
	faHeart,
	faSignOut,
	faTimes,
	faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { FilterImageStyle } from "@model/search-filter-option";
import { merge, ReplaySubject, Subject } from "rxjs";
import { filter, first, map, startWith, takeUntil, distinctUntilChanged } from "rxjs/operators";
import { ViewportService } from "../shared/services/viewport.service";

@Component({
	selector: "cm-menu",
	templateUrl: "./menu.component.html",
	styleUrls: ["./menu.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnDestroy {
	@Input() imgMenuData: any = {};
	@Input() imgMenuHomeLink: string = "/";
	@Input() imgMenuHomeLinkText: string = "Home";
	@Input() imgMenuButtonLabel: string | null = null;
	@Input() imgMenuAutocompleteData: any = null;
	@Input() imgMenuAutocompleteOptions: any = null;
	@Input() caret: boolean = false;

	faChevronDown = faChevronDown;
	faBars = faBars;
	faTimes = faTimes;
	faHeart = faHeart;
	faUsers = faUsers;
	faCogs = faCogs;
	faSignOut = faSignOut;
	faCaretDown = faCaretDown;

	toggleMenuS: Subject<boolean> = new Subject();

	menuShow$ = merge(
		this.toggleMenuS,
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => false),
		),
	).pipe(
		startWith(false),
		distinctUntilChanged(),
	);

	state: RvFilterState = this.inventory.createState();
	filterSettings = [
		{ field: "vehicle_type", label: "Type", imageStyle: FilterImageStyle.Show },
		{ field: "brand", label: "Brand", imageStyle: FilterImageStyle.OnHover },
		{ field: "price_range", label: "Price Range" },
	];

	ngOnDestroyRS: ReplaySubject<void> = new ReplaySubject();

	constructor(
		public userService: UserService,
		public viewport: ViewportService,
		private inventory: RvInventoryService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		renderer: Renderer2,
	) {
		this.menuShow$.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((menuShow) => {
			if (menuShow) {
				renderer.addClass(this.document.body, "overflow-hidden");
			} else {
				renderer.removeClass(this.document.body, "overflow-hidden");
			}
		});
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}

	itemClick(event: any, item: any): void {
		this.viewport.mobile$.pipe(first()).subscribe((mobile) => {
			if (mobile) {
				if (item.items && item.items.length > 0) {
					event.preventDefault();
					item.visible = !item.visible;
				} else {
					if (item.hasOwnProperty("clicked")) {
						item.clicked(event, item);
					}

					this.toggleMenuS.next(false);
				}
			} else {
				if (item.clicked) {
					item.clicked(event, item);
				}
				if (!item.link || item.link === "/#" || item.link === "#") {
					event.preventDefault();
					event.stopPropagation();
				}
			}
		});
	}

	showDropdown(menuItem: any, show: boolean): void {
		menuItem.showDropdown = show;
	}

	logout() {
		this.userService.logOut();
	}
}

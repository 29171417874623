<div class="cm-image-grid row justify-content-center align-items-center">
	<div *ngIf="gridData && gridData.length < 1 && !loaded" class="text-center">
		<fa-icon [icon]="faSpinner" [pulse]="true" [fixedWidth]="true"></fa-icon>
	</div>
	<ng-container *ngIf="template === 'linkWrap'">
		<div *ngFor="let item of gridData; let index = index" [class]="classString">
			<div
				class="cm-image-grid-item {{ item.class }} {{ index === 0 ? firstOffset : '' }}"
				(click)="item.clicked($event)"
			>
				<a routerLink="{{ item.link }}" itemprop="url">
					<cm-image2 [image]="item.image"></cm-image2>
					<div class="image-text text-center" *ngIf="item.text" [innerHTML]="item.text"></div>
				</a>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="template === 'wrapItemType'">
		<div *ngFor="let item of gridData; let index = index" [class]="classString">
			<div
				class="cm-image-grid-item {{ item.class }} {{ index === 0 ? firstOffset : '' }}"
				itemType="attrs.itemType"
				itemscope="attrs.itemscope"
				(click)="item.clicked($event)"
			>
				<span>
					<meta itemprop="mainEntityOfPage" content="{{ item.link }}" />
					<meta itemprop="headline" content="{{ item.text }}" />
				</span>
				<cm-image2 [image]="item.image"></cm-image2>
				<br *ngIf="item.text" />
				<span class="image-text" *ngIf="item.text" [innerHTML]="item.text"></span>
				<span *ngIf="gridItemType">
					<meta itemprop="datePublished" content="{{ item.datePublished }}" />
					<meta itemprop="dateModified" content="{{ item.dateModified }}" />
					<meta itemprop="author" content="{{ siteName }}" />
					<span itemprop="publisher" itemscope itemtype="http://schema.org/Organization">
						<meta itemprop="name" content="{{ siteName }}" />
						<span itemprop="logo" itemscope itemtype="http://schema.org/ImageObject">
							<meta itemprop="url" content="{{ siteUrl }}" />
							<meta itemprop="image" content="{{ siteLogo.url }}" />
							<meta itemprop="width" content="{{ siteLogo.width }}px" />
							<meta itemprop="height" content="{{ siteLogo.height }}px" />
						</span>
					</span>
				</span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="template === 'wrapNoItemType'">
		<div *ngFor="let item of gridData; let index = index" [class]="classString">
			<ng-container *ngIf="!item.link">
				<div
					class="cm-image-grid-item {{ item.class }} {{ index === 0 ? firstOffset : '' }}"
					(click)="item.clicked($event)"
				>
					<cm-image2 [image]="item.image"></cm-image2>
					<div class="text-center" *ngIf="item.text" [innerHTML]="item.text"></div>
				</div>
			</ng-container>
			<ng-container *ngIf="item.link">
				<a routerLink="{{ item.link }}">
					<div class="cm-image-grid-item {{ item.class }} {{ index === 0 ? firstOffset : '' }}">
						<cm-image2 [image]="item.image"></cm-image2>
						<div class="text-center" *ngIf="item.text" [innerHTML]="item.text"></div>
					</div>
				</a>
			</ng-container>
		</div>
	</ng-container>
</div>

import { Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-footer-athome",
	template: `
		<div class="container-fluid" itemscope itemtype="http://schema.org/WPFooter">
			<meta itemprop="copyrightYear" [content]="year" />
			<meta itemprop="copyrightHolder" [content]="siteName" />
			<div class="row">
				<div class="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start">
					<cm-locations></cm-locations>
				</div>
				<div class="col-12 col-lg-4 my-4 my-lg-0 d-flex flex-column text-center">
					<a routerLink="/" class="d-block my-2 home-link">
						<div class="flex-grow-1">
							<span class="text-athome-gray">@</span
							><span class="text-athome-green"><b>Home</b>Realty</span>
						</div>
					</a>
					<cm-social-links2></cm-social-links2>
				</div>
				<div class="col-12 col-lg-4 text-center text-lg-right d-flex flex-column">
					<cm-trust-icons class="mb-1"></cm-trust-icons>
					<div class="flex-grow-1 mr-1">
						<div class="mb-1 text-muted">
							Real Estate Website Design & Internet Marketing by
							<a href="https://designforcemarketing.com/">Design Force</a>.
						</div>
						<div class="mb-1 text-muted">
							&copy; Copyright {{ year }} {{ siteName }}. All Rights Reserved.
						</div>
						<div>
							<a [routerLink]="'/terms-of-use'">Terms of Use</a> |
							<a [routerLink]="'/privacy-policy'">Privacy Policy</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ["./footer-athome.component.scss"],
})
export class FooterAthomeComponent {
	year = new Date().getFullYear();
	siteName = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}
}

<div class="cm-quick-bar theme-bg">
	<div class="share addthis_share" [ngClass]="{ active: shareButtonsActive }">
		<div class="addthis_share_button" *ngFor="let btn of shareButtons">
			<div class="share-button {{ btn.service }}" (click)="share(btn)">
				<fa-icon [icon]="btn.icon" [fixedWidth]="btn.iconFixedWidth"></fa-icon>
			</div>
		</div>
	</div>
	<cm-button-grid [gridData]="buttons" [classes]="['quick-button']"></cm-button-grid>
</div>

<ng-template #salesPersonQuestion let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Have A Question?</h4>
	</div>
	<div class="modal-body">
		<div class="card card-default" [ngClass]="{ 'multi-salesperson': salespeople.length > 1 }">
			<div class="card-header">For More Information Contact</div>
			<div class="card-body">
				<!--Contact Info-->
				<div class="row contact-info">
					<div [ngClass]="{ 'col-12': salespeople.length === 1, 'col-6': salespeople.length > 1 }">
						<!--Salesperson Image-->
						<cm-image
							*ngIf="salespeople[0].image"
							[obj]="salespeople[0].image"
							class="salesperson-image"
							itemtype="http://schema.org/ImageObject"
						></cm-image>
						<div *ngIf="!salespeople[0].image" class="salesperson-image">
							<fa-icon class="avatar" [icon]="faUserCircle"></fa-icon>
						</div>
						<!--SalesPerson Info-->
						<div class="salesperson-info">
							<p class="specialist">{{ salespeople[0].brand }} Specialist</p>
							<p class="salesperson-name theme-highlight-as-fg">
								{{ salespeople[0].name }}
								<span class="d-none d-md-inline" *ngIf="salespeople[0].online">(online now)</span>
							</p>
							<p class="d-none d-md-block">
								Call now for any RV information you need. We have the nation's lowest prices guaranteed!
							</p>
						</div>
						<cm-image
							*ngIf="salespeople.length === 1 && logo"
							[obj]="logo"
							class="brand-logo"
							itemtype="http://schema.org/ImageObject"
						></cm-image>
					</div>
					<div *ngIf="salespeople.length > 1" class="col-6">
						<!--Salesperson Image-->
						<cm-image
							*ngIf="salespeople[1].image"
							[obj]="salespeople[1].image"
							class="salesperson-image d-none d-md-block"
							itemtype="http://schema.org/ImageObject"
						></cm-image>
						<div *ngIf="!salespeople[1].image" class="salesperson-image d-none d-md-block">
							<fa-icon class="avatar" [icon]="faUserCircle"></fa-icon>
						</div>
						<!--SalesPerson Info-->
						<div class="salesperson-info">
							<p *ngIf="salespeople.length > 1" class="specialist d-none d-md-block">
								{{ salespeople[1].brand }} Specialist
							</p>
							<p
								*ngIf="salespeople.length > 1"
								class="salesperson-name d-none d-md-block theme-highlight-as-fg"
							>
								{{ salespeople[1].name }}
								<span class="d-none d-md-inline" *ngIf="salespeople[1].online">(online now)</span>
							</p>
							<cm-image
								*ngIf="logo"
								[obj]="logo"
								class="brand-logo"
								itemtype="http://schema.org/ImageObject"
							></cm-image>
						</div>
					</div>
				</div>
			</div>
			<!--Contact Buttons-->
			<cm-button-grid
				[gridData]="salespeople[0].buttons"
				[gridXs]="6"
				[gridSm]="6"
				[gridMd]="6"
				[gridLg]="6"
			></cm-button-grid>
		</div>
		<div class="price-request-form">
			<div class="card card-default theme-highlight-border">
				<div class="card-header theme-highlight">
					Can't Call? Get the Nation's Best Price!
				</div>
				<div class="card-body">
					<p>
						Manufacturers & Dealers Won't Let Us Advertise RV Prices.<br />{{ dealer.getName() }} Price
						Guarantee - We Will Beat Any RV Price In The Nation!
					</p>
					<cm-form
						[formid]="15"
						[gtmEvent]="'Price Request'"
						[siteActionId]="10"
						[prefill]="prefillData"
						(onSubmit)="modal.dismiss()"
						(onReady)="setupForm()"
					></cm-form>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
	</div>
</ng-template>

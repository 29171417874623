import { Component, HostBinding, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";

let nextIdNum = 0;

@Component({
	selector: "cm-field-textarea",
	templateUrl: "./field-textarea.component.html",
	styleUrls: ["./field-textarea.component.scss"],
})
export class FieldTextareaComponent {
	@Input() name!: string;
	@Input() placeholder: string = "";
	@Input() disabled: boolean = false;
	@Input() hideCount: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() minLen?: number;
	@Input() maxLen?: number;
	@Input() required: boolean = false;
	@Input() value: string | null = null;

	@Output() valueChange = new EventEmitter<string | null>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	idNum: number = 0;
	error: string | null = null;
	focused: boolean = false;
	validated: boolean = false;

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-textarea-${this.idNum}`;
	}

	onModelChange() {
		if (this.value === "") {
			this.value = null;
		}

		this.valueChange.emit(this.value);
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}
}

<div class="cm-card-grid">
	<ul *ngIf="grid.type === 'default'" class="row default-list">
		<li *ngFor="let item of grid.items; let index = index" class="h-100 col card">
			<a href="{{ item.link }}" class=" h-100 d-flex flex-column">
				<div class="text-center embed-responsive embed-responsive-16by9">
					<div class="embed-responsive-item d-flex align-items-center">
						<cm-image2 *ngIf="item.image" [image]="item.image"></cm-image2>
					</div>
				</div>
				<cm-button *ngIf="item.button" [obj]="item.button"></cm-button>
				<div *ngIf="item.title" class="card-title" [innerHTML]="item.title"></div>
				<p
					*ngIf="item.description"
					class="card-description card-body flex-grow-1 p-1"
					[innerHTML]="item.description"
				></p>
			</a>
		</li>
	</ul>
	<div *ngIf="grid.type === 'card'" class="col-12">
		<div *ngIf="grid.items.length < 1" class="row text-center">
			<fa-icon [icon]="faSpinner" [pulse]="true" [fixedWidth]="true"></fa-icon>
		</div>
		<div *ngIf="grid.items.length > 0" class="row d-flex justify-content-center">
			<h2 *ngIf="grid.topHeading" class="theme-primary-font-color col-12">{{ grid.topHeading }}</h2>
			<div
				class="{{ grid.cardGridClasses }}"
				*ngFor="let item of grid.items; let index = index"
				[cmAttr]="grid.attrs"
			>
				<cm-card [options]="item" class="{{ grid.cardClass }}"></cm-card>
			</div>
		</div>
	</div>
</div>

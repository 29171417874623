<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngFor="let v of value; index as i; trackBy: trackByFn" class="flex" [ngClass]="{ 'mt-2': i > 0 }">
	<div class="flex-grow mr-2">
		<label for="{{ id }}-{{ i }}-desc">Description</label>
		<input
			#input
			type="text"
			id="{{ id }}-{{ i }}-desc"
			name="desc"
			[(ngModel)]="v.desc"
			[disabled]="disabled"
			[required]="required"
			class="form-control"
		/>
	</div>
	<div>
		<label for="{{ id }}-{{ i }}-number">Amount</label>
		<input
			#input2
			class="form-control"
			type="number"
			id="{{ id }}-{{ i }}-number"
			name="number"
			step="any"
			[(ngModel)]="v.number"
			[disabled]="disabled"
			[required]="required"
		/>
	</div>
	<ng-container *ngIf="i === 0; else delete">
		<div class="flex align-content-end">
			<button type="button" class="btn btn-primary ml-3 add-delete" (click)="onAddClick()">
				<fa-icon [icon]="faPlus"></fa-icon>
			</button>
		</div>
	</ng-container>
	<ng-template #delete>
		<div class="flex align-content-end">
			<button type="button" class="btn btn-outline-danger ml-3 add-delete" (click)="onDeleteClick(i)">
				<fa-icon [icon]="faTimes"></fa-icon>
			</button>
		</div>
	</ng-template>
</div>
<div class="invalid-feedback">{{ error }}</div>

import { Observable } from "rxjs";

export interface ISearchFilterOption {
	key: string;
	results: any[];
	label: string;
	name: string;
	type: string;
	sortField: string | null;
	sortOrder: string | null;
	queryParams: any;
	imageStyle?: FilterImageStyle;
	show$?: Observable<boolean>;
	col?: number;
	filters?: ISearchFilterOption[];
}

export enum FilterImageStyle {
	Show,
	OnHover,
	Above,
	// None,
}

declare let CallTrk: any;
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { faFacebook, faPinterest, faStumbleupon, faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
	faArrowCircleUp,
	faEdit,
	faEnvelope,
	faHandshake,
	faPhone,
	faRss,
	faShareAlt,
	faStar,
	faSync,
	faTag,
	faTags,
	faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppService } from "../app.service";
import { iterObj } from "../common/iter";
import { cloneDeep, loadScript } from "../common/util";
import { DealerService } from "../dealer.service";

declare function addthis_sendto(...args: any[]): any;

@Component({
	selector: "cm-quick-bar",
	templateUrl: "./quick-bar.component.html",
	styleUrls: ["./quick-bar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuickBarComponent implements OnDestroy {
	buttons: any[];
	initialButtons: any[];
	onScroll: Subscription | null = null;
	shareButtons: any[];
	shareButtonsActive: boolean;
	salespeople: any = {};
	logo: any = {};
	prefillData: any = {};
	faUserCircle = faUserCircle;

	@ViewChild("salesPersonQuestion", { static: true }) salesPersonQuestion!: any;

	constructor(
		public dealer: DealerService,
		public app: AppService,
		router: Router,
		private modalService: NgbModal,
		@Inject(DOCUMENT) private document: any,
		@Inject("PAGE_DATA") pageData: any,
	) {
		const appInfo = pageData.appInfo;

		if (appInfo.data.siteConfigId !== 11 && appInfo.data.siteConfigId !== 9) {
			this.buttons = [
				{
					id: "new",
					text: "New",
					icon: faStar,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/new-rvs",
				},
				{
					id: "used",
					text: "Used",
					icon: faSync,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/used-rvs",
				},
				{
					id: "clearance",
					text: "Clearance",
					icon: faTags,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/clearance-rv-sale",
				},
				{
					id: "call",
					text: "Call",
					css: {
						classes: "btn cm-button-full theme-highlight",
					},
					icon: faPhone,
					iconFixedWidth: true,
					link: "tel:" + dealer.getPhone().replace(/[^\d]/g, ""),
				},
				{
					id: "share",
					text: "Share",
					icon: faShareAlt,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					clicked: () => {
						loadScript(`//s7.addthis.com/js/300/addthis_widget.js#pubid=${pageData.addThisId}`);

						this.shareButtonsActive = !this.shareButtonsActive;
					},
				},
				{
					id: "top",
					text: "Top",
					icon: faArrowCircleUp,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full top-button",
					},
					clicked: () => {
						this.document.body.scrollIntoView();
					},
				},
			];
		} else if (appInfo.data.siteConfigId === 9) {
			this.buttons = [
				{
					id: "new",
					text: "New",
					icon: faStar,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/new-rvs",
				},
				{
					id: "tradein",
					text: "Trade-In",
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					icon: faHandshake,
					iconFixedWidth: true,
					link: "/rv-trade-in-form",
				},
				{
					id: "creditapp",
					text: "Credit App",
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					icon: faEdit,
					iconFixedWidth: true,
					link: "/rv-credit-application",
				},
				{
					id: "blog",
					text: "Blog",
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					icon: faRss,
					iconFixedWidth: true,
					link: "/blog",
				},
				{
					id: "call",
					text: "Call",
					css: {
						classes: "btn cm-button-full theme-highlight",
					},
					icon: faPhone,
					iconFixedWidth: true,
					link: "tel:" + dealer.getPhone().replace(/[^\d]/g, ""),
				},
				{
					id: "share",
					text: "Share",
					icon: faShareAlt,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					clicked: () => {
						loadScript(`//s7.addthis.com/js/300/addthis_widget.js#pubid=${pageData.addThisId}`);

						this.shareButtonsActive = !this.shareButtonsActive;
					},
				},
				{
					id: "top",
					text: "Top",
					icon: faArrowCircleUp,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full top-button",
					},
					clicked: () => {
						this.document.body.scrollIntoView();
					},
				},
			];
		} else {
			this.buttons = [
				{
					id: "inventory",
					text: "Inventory",
					icon: faStar,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/inventory",
				},
				{
					id: "consignment",
					text: "Consignment",
					icon: faTag,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/consignment",
				},
				{
					id: "contact",
					text: "Contact Us",
					icon: faEnvelope,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					link: "/contact-us",
				},
				{
					id: "call",
					text: "Call",
					css: {
						classes: "btn cm-button-full theme-highlight",
					},
					icon: faPhone,
					iconFixedWidth: true,
					link: "tel:" + dealer.getPhone().replace(/[^\d]/g, ""),
				},
				{
					id: "share",
					text: "Share",
					icon: faShareAlt,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full theme-bg",
					},
					clicked: () => {
						loadScript(`//s7.addthis.com/js/300/addthis_widget.js#pubid=${pageData.addThisId}`);

						this.shareButtonsActive = !this.shareButtonsActive;
					},
				},
				{
					id: "top",
					text: "Top",
					icon: faArrowCircleUp,
					iconFixedWidth: true,
					css: {
						classes: "btn cm-button-full top-button",
					},
					clicked: () => {
						this.document.body.scrollIntoView();
					},
				},
			];
		}

		this.shareButtons = [
			{
				id: "facebook",
				icon: faFacebook,
				iconFixedWidth: true,
				service: "facebook",
			},
			{
				id: "pinterest",
				icon: faPinterest,
				iconFixedWidth: true,
				service: "pinterest",
			},
			{
				id: "youtube",
				icon: faTwitter,
				iconFixedWidth: true,
				service: "twitter",
			},
			{
				id: "stumbleupon",
				icon: faStumbleupon,
				iconFixedWidth: true,
				service: "stumbleupon",
			},
		];

		this.shareButtonsActive = false;

		this.initialButtons = cloneDeep(this.buttons);
		const callButton = this.buttons.find(({ id }: any) => id === "call");

		if (callButton) {
			callButton.link = "tel:" + pageData.headerData.queries.GetDealerInfo[0].phone_number.replace(/[^\d]/g, "");
		}

		app.register("QuickBarUpdate", (updates: any) => {
			if (updates.hasOwnProperty("hide")) {
				for (const k of updates.hide) {
					const b = this.buttons.find(({ id }: any) => id === k);

					if (b) {
						b.active = false;
					}
				}
			}

			let modified = false;

			if (updates.hasOwnProperty("prepend")) {
				for (const p of updates.prepend) {
					if (!this.buttons.find(({ id }: any) => id === p.id)) {
						if (p.hasOwnProperty("data")) {
							for (const [key, value] of iterObj<any>(p.data)) {
								(this as any)[key] = value;
							}
						}
						if (p.id === "question") {
							p.clicked = () => this._openQuestionPopup();
						}
						this.buttons.unshift(Object.assign({ cssClasses: "btn theme-bg" }, p));
					} else {
						const b = this.buttons.find(({ id }: any) => id === p.id);
						b.active = true;
					}
				}

				modified = true;
			}

			if (updates.hasOwnProperty("append")) {
				for (const a of updates.append) {
					if (!this.buttons.find(({ id }: any) => id === a.id)) {
						this.buttons.push(Object.assign({ cssClasses: "btn theme-bg" }, a));
					} else {
						const b = this.buttons.find(({ id }: any) => id === a.id);
						b.active = true;
					}
				}

				modified = true;
			}

			if (updates.hasOwnProperty("update")) {
				for (const u of updates.update) {
					const b = this.buttons.find(({ id }: any) => id === u.id);

					if (b) {
						for (const [k, v] of iterObj(u)) {
							b[k] = v;
						}
					}
				}

				modified = true;
			}

			if (modified) {
				this.buttons = cloneDeep(this.buttons);
			}
		});

		if (typeof window !== "undefined") {
			this.onScroll = fromEvent(window, "scroll").subscribe(() => {
				this.shareButtonsActive = false;
			});
		}

		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.buttons = cloneDeep(this.initialButtons);
		});
	}

	ngOnDestroy(): void {
		if (this.onScroll) {
			this.onScroll.unsubscribe();
		}
	}

	share(btn: any) {
		addthis_sendto(btn.service);
	}

	setupForm() {
		this.document.querySelector(".price-request-form .form-footer button").text("Get Lowest Price");
	}

	_openQuestionPopup() {
		this.modalService.open(this.salesPersonQuestion, { size: "lg", windowClass: "salesperson-question-modal" });
		setTimeout(() => {
			if (typeof CallTrk === "object") {
				CallTrk.swap();
			}
		});
	}
}

import { Component, Input } from "@angular/core";

@Component({
	selector: "cm-image2",
	template: `
		<ng-container *ngIf="image">
			<img
				loading="lazy"
				[src]="image.src"
				[alt]="image.alt"
				[title]="image.title || image.alt"
				class="img-fluid {{ image.imgClass }}"
			/>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			img {
				object-fit: cover;
				object-position: 50% 50%;
				margin: auto;
			}
		`,
	],
})
export class Image2Component {
	@Input() image: any;
}

import { IOption, none } from "@common/option";
import { Field, FieldFile, FieldFileMultiple } from "@core/app/form/field";

export class FormField {
	field: Field;
	parent: IOption<FormField> = none();
	onAnswerChoiceid: IOption<string>;
	onRegex: IOption<RegExp>;

	constructor(field: Field, onAnswerChoiceid: IOption<string>, onRegex: IOption<RegExp>) {
		this.field = field;
		this.onAnswerChoiceid = onAnswerChoiceid;
		this.onRegex = onRegex;
	}

	get name(): string {
		return this.field.name;
	}

	clone(): FormField {
		return new FormField(this.field.clone(), this.onAnswerChoiceid, this.onRegex);
	}

	getValue(): any {
		return this.field.getValue();
	}

	setValue(val: any) {
		this.field.setValue(val);
	}

	visible(): boolean {
		return this.parent.match({
			some: (parent) => {
				return this.onRegex.match({
					some: (onRegex) => onRegex.test(parent.getValue()),
					none: () => parent.getValue() == this.onAnswerChoiceid.unwrap(),
				});
			},
			none: () => true,
		});
	}
}

export class FormRow {
	fields: FormField[];

	constructor(fields: FormField[] = []) {
		this.fields = fields;
	}

	clone(): FormRow {
		return new FormRow(this.fields.map((f) => f.clone()));
	}

	fieldsVisible(): FormField[] {
		return this.fields.filter((f) => f.visible());
	}

	async getData(): Promise<any> {
		const ret: any = {};

		for (const field of this.fields) {
			if (field.visible()) {
				if (field.field instanceof FieldFile || field.field instanceof FieldFileMultiple) {
					ret[field.name] = await field.field.getValuesAsBase64();
				} else {
					ret[field.name] = field.getValue();
				}
			}
		}

		return ret;
	}
}

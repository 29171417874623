<div class="row">
	<div class="col w-full">
		<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
		<div class="inline-block mx-1" *ngIf="intro" [innerHTML]="intro"></div>
		<div class="w-full mb-1">
			<textarea
				#input
				[id]="id"
				[name]="name"
				[disabled]="disabled"
				[required]="required"
				[attr.minlength]="minLen"
				[attr.maxlength]="maxLen"
				[(ngModel)]="value"
				(ngModelChange)="onModelChange()"
				class="form-control"
				(focus)="focused = true"
				(blur)="validate()"
			></textarea>
		</div>
		<label class="text-xs text-red-700" *ngIf="error">{{ error }}</label>
	</div>
</div>

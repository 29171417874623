import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ImageService } from "@core/app/image.service";
import { ISearchFilterOption, FilterImageStyle } from "@model/search-filter-option";

@Component({
	selector: "cm-checkbox-selector",
	templateUrl: "./checkbox-selector.component.html",
	styleUrls: ["./checkbox-selector.component.scss"],
})
export class CheckboxSelectorComponent implements OnChanges {
	// @ts-ignore
	@Input() filter: ISearchFilterOption;
	@Output() select = new EventEmitter();

	tracker = (i: number, f: any) => f.label;

	boxes: any = {};
	images = {};

	FilterImageStyle = FilterImageStyle;

	constructor(private imageService: ImageService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filter.currentValue) {
			this.checkForAndSetupImages(changes.filter.currentValue);
			this.setupBoxes(changes.filter.currentValue);
		}
	}

	checkForAndSetupImages(filter: ISearchFilterOption) {
		this.images = {};
		const result = filter.results[0];
		let size = "i";
		switch (filter.imageStyle) {
			case FilterImageStyle.OnHover:
				size = "t";
				break;
			case FilterImageStyle.Above:
				size = "t";
				break;
		}
		this.images = filter.results.reduce((acc, result) => {
			if (result.img_path) {
				acc[result.id] = this.imageService.make({
					src: result.img_path,
					altText: result.img_alt,
					size: size,
					span: true,
				});
			}
			return acc;
		}, {});
	}

	setupBoxes(filter: ISearchFilterOption): void {
		const filterKey = `${filter.name}id`;
		const filterQuery = filter.queryParams[filterKey];
		if (!filterQuery) {
			this.boxes = {};
			return;
		}
		let ids = [];
		if (typeof filterQuery === "string") {
			ids = filterQuery.split(",");
		} else {
			ids = [filterQuery];
		}
		this.boxes = ids.reduce((acc: any, id: string) => {
			acc[id] = true;
			return acc;
		}, {});
	}

	doChange(filter: any, checked: boolean) {
		if (checked) {
			this.boxes[filter.id] = true;
		} else {
			delete this.boxes[filter.id];
		}

		this.emitChange();
	}

	emitChange() {
		// Emits ids in array
		this.select.emit(Object.keys(this.boxes));
	}
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ISearchFilterOption } from "@model/search-filter-option";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
	selector: "cm-range-input-selector",
	templateUrl: "./range-input-selector.component.html",
	styleUrls: ["./range-input-selector.component.scss"],
	host: {
		"[class.filter-row]": "true",
	},
})
export class RangeInputSelectorComponent implements OnChanges, OnDestroy {
	// @ts-ignore
	@Input() range: ISearchFilterOption;
	@Output() select = new EventEmitter();
	min = new FormControl();
	max = new FormControl();

	subs: Subscription[] = [];

	constructor() {
		this.subs.push(
			this.min.valueChanges.pipe(debounceTime(600)).subscribe(() => {
				this.sendChange();
			}),
		);
		this.subs.push(
			this.max.valueChanges.pipe(debounceTime(600)).subscribe(() => {
				this.sendChange();
			}),
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.range && changes.range.currentValue) {
			this.setValues();
		}
	}

	setValues() {
		if (
			this.range.queryParams[`min_${this.range.name}`] &&
			this.range.queryParams[`min_${this.range.name}`] !== this.min.value
		) {
			this.min.setValue(this.range.queryParams[`min_${this.range.name}`]);
		}
		if (
			this.range.queryParams[`max_${this.range.name}`] &&
			this.range.queryParams[`max_${this.range.name}`] !== this.max.value
		) {
			this.max.setValue(this.range.queryParams[`max_${this.range.name}`]);
		}
	}

	sendChange() {
		this.select.emit({
			type: this.range.type,
			name: this.range.name,
			min: this.min.value,
			max: this.max.value,
		});
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s.unsubscribe());
	}
}

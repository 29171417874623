<div class="filter-column">
	<div class="d-flex align-items-center">
		<select class="form-control" [ngModel]="min" (ngModelChange)="setMin($event)">
			<option value="">Min</option>
			<option *ngFor="let result of range.results; trackBy: tracker" [value]="result.value">
				{{ result.label || result.value }}
			</option>
		</select>
		<div class="px-2">to</div>
		<select class="form-control" [ngModel]="max" (ngModelChange)="setMax($event)">
			<option value="">Max</option>
			<option *ngFor="let result of range.results; trackBy: tracker" [value]="result.value">
				{{ result.label || result.value }}
			</option>
		</select>
	</div>
</div>

import { Injectable } from "@angular/core";

const observers: any = {};
const observed: any = {};

@Injectable({
	providedIn: "root",
})
export class AppService {
	addLoader(key: any, func: any) {
		this.register(key, func());
	}

	observe(key: any, callback: any) {
		if (observers.hasOwnProperty(key)) {
			observers[key].push(callback);
		} else {
			observers[key] = [callback];
		}

		if (observed.hasOwnProperty(key)) {
			if (observed[key].hasOwnProperty("then") || observed[key].hasOwnProperty("$$state")) {
				observed[key].then(callback);
			} else {
				observed[key](callback());
			}
		}
	}

	register(key: any, promise: any) {
		observed[key] = promise;

		if (observers.hasOwnProperty(key)) {
			if (promise.hasOwnProperty("then") || promise.hasOwnProperty("$$state")) {
				for (const o of observers[key]) {
					promise.then(o);
				}
			} else {
				for (const o of observers[key]) {
					promise(o());
				}
			}
		}
	}

	trigger(key: any, wakeFn: any) {
		if (observed.hasOwnProperty(key)) {
			observed[key](wakeFn());
		} else if (observers.hasOwnProperty(key)) {
			observers[key].push(wakeFn);
		} else {
			observers[key] = [wakeFn];
		}
	}
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ISearchFilterOption } from "@model/search-filter-option";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
	selector: "cm-keyword-selector",
	templateUrl: "./keyword-selector.component.html",
	styleUrls: ["./keyword-selector.component.scss"],
})
export class KeywordSelectorComponent implements OnChanges {
	// @ts-ignore
	@Input() filter: ISearchFilterOption;
	@Output() select = new EventEmitter();

	// @ts-ignore
	@ViewChild("search", { static: false }) searchField: ElementRef;

	keyword = "";

	private doChangeS: Subject<string> = new Subject();

	constructor() {
		this.doChangeS.pipe(debounceTime(300)).subscribe((val) => this.select.emit(val));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filter && changes.filter.currentValue) {
			this.setupKeyword();
		}
	}

	setupKeyword() {
		const filterKey = this.filter.name;
		const filterQuery = this.filter.queryParams[filterKey];
		if (filterQuery) {
			this.keyword = filterQuery;
		}
	}

	doChange(value: string) {
		this.doChangeS.next(value);
	}
}

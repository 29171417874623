import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ImagePipe } from "@common/pipes/image";
import { Window2Service } from "@core/app/common/services/window2.service";
import { MenuHelperService } from "@core/app/menu-helper.service";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { UserService } from "@core/app/user.service";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { concat, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [ImagePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	@Input() template: string = "default";

	small$: Observable<boolean>;
	mobile$: Observable<boolean>;
	fixed$: Observable<boolean>;
	searchValues$: Observable<any>;
	searchOptions: any;
	socialData!: any;
	menus: any;
	logo: any;
	phoneNo: string = "";
	dealer: any;
	address: any;
	siteUrl: any;
	loadS: Subject<void> = new Subject();

	constructor(
		menuHelperService: MenuHelperService,
		router: Router,
		http: HttpClient,
		user: UserService,
		viewport: ViewportService,
		@Inject("PAGE_DATA") pageData: IPageData,
		window2: Window2Service,
		imagePipe: ImagePipe,
	) {
		this.siteUrl = "https://" + pageData.host + "/";
		this.mobile$ = viewport.bp.down.sm$;
		this.small$ = window2.scrollY$.pipe(
			map((scrollY) => scrollY > 85),
			distinctUntilChanged(),
		);
		this.fixed$ = window2.scrollY$.pipe(
			map((scrollY) => scrollY > 46),
			distinctUntilChanged(),
		);

		this.socialData = pageData.headerData.queries.GetDealerSocialAffiliateLinks.map((row) => ({
			id: row.img,
			src: row.img_dir + row.img_file,
			desc: row.affiliate_name,
			link: row.site_affiliate_url,
		}));

		this.address = pageData.headerData.queries.GetDealerInfo[0];
		this.phoneNo = pageData.headerData.queries.GetDealerInfo[0].phone_number;
		this.dealer = pageData.appInfo.data.siteName;

		this.logo = {
			src: imagePipe.transform(
				pageData.headerData.queries.GetDealerInfo[0].img_dir +
					pageData.headerData.queries.GetDealerInfo[0].img_file,
				"s",
			),
			alt: pageData.appInfo.data.siteName,
			disableLazyLoad: true,
		};

		this.searchValues$ = combineLatest(user.loggedIn$, user.isCustomer$, this.loadS).pipe(
			map(([loggedIn, isCustomer]) =>
				loggedIn && !isCustomer ? pageData.searchUrls.salesSearchResults : pageData.searchUrls.searchResults,
			),
			switchMap((url) => of(null).pipe(concat(http.get(url)))),
			shareReplay(1),
		);

		this.searchOptions = {
			disabled: false,
			focusFirst: false,
			focus: () => this.loadS.next(),
			changed: (model: any, event: any) => {
				if (event && event.keyCode === 13) {
					if (model && model.hasOwnProperty("link")) {
						router.navigateByUrl(model.link);
					} else if (model && model.hasOwnProperty("item")) {
						router.navigateByUrl(model.item.link);
					} else if (model.length > 0) {
						router.navigateByUrl("/search?s=" + model);
					}
				} else if (model && (model.hasOwnProperty("link") || model.hasOwnProperty("item"))) {
					if (model.link) {
						router.navigateByUrl(model.link);
					} else if (model.item && model.item.link) {
						router.navigateByUrl(model.item.link);
					}
				}
			},
			enterKeySubmit: true,
			placeholder: pageData.appInfo.data.siteConfigId === 11 ? "Search" : "What are you looking for?",
			icon: faSearch,
			buttons: [
				{
					text: "search",
					clicked: (item: any) => {
						router.navigate(["/"], { queryParams: { s: item.link } });
					},
				},
			],
		};
		this.menus = menuHelperService.getMenu();
	}
}

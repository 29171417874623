import { Component, Input, OnInit, OnChanges, SimpleChange, ViewEncapsulation } from "@angular/core";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-card-grid",
	templateUrl: "./card-grid.component.html",
	styleUrls: ["./card-grid.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CardGridComponent implements OnInit, OnChanges {
	grid: any = {};
	faSpinner = faSpinner;
	@Input() obj: any = null;

	setupGrid(): void {
		this.grid = Object.assign(
			{
				type: "default",
				cardGridClasses: "col-12 col-sm-6 col-md-4 col-lg-4",
				topHeading: null,
				itemType: null,
				attrs: {},
				columns: null,
				items: [],
			},
			this.obj,
		);

		if (this.grid.columns === "*") {
			this.grid.cardGridClasses = "fill-width";
		}

		if (this.grid.itemType !== null) {
			this.grid.attrs = {
				itemType: this.grid.itemType,
				itemscope: "",
			};
		}
	}

	ngOnInit() {
		this.setupGrid();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.obj) {
			this.obj = changes.obj.currentValue;
			this.setupGrid();
		}
	}
}

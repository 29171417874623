import { HttpClient } from "@angular/common/http";
import {
	Component,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChange,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Window2Service } from "@common/services/window2.service";
import {
	faBars,
	faChevronDown,
	faChevronUp,
	faSearch,
	faSignIn,
	faSignOut,
	faTimes,
	faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { from, Observable, of, Subject, Subscription } from "rxjs";
import { concat, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { iterObj } from "../common/iter";
import { ImageService } from "../image.service";
import { RestService, Service } from "../rest.service";
import { UserService } from "../user.service";

@Component({
	selector: "cm-gen-menu",
	templateUrl: "./gen-menu.component.html",
	styleUrls: ["./gen-menu.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class GenMenuComponent implements OnDestroy, OnInit, OnChanges {
	@Input() obj: any;
	loadS: Subject<void> = new Subject();
	fixed$ = this.window2Service.scrollY$.pipe(
		map((scrollY) => scrollY > 46),
		distinctUntilChanged(),
	);
	searchValues$ = this.loadS.pipe(
		map(() => this.pageData.searchUrls.searchResults),
		switchMap((url) => of(null).pipe(concat(this.http.get(url)))),
		shareReplay(1),
	);

	searchOptions = {
		disabled: false,
		focusFirst: false,
		focus: () => this.loadS.next(),
		changed: (model: any, event: any) => {
			if (event && event.keyCode === 13) {
				if (model && model.hasOwnProperty("link")) {
					this.router.navigateByUrl(model.link);
				} else if (model && model.hasOwnProperty("item")) {
					this.router.navigateByUrl(model.item.link);
				}
			} else if (model && (model.hasOwnProperty("link") || model.hasOwnProperty("item"))) {
				if (model.link) {
					this.router.navigateByUrl(model.link);
				} else if (model.item && model.item.link) {
					this.router.navigateByUrl(model.item.link);
				}
			}
		},
		enterKeySubmit: true,
		placeholder: "Search...",
		icon: faSearch,
		buttons: [],
	};

	searchValue: string = "";
	onLoggedIn: Subscription;

	hidden: boolean = true;
	service: Service;
	modalInstance: NgbModalRef | null = null;
	menu: any = {};
	searching: boolean = false;

	imageObj$ = this.userService.img$.pipe(
		map((img) => (img ? this.imageService.make(img, { size: "t", bg: true, link: "/my-account" }) : null)),
	);

	faSignIn = faSignIn;
	faSignOut = faSignOut;
	faBars = faBars;
	faUser = faUser;
	faTimes = faTimes;
	faChevronUp = faChevronUp;
	faChevronDown = faChevronDown;

	@ViewChild("loginModal", { static: true }) loginModal!: TemplateRef<any>;

	constructor(
		private imageService: ImageService,
		private modalService: NgbModal,
		public userService: UserService,
		restService: RestService,
		private router: Router,
		private window2Service: Window2Service,
		@Inject("PAGE_DATA") private pageData: IPageData,
		private http: HttpClient,
	) {
		this.service = restService.init("statement");
		this.onLoggedIn = userService.loggedIn$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => {
			if (this.modalInstance) {
				this.modalInstance.close();
			}
		});

		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (!this.hidden) {
					this.toggleMenu();
				}
			}
		});
	}

	ngOnInit() {
		this.menu = {
			type: "",
			columns: [],
			user: { first_name: "GUEST" },
			itemClick: () => this.toggleMenu(),
		};

		if (this.isObject(this.obj)) {
			this.updateMenu(this.obj);
		}
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.obj && !changes.obj.isFirstChange()) {
			if (this.isObject(changes.obj.currentValue)) {
				this.updateMenu(changes.obj.currentValue);
			}
		}
	}

	ngOnDestroy() {
		this.onLoggedIn.unsubscribe();
	}

	loginClick() {
		if (this.userService.loggedIn()) {
			this.userService.logOut();
		} else {
			this.showLogin();
		}
	}

	// TODO: don't think this is used anymore
	search = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			switchMap((term) =>
				from(
					this.service.post("GetSearchAuto", { vars: { search: term } }).then((response: any) => {
						const results: any[] = [];
						for (const item of response.results) {
							results.push({
								link: item.content_page_url,
								href: item.content_page_url,
								label: item.title,
							});
						}

						return results;
					}),
				),
			),
			tap(() => (this.searching = false)),
		);

	showLogin() {
		this.modalInstance = this.modalService.open(this.loginModal, { ariaLabelledBy: "loginModal", size: "lg" });
	}

	toggleMenu() {
		this.hidden = !this.hidden;
	}

	updateMenu(obj: any) {
		for (const [k, v] of iterObj(obj)) {
			this.menu[k] = v;
		}
	}

	isObject(x: any): x is object {
		return x != null && typeof x === "object";
	}
}

// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@core/app/shared/shared.module";
import { SideWidgetModule } from "@core/app/side-widget/side-widget.module";
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularDraggableModule } from "angular2-draggable";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { AccountModule } from "./account/account.module";
import { Banner2Component } from "./banner2/banner2.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { EqualValidatorDirective } from "./equal-validator.directive";
import { FacebookModule } from "./facebook/facebook.module";
import { FooterModule } from "./footer/footer.module";
// our code
import { AscHeaderComponent } from "./header-meta/asc-header/asc-header.component";
import { HeaderAnthonyComponent } from "./header-meta/header-anthony/header-anthony.component";
import { HeaderAtHomeComponent } from "./header-meta/header-athome/header-athome.component";
import { HeaderDesignForceComponent } from "./header-meta/header-design-force/header-design-force.component";
import { HeaderMetaComponent } from "./header-meta/header-meta.component";
import { HeaderComponent } from "./header-meta/header/header.component";
import { ImageModule } from "./image/image.module";
import { InitDirective } from "./init.directive";
import { InstagramModule } from "./instagram/instagram.module";
import { InventoryModule } from "./inventory/inventory.module";
import { LiveTrackerComponent } from "./live-tracker/live-tracker.component";
import { Menu2Component } from "./menu2/menu2.component";
import { Page404Component } from "./pages/page-404/page-404.component";
import { PromotionPopupComponent } from "./promotion/components/promotion-popup/promotion-popup.component";
import { QuickBarComponent } from "./quick-bar/quick-bar.component";
import { RestService } from "./rest.service";
import { ReviewsComponent } from "./reviews/reviews.component";
import { RouteResolver } from "./route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { StoreMapComponent } from "./store-map/store-map.component";
import { UserWidgetComponent } from "./user-widget/user-widget.component";
import { config } from "@fortawesome/fontawesome-svg-core";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};
if (typeof window === "undefined") {
	auth.storageType = StorageType.MEMORY;
}

@NgModule({
	declarations: [
		AscHeaderComponent,
		CalculatorComponent,
		EqualValidatorDirective,
		InitDirective,
		HeaderComponent,
		LiveTrackerComponent,
		PromotionPopupComponent,
		QuickBarComponent,
		ReviewsComponent,
		StoreMapComponent,
		UserWidgetComponent,
		Page404Component,
		HeaderMetaComponent,
		Menu2Component,
		HeaderAtHomeComponent,
		Banner2Component,
		HeaderDesignForceComponent,
		HeaderAnthonyComponent,
	],
	entryComponents: [LiveTrackerComponent, ReviewsComponent, StoreMapComponent],
	imports: [
		AngularDraggableModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		NgbModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SideWidgetModule,
		SliderModule,
		FooterModule,
		InstagramModule,
		FacebookModule,
		InventoryModule,
		ImageModule,
		AccountModule,
	],
	exports: [
		AscHeaderComponent,
		EqualValidatorDirective,
		InitDirective,
		HeaderComponent,
		NgbModule,
		PromotionPopupComponent,
		QuickBarComponent,
		SharedModule,
		UserWidgetComponent,
		Page404Component,
		SideWidgetModule,
		HeaderMetaComponent,
		Banner2Component,
		SliderModule,
		FooterModule,
		InstagramModule,
		FacebookModule,
		InventoryModule,
		ImageModule,
		AccountModule,
	],
	providers: [RestService, NgbModal],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}

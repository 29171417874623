<div class="cm-brands" *ngFor="let manufacturer of manufacturers">
	<h2 class="image-set-heading">
		<a href="{{ manufacturer.heading.link }}">{{ manufacturer.heading.name }} Brands</a>
	</h2>
	<cm-image-grid
		[gridXs]="12"
		[gridSm]="12"
		[gridMd]="6"
		[gridData]="manufacturer.data"
		image-size="t"
	></cm-image-grid>
</div>

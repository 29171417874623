import { Directive, ElementRef, OnInit, OnChanges, Inject, Input, SimpleChange } from "@angular/core";
import { iterObj } from "./common/iter";

@Directive({
	selector: "[cmAttr]",
})
export class AttrDirective implements OnInit, OnChanges {
	@Input("cmAttr") cmAttr: any;

	constructor(@Inject(ElementRef) private el: ElementRef) {}

	setup(): void {
		for (const [k, v] of iterObj(this.cmAttr)) {
			this.el.nativeElement.setAttribute(k, v);
		}
	}

	ngOnInit() {
		this.setup();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.cmAttr && !changes.cmAttr.isFirstChange()) {
			this.setup();
		}
	}
}

<div class="container-fluid not-found-page">
	<div class="not-found-container">
		<div class="row not-found-header">
			<div class="col-12">
				<header class="page-header">
					<h1 class="page-title" itemprop="headline">Oops! That page can't be found.</h1>
				</header>
			</div>
		</div>
		<div class="row not-found-main">
			<div class="col-12">
				<cm-translator *ngIf="pageContent" [inputText]="pageContent" itemprop="text"></cm-translator>
			</div>
		</div>
	</div>
</div>

<div class="cm-menu d-print-none" itemscope itemtype="http://schema.org/SiteNavigationElement">
	<!--Desktop-->
	<ul *ngIf="!(viewport.bp.down.sm$ | async)" class="cm-menu-ul d-none d-md-block">
		<li
			*ngFor="let item of imgMenuData"
			(mouseover)="showDropdown(item, true)"
			(mouseleave)="showDropdown(item, false)"
		>
			<ng-container *ngTemplateOutlet="items; context: { item: item }"></ng-container>
		</li>
	</ul>
	<!--Mobile-->
	<div
		*ngIf="(menuShow$ | async) && (viewport.bp.down.sm$ | async)"
		class="cm-menu-mobile-overlay d-md-none"
		(click)="toggleMenuS.next(false)"
	></div>
	<div class="hamburger-container d-md-none" (click)="toggleMenuS.next(true)">
		<span *ngIf="imgMenuButtonLabel; else icon">{{ imgMenuButtonLabel }}</span>
		<ng-template #icon>
			<fa-icon [icon]="faBars"></fa-icon>
		</ng-template>
	</div>
	<div
		*ngIf="viewport.bp.down.sm$ | async"
		class="cm-menu-mobile d-md-none"
		[ngClass]="{ visible: menuShow$ | async }"
	>
		<div class="cm-menu-mobile-header theme-bg text-light">
			<span class="user">Hello, {{ userService.name$ | async }}</span>
			<fa-icon [icon]="faTimes" (click)="toggleMenuS.next(false)"></fa-icon>
			<div class="user-buttons" *ngIf="userService.loggedIn$ | async">
				<hr />
				<a
					*ngIf="userService.isCustomer$ | async"
					routerLink="/your-favorite-rvs"
					itemprop="url"
					class="btn btn-special"
					(click)="toggleMenuS.next(false)"
				>
					<fa-icon [icon]="faHeart"></fa-icon>
					<span class="ml-1" itemprop="name">Favs</span>
				</a>
				<button class="btn btn-special" (click)="logout()">
					<fa-icon [icon]="faSignOut"></fa-icon><span class="ml-1" itemprop="name">Logout</span>
				</button>
			</div>
			<hr />
			<div class="search" *ngIf="imgMenuAutocompleteOptions">
				<cm-autocomplete
					[searchItems]="imgMenuAutocompleteData"
					[options]="imgMenuAutocompleteOptions"
				></cm-autocomplete>
			</div>
		</div>
		<ul class="cm-menu-mobile-ul">
			<li>
				<a [cmHref]="imgMenuHomeLink" (click)="toggleMenuS.next(false)" itemprop="url">
					<span itemprop="name">{{ imgMenuHomeLinkText }}</span>
				</a>
			</li>
			<li *ngFor="let item of imgMenuData">
				<ng-container *ngTemplateOutlet="items; context: { item: item }"></ng-container>
			</li>
		</ul>
	</div>
</div>

<ng-template #items let-item="item">
	<a
		*ngIf="item.link; else noLink"
		class="dropdown-toggle"
		routerLink="{{ item.link }}"
		itemprop="url"
		(click)="itemClick($event, item)"
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</a>
	<ng-template #noLink>
		<span class="dropdown-toggle" (click)="itemClick($event, item)">
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</span>
	</ng-template>

	<ng-template #itemContent>
		<fa-icon *ngIf="item.icon" class="menu-icon" [icon]="item.icon"></fa-icon>
		<cm-image *ngIf="item.image" [obj]="item.image"></cm-image>
		<span itemprop="name" [innerHTML]="item.text"></span>
		<fa-icon
			*ngIf="(item.items && item.items.length > 0) || (item.megaMenu && !(viewport.bp.down.sm$ | async))"
			[icon]="caret ? faCaretDown : faChevronDown"
		></fa-icon>
	</ng-template>

	<ul
		*ngIf="!item.megaMenu && item.items && item.items.length > 0"
		class="dropdown-menu regular {{ item.cssClasses || '' }}"
		[ngClass]="{ visible: item.visible, stmtmenu: item.stmtid }"
	>
		<li
			*ngFor="let subitem of item.items"
			(mouseover)="showDropdown(subitem, true)"
			(mouseleave)="showDropdown(subitem, false)"
		>
			<ng-container *ngTemplateOutlet="items; context: { item: subitem }"></ng-container>
		</li>
	</ul>
	<ul
		*ngIf="!(viewport.bp.down.sm$ | async) && item.megaMenu && item.showDropdown"
		class="dropdown-menu regular filter {{ item.cssClasses || '' }}"
	>
		<li class="p-2">
			<cm-search-filter
				[filters]="state.filters$ | async"
				[searchUrl]="item.link"
				[filterSettings]="filterSettings"
			></cm-search-filter>
		</li>
	</ul>
</ng-template>

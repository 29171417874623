import { Component, OnInit, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-store-map",
	templateUrl: "./store-map.component.html",
})
export class StoreMapComponent implements OnInit {
	mapCenter: any = {};
	mapData: any = [];
	markersArray: any = [];

	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}

	async ngOnInit() {
		const data = this.pageData.locations[0];

		this.mapData[0] = {};
		this.mapCenter.lat = Number(data.latitude);
		this.mapData[0].latLng = {};
		this.mapData[0].latLng.lat = Number(data.latitude);
		this.mapCenter.lng = Number(data.longitude);
		this.mapData[0].latLng.lng = Number(data.longitude);
	}
}

import { Injectable, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";

@Injectable({
	providedIn: "root",
})
export class DealerService {
	dealerInfo: any = {};

	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {
		const headerData = this.pageData.headerData;
		this.dealerInfo = headerData.queries.GetDealerInfo[0];
	}

	getAddress() {
		return {
			address1: this.dealerInfo.address1,
			address2: this.dealerInfo.address2,
			city: this.dealerInfo.city,
			state: this.dealerInfo.stateprov,
			zip: this.dealerInfo.postal_code,
			country: this.dealerInfo.country,
			/**
			 * Formats the Address returned
			 */
			format: function() {
				return [this.address1, this.address2, this.city, this.state + " " + this.zip]
					.filter((x: any) => x !== "")
					.join(", ");
			},
		};
	}

	getName() {
		return this.dealerInfo.dealer;
	}

	getPhone() {
		return this.dealerInfo.phone_number;
	}

	getTagline() {
		return this.dealerInfo.dealer_tagline;
	}
}

<div class="staff-row">
	<div
		class="single-staff"
		*ngFor="let staff of salesStaff; let index = index"
		itemscope
		itemtype="http://schema.org/Person"
	>
		<div class="wrap row">
			<div class="col-4 staff-photo">
				<cm-image
					*ngIf="staff.image"
					[obj]="staff.image"
					itemscope
					itemtype="http://schema.org/ImageObject"
				></cm-image>
				<fa-icon *ngIf="!staff.image" class="avatar" [icon]="faUserCircle"></fa-icon>
			</div>
			<div class="col-8 staff-info">
				<meta itemprop="email" content="{{ staff.email }}" />
				<meta itemprop="telephone" content="{{ staff.phoneNumber }}" />
				<strong itemprop="name">{{ staff.name }}</strong>
				<br />
				<a href="mailto:{{ staff.email }}" class="theme" rel="nofollow">{{ staff.email }}</a>
				<br />
				<a
					href="tel:{{ staff.phoneNumber }}"
					itemprop="url"
					class="theme"
					(click)="app.placeCall()"
					rel="nofollow"
					>{{ staff.phoneNumber | tel }}</a
				>
			</div>
		</div>
	</div>
</div>

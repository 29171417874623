import { Component, OnInit } from "@angular/core";
import { ImagePipe } from "@common/pipes/image";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { RestService, Service } from "../rest.service";

@Component({
	selector: "cm-brands",
	templateUrl: "./brands.component.html",
	styleUrls: ["./brands.component.scss"],
	providers: [ImagePipe],
})
export class BrandsComponent implements OnInit {
	manufacturers: any;
	service: Service;

	constructor(private imagePipe: ImagePipe, private restService: RestService, private transfer: TransferRxService) {
		this.service = this.restService.init("statement");
	}

	ngOnInit() {
		this.transfer
			.transfer$("GetAllBrandLogosByMfr", () => this.service.post$("GetAllBrandLogosByMfr"))
			.subscribe((response: any) => {
				if (response.success) {
					this.manufacturers = [];
					let current: any = {};
					for (const row of response.results) {
						if (!current.hasOwnProperty("heading") || row.mfr !== current.heading.name) {
							if (current.hasOwnProperty("heading")) {
								this.manufacturers.push(current);
							}
							current = {
								heading: {
									name: row.mfr,
									link: row.mfr_link,
									class: "text-center",
								},
								data: [],
							};
						}

						current.data.push({
							image: { src: this.imagePipe.transform(row.img_dir + row.img_file, "m"), alt: row.img_alt },
							link: row.brand_link,
							show: true,
							class: "text-center",
						});
					}

					if (current.hasOwnProperty("heading")) {
						this.manufacturers.push(current);
					}
				}
			});
	}
}

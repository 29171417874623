import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSegment, UrlMatchResult, UrlMatcher } from '@angular/router';
import { CoreModule } from '../core.module';

import { RouteResolver } from "@core/app/route-resolver.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@core/environments/environment';
import { RootComponent } from "@core/app/pages/root/root.component";
import { PAGE_DATA } from "./page-data";
import { ToastNoAnimationModule } from 'ngx-toastr';
    import { PrerenderModule } from "shared";

export const routes: Routes = [
    {
        matcher: routeMatcher0 as UrlMatcher,
        loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Admin",
            pageTemplateId: 88,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/new-admin).*$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher1 as UrlMatcher,
        loadChildren: () => import("./pages/page-blog/page-blog.module").then((m) => m.PageBlogModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Blog",
            pageTemplateId: 37,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/(blog))(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&blog=2&page=3",
            stmtid: 93,
        }
    },
    {
        matcher: routeMatcher2 as UrlMatcher,
        loadChildren: () => import("./pages/page-blog-post/page-blog-post.module").then((m) => m.PageBlogPostModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "BlogPost",
            pageTemplateId: 14,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^\\/blog\\/([^\\/]+)(?:\\/([0-9]+))?(?:\\?.*)?$",
            rewriteTo: "blog_post=1&page=2",
            stmtid: 93,
        }
    },
    {
        matcher: routeMatcher3 as UrlMatcher,
        loadChildren: () => import("./pages/page-car-listing/page-car-listing.module").then((m) => m.PageCarListingModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "CarListing",
            pageTemplateId: 56,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^\\/builds\\/(?:[^\\/]+)-([0-9]+)(?:\\?.*)?$",
            rewriteTo: "listingid=1",
            stmtid: 107,
        }
    },
    {
        matcher: routeMatcher4 as UrlMatcher,
        loadChildren: () => import("./pages/page-inventory/page-inventory.module").then((m) => m.PageInventoryModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Inventory",
            pageTemplateId: 57,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/builds)(?:\\/([^\\/]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&project_cat=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher5 as UrlMatcher,
        loadChildren: () => import("./pages/page-job-posting/page-job-posting.module").then((m) => m.PageJobPostingModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "JobPosting",
            pageTemplateId: 87,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/jobs\\/application)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher6 as UrlMatcher,
        loadChildren: () => import("./pages/page-account/page-account.module").then((m) => m.PageAccountModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Account",
            pageTemplateId: 60,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/my-account)(?:\\?.*)?$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher7 as UrlMatcher,
        loadChildren: () => import("./pages/page-about/page-about.module").then((m) => m.PageAboutModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "About",
            pageTemplateId: 61,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/about-us)$",
            rewriteTo: "content_page_url=1",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher8 as UrlMatcher,
        loadChildren: () => import("./pages/page-home-asc/page-home-asc.module").then((m) => m.PageHomeAscModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Home",
            pageTemplateId: 39,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/)([0-9]+)?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
    {
        matcher: routeMatcher9 as UrlMatcher,
        loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
        resolve: { routeData: RouteResolver },
        data: {
            bodyClass: "Page",
            pageTemplateId: 13,
            // Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
            // to regex where it's used
            regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
            rewriteTo: "content_page_url=1&page=2",
            stmtid: 85,
        }
    },
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/new-admin).*$/, segments, true); }
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/(blog))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^\/blog\/([^\/]+)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^\/builds\/(?:[^\/]+)-([0-9]+)(?:\?.*)?$/, segments, false); }
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/builds)(?:\/([^\/]+))?(?:\?.*)?$/, segments, false); }
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/jobs\/application)(?:\?.*)?$/, segments, false); }
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false); }
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/about-us)$/, segments, false); }
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/)([0-9]+)?(?:\?.*)?$/, segments, false); }
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null { return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false); }

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
    if (regex.test("/" + segments.map(x => x.path).join("/"))) {
        if (hasChildRouter) {
            return { consumed: [segments[0]] };
        } else {
            return { consumed: segments };
        }
    } else {
        return null;
    }
}

@NgModule({
    declarations: [RootComponent],
    imports: [
        CommonModule,
        CoreModule.forRoot(),
        ToastNoAnimationModule.forRoot({ positionClass: 'toast-top-center' }),
        PrerenderModule,
        RouterModule.forRoot(routes, {
            initialNavigation: "enabledBlocking",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload",
        }),
        ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
        
    ],
    providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }, ],
    entryComponents: [RootComponent],
    bootstrap: [RootComponent],
})
export class AppModule { }

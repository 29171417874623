import { Injectable } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { RestService } from "@core/app/rest.service";
import { IStmtResults } from "@model/stmt-results";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PromotionsService {
	promotions$: Observable<IStmtResults<any>>;

	constructor(restService: RestService, transfer: TransferRxService) {
		this.promotions$ = transfer.transfer$("GetPopupPromotions", () =>
			restService
				.init("statement")
				.post$("GetPopupPromotions", {}, "PopupPromotions")
				.pipe(shareReplay(1)),
		);
	}
}

import {
	Component,
	HostBinding,
	Input,
	ElementRef,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
	OnInit,
} from "@angular/core";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";

let nextIdNum = 0;

@Component({
	selector: "cm-field-text-multiple",
	templateUrl: "./field-text-multiple.component.html",
	styleUrls: ["./field-text-multiple.component.scss"],
})
export class FieldTextMultipleComponent implements OnInit {
	@Input() type!: string;
	@Input() name!: string;
	@Input() placeholder: string = "";
	@Input() autocomplete?: string;
	@Input() disabled: boolean = false;
	@Input() hideCount: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() maxLen?: number;
	@Input() minLen?: number;
	@Input() required: boolean = false;
	@Input() pattern?: string | null;
	@Input() value?: string;

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChildren("inputs") inputs!: QueryList<ElementRef<HTMLInputElement>>;

	faPlus = faPlus;
	faTimes = faTimes;

	idNum: number = 0;
	error: string | null = null;
	focused: boolean = false;
	validated: boolean = false;
	valueSplit: { val: string }[] = [{ val: "" }];

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-text-multiple-${this.idNum}`;
	}

	get inputStyle(): { [key: string]: string } | null {
		return this.maxLen ? { "max-width": `${this.maxLen * 1.1}ch` } : null;
	}

	ngOnInit() {
		if (this.value) {
			this.valueSplit = this.value.split(",").map((x) => ({ val: x }));
		}
	}

	onAddClick() {
		this.valueSplit.push({ val: "" });
		this.onModelChange();
	}

	onDeleteClick(index: number) {
		this.valueSplit.splice(index, 1);
		this.onModelChange();
	}

	onModelChange() {
		this.valueChange.emit(this.valueSplit.map((x) => x.val).join(","));
	}

	validate() {
		for (const el of this.inputs.toArray()) {
			if (!el.nativeElement.checkValidity()) {
				this.error = el.nativeElement.validationMessage;
			} else {
				this.error = null;
			}
		}

		this.validated = true;
	}
}

import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "allspeedcustoms",
		homeBreadcrumb: "ASC",
		themeData: {
			dealerAccent: null,
		},
	},
};

import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DmsCommonModule } from "@common/common.module";
import { VideoComponent } from "@common/components/video/video.component";
import { AddressComponent } from "@core/app/address/address.component";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { ArrowDivComponent } from "@core/app/arrow-div/arrow-div.component";
import { AttrDirective } from "@core/app/attr.directive";
import { BlogMenuComponent } from "@core/app/blog-menu/blog-menu.component";
import { BootstrapModule } from "@core/app/bootstrap/bootstrap.module";
import { ButtonGridComponent } from "@core/app/button-grid/button-grid.component";
import { ButtonModule } from "@core/app/button/button.module";
import { CardGridComponent } from "@core/app/card-grid/card-grid.component";
import { CardComponent } from "@core/app/card/card.component";
import { ClickCopyDirective } from "@core/app/click-copy.directive";
import { ContactInfoComponent } from "@core/app/contact-info/contact-info.component";
import { CrumbsComponent } from "@core/app/crumbs/crumbs.component";
import { CustomerReviewsComponent } from "@core/app/customer/components/customer-reviews/customer-reviews.component";
import { FacebookCommentComponent } from "@core/app/facebook-comment/facebook-comment.component";
import { FaqModule } from "@core/app/faq/faq.module";
import { FeatureListComponent } from "@core/app/feature-list/feature-list.component";
import { FieldsetComponent } from "@core/app/fieldset/fieldset.component";
import { FormButtonComponent } from "@core/app/form-button/form-button.component";
import { FormPopUpComponent } from "@core/app/form-popup/form-popup.component";
import { FormTransparentComponent } from "@core/app/form-transparent/form-transparent.component";
import { GenMenuComponent } from "@core/app/gen-menu/gen-menu.component";
import { GroupByPipe } from "@core/app/group-by.pipe";
import { ImageGridComponent } from "@core/app/image-grid/image-grid.component";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { LazyModule } from "@core/app/lazy/lazy.module";
import { ListingGridComponent } from "@core/app/listing-grid/listing-grid.component";
import { ListingCard2Component } from "@core/app/listing/components/listing-card2/listing-card2.component";
import { ListingModule } from "@core/app/listing/listing.module";
import { MapComponent } from "@core/app/map/map.component";
import { Map2Module } from "@core/app/map2/map2.module";
import { MenuComponent } from "@core/app/menu/menu.component";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { PaymentCalculatorComponent } from "@core/app/payment-calculator/payment-calculator.component";
import { PhoneNumberComponent } from "@core/app/phone-number/phone-number.component";
import { PromotionBannerComponent } from "@core/app/promotion/components/promotion-banner/promotion-banner.component";
import { SearchModule } from "@core/app/search/search.module";
import { SalesStaffComponent } from "@core/app/shared/components/sales-staff/sales-staff.component";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { UserHeaderComponent } from "@core/app/shared/components/user-header/user-header.component";
import { UserImageComponent } from "@core/app/shared/components/user-image/user-image.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SlugifyPipe } from "@core/app/slugify.pipe";
import { SocialLinksComponent } from "@core/app/social-links/social-links.component";
import { StarListingComponent } from "@core/app/star-listing/star-listing.component";
import { TextListComponent } from "@core/app/text-list/text-list.component";
import { TimeClockComponent } from "@core/app/time-clock/time-clock.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ModalModule } from "shared";
import { AscContactUsComponent } from "../asc/components/home-page/contact-us/contact-us.component";
import { AutocompleteModule } from "../autocomplete/autocomplete.module";
import { BrandsComponent } from "../brands/brands.component";
import { CustomBuildComponent } from "../custom-build/custom-build.component";
import { DropzoneComponent } from "../dropzone/dropzone.component";
import { FormModule } from "../form/form.module";
import { HomePageInventoryComponent } from "../listing/components/home-page-inventory/home-page-inventory.component";
import { ListingHortizontalCardComponent } from "../listing/components/listing-hortizontal-card/listing-hortizontal-card.component";
import { PanotourComponent } from "../panotour/panotour.component";
import { ReviewCardComponent } from "../review-card/review-card.component";
import { ReviewRowComponent } from "../reviews/review-row/review-row.component";
import { TranslatorComponent } from "../translator/translator.component";
import { UtilModule } from "../util/util.module";

@NgModule({
	imports: [
		BootstrapModule,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		InfiniteScrollModule,
		NgbModule,
		FlatpickrModule.forRoot(),
		RouterModule,
		LazyModule,
		FaqModule,
		RouterModule,
		ListingModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		Pagination2Module,
		DmsCommonModule,
		ButtonModule,
		UtilModule,
		FormModule,
		ModalModule,
		AutocompleteModule,
	],
	declarations: [
		AttrDirective,
		AddressComponent,
		BlogMenuComponent,
		ButtonGridComponent,
		CardComponent,
		CardGridComponent,
		ClickCopyDirective,
		ContactInfoComponent,
		CrumbsComponent,
		FacebookCommentComponent,
		FeatureListComponent,
		FieldsetComponent,
		FormPopUpComponent,
		GenMenuComponent,
		GroupByPipe,
		ImageGridComponent,
		ListingGridComponent,
		MapComponent,
		MenuComponent,
		PaymentCalculatorComponent,
		PhoneNumberComponent,
		SlugifyPipe,
		SocialLinksComponent,
		StarListingComponent,
		TextListComponent,
		TimeClockComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		UserImageComponent,
		UserHeaderComponent,
		SocialLinks2Component,
		CustomerReviewsComponent,
		ListingCard2Component,
		CampaignPickerComponent,
		FormTransparentComponent,
		ArrowDivComponent,
		PromotionBannerComponent,
		SalesStaffComponent,
		FormButtonComponent,
		BrandsComponent,
		PanotourComponent,
		CustomBuildComponent,
		AscContactUsComponent,
		HomePageInventoryComponent,
		ReviewCardComponent,
		ReviewRowComponent,
		ListingHortizontalCardComponent,
	],
	entryComponents: [BrandsComponent, SalesStaffComponent],
	exports: [
		AttrDirective,
		AddressComponent,
		BlogMenuComponent,
		BootstrapModule,
		ButtonGridComponent,
		CardComponent,
		CardGridComponent,
		ClickCopyDirective,
		CommonModule,
		ContactInfoComponent,
		CrumbsComponent,
		FacebookCommentComponent,
		FeatureListComponent,
		FieldsetComponent,
		FontAwesomeModule,
		FormPopUpComponent,
		FormsModule,
		GenMenuComponent,
		GroupByPipe,
		ImageGridComponent,
		InfiniteScrollModule,
		ListingGridComponent,
		MapComponent,
		MenuComponent,
		NgbModule,
		PaymentCalculatorComponent,
		PhoneNumberComponent,
		RouterModule,
		SlugifyPipe,
		SocialLinksComponent,
		StarListingComponent,
		TextListComponent,
		TimeClockComponent,
		DropzoneComponent,
		TranslatorComponent,
		VideoComponent,
		FlatpickrModule,
		LazyModule,
		FaqModule,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ListingModule,
		ImageModule,
		UserHeaderComponent,
		SocialLinks2Component,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		CustomerReviewsComponent,
		LayoutModule,
		ListingCard2Component,
		CampaignPickerComponent,
		FormTransparentComponent,
		ArrowDivComponent,
		Pagination2Module,
		PromotionBannerComponent,
		SalesStaffComponent,
		FormButtonComponent,
		DmsCommonModule,
		ButtonModule,
		BrandsComponent,
		PanotourComponent,
		CustomBuildComponent,
		AscContactUsComponent,
		FormModule,
		HomePageInventoryComponent,
		ReviewCardComponent,
		ReviewRowComponent,
		ModalModule,
		AutocompleteModule,
	],
	providers: [Location],
})
export class SharedModule {}

import { Component, Input, OnInit, OnChanges, SimpleChange, Inject, ViewEncapsulation } from "@angular/core";
import { ConfigService } from "@core/app/config.service";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

interface ItemTypeAttrs {
	itemType: string;
	itemscope: string;
}

@Component({
	selector: "cm-image-grid",
	templateUrl: "./image-grid.component.html",
	styleUrls: ["./image-grid.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ImageGridComponent implements OnInit, OnChanges {
	attrs: ItemTypeAttrs;
	classes: any = [];
	classString: string = "";
	siteName: string = "";
	siteUrl: string = "";
	siteLogo: any = {};
	path: string = "";
	loaded: boolean = false;
	template: string = "";
	faSpinner = faSpinner;
	@Input() gridXl!: string | number;
	@Input() gridLg!: string | number;
	@Input() gridMd!: string | number;
	@Input() gridSm!: string | number;
	@Input() gridXs!: string | number;
	@Input() gridItemType!: string;
	@Input() gridData!: any;
	@Input() linkWrap!: string;
	@Input() firstOffset!: string;

	constructor(private config: ConfigService, @Inject("PAGE_DATA") pageData: any) {
		this.attrs = {
			itemType: "",
			itemscope: "",
		};
		this.siteUrl = "https://" + pageData.host + "/";
	}

	setupGrid(): void {
		const appToken = this.config.getAppToken();
		this.siteName = appToken.siteName;
		this.siteLogo = appToken.siteLogo;
		this.classes = [];
		this.loaded = true;

		if (this.gridXl === undefined) {
			this.gridXl = 4;
		}

		if (this.gridLg === undefined) {
			this.gridLg = 4;
		}

		if (this.gridMd === undefined) {
			this.gridMd = 6;
		}

		if (this.gridSm === undefined) {
			this.gridSm = 6;
		}

		if (this.gridXs === undefined) {
			this.gridXs = 6;
		}

		if (this.gridItemType !== undefined && this.gridItemType) {
			this.attrs = {
				itemType: this.gridItemType,
				itemscope: "",
			};
		}

		this.classes.push("col-" + this.gridXs);
		this.classes.push("col-sm-" + this.gridSm);
		this.classes.push("col-md-" + this.gridMd);
		this.classes.push("col-lg-" + this.gridLg);
		this.classes.push("col-xl-" + this.gridXl);
		this.classString = this.classes.join(" ");

		if (this.linkWrap) {
			this.template = "linkWrap";
		} else if (this.gridItemType) {
			this.template = "wrapItemType";
		} else {
			this.template = "wrapNoItemType";
		}
	}

	ngOnInit() {
		this.setupGrid();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.gridData && !changes.gridData.firstChange) {
			this.gridData = changes.gridData.currentValue;
			this.setupGrid();
		}
	}
}

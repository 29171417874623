<div class="component component-cm-footer {{ template || 'default' }}" itemscope itemtype="http://schema.org/WPFooter">
	<meta itemprop="copyrightYear" [content]="currentYear" />
	<meta itemprop="copyrightHolder" [content]="dealer" />
	<div class="container-fluid" *ngIf="!template || template === 'default'">
		<!--Footer Content-->
		<div class="row footer-content theme-base">
			<div class="container">
				<div class="row">
					<div class="company-info col-12 col-lg-5">
						<div class="copyright">
							<strong [innerHTML]="copyright"></strong>
							<strong>| <a routerLink="/privacy-policy">Privacy Policy</a></strong>
						</div>
						<cm-address [address]="address" class="cm-address"></cm-address>
						<div class="phone">Ph: {{ phoneNumber }}</div>
						<div
							ngbDropdown
							*ngIf="states.length > 0"
							placement="top-left"
							[autoClose]="true"
							class="states-dropdown"
						>
							<button class="btn btn-outline-secondary" id="stateDropdown" ngbDropdownToggle>
								State/Province Info
							</button>
							<div ngbDropdownMenu aria-labelledby="stateDropdown">
								<a
									class="dropdown-item"
									routerLink="{{ state.content_page_url }}"
									*ngFor="let state of states"
									ngbDropdownItem
									>{{ state.stateprov }}</a
								>
							</div>
						</div>
						<div class="row">
							<div
								*ngFor="let column of footerLinks; let index = index"
								class="d-print-none col-6 col-lg-6"
							>
								<ul class="footer-links">
									<li *ngFor="let link of column; let index = index">
										<a routerLink="{{ link.link }}" [attr.target]="link.target">{{ link.text }}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="divider d-print-none d-none d-lg-block col-lg-2">
						<div class="divider-line"></div>
					</div>
					<div class="company-hours col-12 col-lg-5">
						<div *ngFor="let hourType of hours" class="hours-wrapper">
							<p class="heading">
								<strong>{{ hourType[0] }} Hours</strong>
							</p>
							<div *ngFor="let hourLine of hourType[1]" class="row hour">
								<span class="col-6 day-of-week">{{ hourLine[1].join(", ") }}</span>
								<span class="col-6 day-hours">{{ hourLine[0] }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Tagline-->
		<div class="row footer-bar theme-bg theme-fg">
			<div class="col-12">
				<p>{{ tagLine }}</p>
			</div>
		</div>
	</div>
	<div class="container-fluid" *ngIf="template === 'address-only'">
		<!--Footer Content-->
		<div class="row footer-content theme-base">
			<div class="container">
				<div class="company-info col-12">
					<cm-menu *ngIf="menus && !mobile" [imgMenuData]="menus"></cm-menu>
					<cm-address [address]="address" class="cm-address"></cm-address>
					<div class="phone">
						Ph:
						<cm-phone-number *ngIf="phoneNumber" [phone]="phoneNumber"></cm-phone-number>
					</div>
					<div class="social d-print-none">
						<cm-social-links [socialLinks]="socialData" class="cm-social-links"></cm-social-links>
					</div>
				</div>
			</div>
		</div>
		<!--Tagline-->
		<div class="row footer-bar theme-bg theme-fg">
			<div class="col-12">
				<div class="policies d-print-none">
					<a *ngFor="let link of termsLinks" routerLink="{{ link.link }}" target="_blank">{{ link.text }}</a>
				</div>
			</div>
		</div>
	</div>
</div>

<cm-label *ngIf="label" [text]="label"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<div *ngIf="brands; else loading" class="form-row row">
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[0] }"></ng-container>
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[1] }"></ng-container>
	<ng-container *ngTemplateOutlet="col; context: { $implicit: units[2] }"></ng-container>
</div>

<ng-template #col let-unit>
	<div class="col w-1/3">
		<select class="form-control" [(ngModel)]="unit.brand" (ngModelChange)="onBrandSelect(unit)">
			<option value=""></option>
			<option *ngFor="let brand of brands" [ngValue]="brand">{{ brand.name }}</option>
		</select>
		<div *ngIf="unit.brand" class="mt-2">
			<select
				*ngIf="unit.listings; else loading"
				class="form-control"
				[(ngModel)]="unit.listing"
				(ngModelChange)="onListingSelect(unit)"
			>
				<option value="" disabled>Choose model</option>
				<option *ngFor="let listing of unit.listings" [ngValue]="listing">
					{{ listing.year }} {{ listing.modelNum }}
					<ng-container *ngIf="listing.subvin">- {{ listing.subvin }}</ng-container>
				</option>
			</select>

			<ng-container *ngIf="unit.listing">
				<cm-image2
					*ngIf="unit.listing.image"
					[image]="unit.listing.image"
					class="block text-center mt-2"
				></cm-image2>
				<div class="mt-2">
					{{ unit.listing.condition }} {{ unit.listing.year }} {{ unit.brand.name }}
					{{ unit.listing.modelNum }}
				</div>
				<div class="my-2">Sale Price: {{ unit.listing.price | currency: "USD" }}</div>
				<ng-container *ngIf="unit.specs; else loading">
					<div class="flex"><strong class="flex-grow">Sleeps</strong> {{ unit.specs.sleeps }}</div>
					<div class="flex"><strong class="flex-grow">Slides</strong> {{ unit.specs.slides }}</div>
					<div class="flex">
						<strong class="flex-grow">Hitch Weight</strong> {{ unit.specs.hitchWeight }} lbs.
					</div>
					<div class="flex">
						<strong class="flex-grow">Ship Weight</strong> {{ unit.specs.shipWeight }} lbs.
					</div>
					<div class="flex"><strong class="flex-grow">GVWR</strong> {{ unit.specs.gvwr }} lbs.</div>
					<div class="flex">
						<strong class="flex-grow">Length</strong> {{ unit.specs.length.feet }}'
						{{ unit.specs.length.feet }}"
					</div>
					<div class="flex">
						<strong class="flex-grow">Height</strong> {{ unit.specs.height.feet }}'
						{{ unit.specs.height.feet }}"
					</div>
					<div class="flex">
						<strong class="flex-grow">Width</strong> {{ unit.specs.width.feet }}'
						{{ unit.specs.width.feet }}"
					</div>
					<div class="flex">
						<strong class="flex-grow">Fresh Water</strong> {{ unit.specs.freshWater }} gal
					</div>
					<div class="flex">
						<strong class="flex-grow">Gray Water</strong> {{ unit.specs.grayWater }} gal
					</div>
					<div class="flex">
						<strong class="flex-grow">Black Water</strong> {{ unit.specs.blackWater }} gal
					</div>
					<div class="flex">
						<strong class="flex-grow">L.P. Capacity</strong> {{ unit.specs.lpCapacity }} lbs.
					</div>
					<div class="flex"><strong class="flex-grow">Tire Size</strong> {{ unit.specs.tireSize }}</div>
					<div class="flex">
						<strong class="flex-grow">Furnace BTU</strong> {{ unit.specs.furnaceBtu }}
					</div>
				</ng-container>
				<cm-image2
					*ngIf="unit.listing.floorplan"
					[image]="unit.listing.floorplan"
					class="block text-center mt-2"
				></cm-image2>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #loading>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

import { NgModule } from "@angular/core";
import { PinterestSlideshowComponent } from "./components/pinterest-slideshow/pinterest-slideshow.component";
import { SliderModule } from "../slider/slider.module";
import { CommonModule } from "@angular/common";

@NgModule({
	declarations: [PinterestSlideshowComponent],
	imports: [CommonModule, SliderModule],
	exports: [PinterestSlideshowComponent],
})
export class PinterestModule {}

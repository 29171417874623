import { Component, OnInit } from "@angular/core";
import { ImageService } from "@core/app/image.service";
import { RestService, Service } from "@core/app/rest.service";
import { IImage } from "@model/image";
import { IStmtResults } from "@model/stmt-results";
import { IGetImg } from "@model/stmt/GetImg";
import { first, tap } from "rxjs/operators";

@Component({
	selector: "cm-trust-icons",
	template: `
		<cm-image *ngFor="let icon of icons" [obj]="icon" class="mx-1"></cm-image>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			cm-image {
				max-width: 50px;
				display: inline-block;
			}
		`,
	],
})
export class TrustIconsComponent implements OnInit {
	icons: IImage[] = [];
	statementService: Service;
	constructor(private imageService: ImageService, restService: RestService) {
		this.statementService = restService.init("statement");
	}

	ngOnInit() {
		this.statementService
			.post$("GetImg", { vars: { img_cat: "TrustIcon", limit: 3 } })
			.pipe(
				tap((response: IStmtResults<IGetImg>) => {
					if (response.success) {
						for (const item of response.results!) {
							this.icons.push(this.imageService.make(item, { size: "i" }));
						}
					}
				}),
				first(),
			)
			.subscribe();
	}
}

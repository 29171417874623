import { Component, Input, OnInit, OnChanges, SimpleChange } from "@angular/core";

interface ItemTypeAttrs {
	itemType: string;
	itemscope: string;
}

@Component({
	selector: "cm-button-grid",
	templateUrl: "./button-grid.component.html",
	styleUrls: ["./button-grid.component.scss"],
})
export class ButtonGridComponent implements OnInit, OnChanges {
	attrs: ItemTypeAttrs = {
		itemType: "",
		itemscope: "",
	};
	gridClasses: any = [];
	gridClassString: string = "";
	loaded: boolean = false;
	@Input() classes: any = null;
	@Input() gridLg!: string | number;
	@Input() gridMd!: string | number;
	@Input() gridSm!: string | number;
	@Input() gridXs!: string | number;
	@Input() gridItemType!: string;
	@Input() gridData!: string;
	@Input() firstOffset!: string;

	setupGrid(): void {
		this.loaded = true;

		if (this.classes) {
			this.gridClassString = this.classes;
		} else {
			if (this.gridLg === undefined) {
				this.gridLg = 3;
			}
			if (this.gridMd === undefined) {
				this.gridMd = 3;
			}
			if (this.gridSm === undefined) {
				this.gridSm = 4;
			}
			if (this.gridXs === undefined) {
				this.gridXs = 6;
			}
			if (this.gridItemType !== undefined && this.gridItemType) {
				this.attrs = {
					itemType: this.gridItemType,
					itemscope: "",
				};
			}
			this.gridClasses.push("col-" + this.gridXs);
			this.gridClasses.push("col-sm-" + this.gridSm);
			this.gridClasses.push("col-md-" + this.gridMd);
			this.gridClasses.push("col-lg-" + this.gridLg);
			this.gridClassString = this.gridClasses.join(" ");
		}
	}

	ngOnInit() {
		this.setupGrid();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.gridData && !changes.gridData.isFirstChange()) {
			this.gridData = changes.gridData.currentValue;
		}
	}
}

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
	selector: "cm-field-month-year",
	templateUrl: "./field-month-year.component.html",
	styleUrls: ["./field-month-year.component.scss"],
})
export class FieldMonthYearComponent implements OnInit {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() value?: string; // TODO: parse date if set

	@Output() valueChange = new EventEmitter<string>();

	month: string = "";
	year: string = "";
	yearList: number[] = [];

	constructor() {
		const currentYear = new Date().getFullYear();
		for (let i = 0; i < 50; i++) {
			this.yearList.push(currentYear - i);
		}
	}

	changed() {
		if (!this.year || !this.month) {
			this.value = "";
			this.valueChange.emit(``);
		} else {
			this.value = `${this.year}-${this.month}-01`;
			this.valueChange.emit(`${this.year}-${this.month}-01`);
		}
	}

	ngOnInit(): void {
		if (this.value) {
			const temp = this.value.split("-");
			this.month = temp[1];
			this.year = temp[0];
		} else {
			this.value = "";
		}
	}
}

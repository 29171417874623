import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageComponent } from "@core/app/image/components/image/image.component";
import { HrefDirective } from "@core/app/href.directive";
import { HtmlToPlaintextPipe } from "@core/app/html-to-plaintext.pipe";
import { TelPipe } from "@core/app/tel.pipe";
import { Image2Component } from "./components/image2/image2.component";

@NgModule({
	declarations: [HrefDirective, HtmlToPlaintextPipe, ImageComponent, TelPipe, Image2Component],
	imports: [CommonModule],
	entryComponents: [ImageComponent],
	exports: [HrefDirective, HtmlToPlaintextPipe, ImageComponent, TelPipe, Image2Component],
})
export class ImageModule {}

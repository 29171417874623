import { DOCUMENT } from "@angular/common";
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { faCalendarAlt, faClock } from "@fortawesome/pro-solid-svg-icons";
import { fromEvent } from "rxjs";
import { take } from "rxjs/operators";
import { parseDate } from "@common/date";
import { format } from "date-fns";

let nextIdNum = 0;

@Component({
	selector: "cm-field-datetime",
	templateUrl: "./field-datetime.component.html",
	styleUrls: ["./field-datetime.component.scss"],
})
export class FieldDatetimeComponent implements OnInit {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;
	@Input() value?: string;
	@Input() format: string = "MM/DD/YYYY HH:mm:ss";
	@Input() displayFormat: boolean = false;

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	faCalendarAlt = faCalendarAlt;
	faClock = faClock;

	idNum: number = 0;
	error: string | null = null;
	pickerVisible: boolean = false;
	myDate?: string;
	myTime?: string;
	validated: boolean = false;

	constructor(@Inject(DOCUMENT) private document: any) {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-datetime-${this.idNum}`;
	}

	ngOnInit(): void {
		if (this.value) {
			try {
				const date = parseDate(this.value, this.format);
				this.myDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
				this.myTime = "" + date.getHours() + ":" + date.getMinutes();
			} catch {
				const date = parseDate(this.value, "YYYY-MM-DD HH:mm:ss");
				this.format = "YYYY-MM-DD HH:mm:ss";
				this.myDate = this.value.split(" ")[0];
				this.myTime = this.value.split(" ")[1] || "0:0:0";
			}
		}
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}

	onPickerToggle(event: Event) {
		event.stopPropagation();

		this.pickerVisible = !this.pickerVisible;

		if (this.pickerVisible) {
			fromEvent(this.document, "click")
				.pipe(take(1))
				.subscribe(() => {
					this.pickerVisible = false;
				});
		}
	}

	onSubmit() {
		this.pickerVisible = false;

		this.validate();
		if (this.validated) {
			this.value = this.myDate + " " + this.myTime + ":00";
			this.valueChange.emit(this.value);
		}
	}
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { AutocompleteComponent } from "./autocomplete.component";

@NgModule({
	declarations: [AutocompleteComponent],
	imports: [CommonModule, FormsModule, NgbTypeaheadModule],
	exports: [AutocompleteComponent],
})
export class AutocompleteModule {}

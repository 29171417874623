import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { FieldCannedResponseComponent } from "@core/app/form/components/field-canned-response/field-canned-response.component";
import { FieldCheckboxComponent } from "@core/app/form/components/field-checkbox/field-checkbox.component";
import { FieldCompareComponent } from "@core/app/form/components/field-compare/field-compare.component";
import { FieldDateComponent } from "@core/app/form/components/field-date/field-date.component";
import { FieldDatetimeComponent } from "@core/app/form/components/field-datetime/field-datetime.component";
import { FieldFileMultipleComponent } from "@core/app/form/components/field-file-multiple/field-file-multiple.component";
import { FieldFileComponent } from "@core/app/form/components/field-file/field-file.component";
import { FieldLineItemComponent } from "@core/app/form/components/field-line-item/field-line-item.component";
import { FieldMonthYearComponent } from "@core/app/form/components/field-month-year/field-month-year.component";
import { FieldNumberComponent } from "@core/app/form/components/field-number/field-number.component";
import { FieldRadioComponent } from "@core/app/form/components/field-radio/field-radio.component";
import { FieldSelectComponent } from "@core/app/form/components/field-select/field-select.component";
import { FieldServiceIssueComponent } from "@core/app/form/components/field-service-issue/field-service-issue.component";
import { FieldTextMultipleComponent } from "@core/app/form/components/field-text-multiple/field-text-multiple.component";
import { FieldTextComponent } from "@core/app/form/components/field-text/field-text.component";
import { FieldTextareaComponent } from "@core/app/form/components/field-textarea/field-textarea.component";
import { FieldTimeComponent } from "@core/app/form/components/field-time/field-time.component";
import { FieldUserAuthGroupsComponent } from "@core/app/form/components/field-user-auth-groups/field-user-auth-groups.component";
import { FieldComponent } from "@core/app/form/components/field/field.component";
import { FieldsetComponent } from "@core/app/form/components/fieldset/fieldset.component";
import { FormPopUpComponent } from "@core/app/form/components/form-popup/form-popup.component";
import { FormComponent } from "@core/app/form/components/form/form.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LabelComponent } from "./components/label/label.component";

@NgModule({
	imports: [CommonModule, FormsModule, FontAwesomeModule, MatButtonModule],
	declarations: [
		FieldCannedResponseComponent,
		FieldCheckboxComponent,
		FieldCompareComponent,
		FieldComponent,
		FieldDateComponent,
		FieldDatetimeComponent,
		FieldFileComponent,
		FieldFileMultipleComponent,
		FieldMonthYearComponent,
		FieldNumberComponent,
		FieldRadioComponent,
		FieldSelectComponent,
		FieldServiceIssueComponent,
		FieldUserAuthGroupsComponent,
		FieldTextComponent,
		FieldTextMultipleComponent,
		FieldTextareaComponent,
		FieldTimeComponent,
		FieldLineItemComponent,
		FieldsetComponent,
		FormComponent,
		FormPopUpComponent,
		LabelComponent,
	],
	exports: [
		FieldCannedResponseComponent,
		FieldCheckboxComponent,
		FieldCompareComponent,
		FieldComponent,
		FieldDateComponent,
		FieldDatetimeComponent,
		FieldFileComponent,
		FieldFileMultipleComponent,
		FieldMonthYearComponent,
		FieldNumberComponent,
		FieldRadioComponent,
		FieldSelectComponent,
		FieldServiceIssueComponent,
		FieldUserAuthGroupsComponent,
		FieldTextComponent,
		FieldTextMultipleComponent,
		FieldTextareaComponent,
		FieldTimeComponent,
		FieldLineItemComponent,
		FieldsetComponent,
		FormComponent,
		LabelComponent,
	],
})
export class FormModule {}

import { ChangeDetectorRef, Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CacheService } from "../cache.service";
import { RestService, Service } from "../rest.service";
import { UserService } from "../user.service";

let nextIdNum = 0;

@Component({
	selector: "cm-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
	service: Service;
	provider: Service;
	cache: any;
	createUser: Service;

	errorMessage: string;
	idNum: number = nextIdNum++;
	view: "SignIn" | "SignUp";
	user = {
		firstName: "",
		lastName: "",
		userLogin: "",
		email: "",
		phoneNumber: "",
		password: "",
		confirmPassword: "",
		customerRecord: true,
	};
	username: string = "";
	password: string = "";
	remember: boolean = true;
	signingIn: boolean = false;

	constructor(
		private cacheService: CacheService,
		private toastrService: ToastrService,
		private userService: UserService,
		private cd: ChangeDetectorRef,
		restService: RestService,
	) {
		this.service = restService.init("oauth");
		this.provider = restService.init("statement");
		this.createUser = restService.init("user");
		this.cache = this.cacheService.init("sessionStorage");
		this.errorMessage = "";
		this.view = "SignIn";
	}

	/**
	 *  Send Email to User with temp password
	 */
	forgotPassword() {
		this.createUser.post("forgotPassword", { userName: this.username }).then((response: any) => {
			if (response.success) {
				this.toastrService.success(response.message);
			} else {
				this.toastrService.error(response.message);
			}
		});
	}

	/**
	 *  Login User
	 */
	signIn() {
		if (this.signingIn) {
			return;
		}

		this.signingIn = true;
		this.errorMessage = "";

		this.userService.logIn(this.username, this.password, this.remember).subscribe({
			next: () => {
				this.signingIn = false;
			},
			error: (res) => {
				this.errorMessage = res.error.message;
				this.signingIn = false;
			},
		});
	}

	/**
	 * Sign Up
	 *
	 */
	signUp() {
		this.createUser.post("create", this.user).then((response: any) => {
			if (response.success) {
				this.toastrService.success("Account created successfully.");

				this.userService.logIn(this.user.userLogin, this.user.password, false).subscribe();
				this.cd.markForCheck();
			} else {
				this.toastrService.error(response.message);
			}
		});
	}
}

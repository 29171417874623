import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CacheService } from "@core/app/cache.service";
import { ImageService } from "@core/app/image.service";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first, tap } from "rxjs/operators";
import { PromotionsService } from "../../services/promotions.service";

@Component({
	selector: "cm-promotion-popup",
	templateUrl: "./promotion-popup.component.html",
	styleUrls: ["./promotion-popup.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class PromotionPopupComponent implements OnDestroy, OnInit {
	faMinus = faMinus;
	faPlus = faPlus;
	mobile: boolean = false;
	totalCount: number = 0;
	promotions: any = [];
	sessionService: any = null;
	@ViewChild("promotionPopup", { static: true }) promotionPopup!: TemplateRef<NgbModal>;

	constructor(
		private imageService: ImageService,
		router: Router,
		private cacheService: CacheService,
		private promotionsService: PromotionsService,
		@Inject(DOCUMENT) private document: any,
	) {
		this.sessionService = this.cacheService.init("sessionStorage");
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				for (const item of this.promotions) {
					if (item.opened) {
						item.opened = false;
					}
				}
			}
		});
	}

	handleWindowSizeClosure: () => void = () => this.handleWindowSize();

	handleWindowSize(): void {
		const winWidth = this.document.body.clientWidth;

		this.mobile = winWidth <= 991;

		if (this.mobile) {
			for (const item of this.promotions) {
				if (item.opened) {
					item.opened = false;
				}
			}
		}
	}

	hide(item: any): void {
		item.hide = true;
		this.totalCount--;
	}

	ngOnInit() {
		this.mobile = this.document.body.clientWidth <= 991;
		if (typeof window !== "undefined") {
			window.addEventListener("resize", this.handleWindowSizeClosure);
		}
		this.promotionsService.promotions$
			.pipe(
				tap((response) => {
					if (response.success) {
						for (const item of response.results!) {
							this.totalCount++;
							const current = {
								hide: false,
								opened: false,
								promotion: item.promotion,
								desc: item.promotion_desc,
								title: item.promotion,
								headerClass: "theme-highlight",
								content: item.meta_desc,
								button: {
									text: "View Discounted RVs",
									link: item.content_page_url,
									css: { classes: "btn btn-primary" },
								},
								mainImg: this.imageService.make({
									id: item.meta_img_dir + item.meta_img_file,
									size: "s",
									altText: item.promotion,
								}),
								popupImg: this.imageService.make({
									id: item.meta_img_dir + item.meta_img_file,
									size: "l",
									altText: item.promotion,
								}),
								iconImg: this.imageService.make({
									id: item.img_dir + item.img_file,
									size: "i",
									altText: item.promotion,
								}),
							};
							this.promotions.push(current);
						}
					}
				}),
				first(),
			)
			.subscribe();
	}

	ngOnDestroy() {
		if (typeof window !== "undefined") {
			window.removeEventListener("resize", this.handleWindowSizeClosure);
		}
	}

	toggleVisible(item: any): void {
		item.opened = !item.opened;
	}
}

<div class="cm-reviews" *ngIf="reviews.length > 0">
	<cm-card-grid
		[obj]="{
			cardGridClasses: 'col-12 col-md-4 col-lg-4 review',
			cardClass: 'theme-primary-review-card',
			items: reviews,
			type: 'card'
		}"
	></cm-card-grid>
	<div
		*ngIf="reviews.length > 0"
		infiniteScroll
		[infiniteScrollDistance]="2"
		[infiniteScrollThrottle]="50"
		[infiniteScrollDisabled]="loading"
		(scrolled)="loadMore()"
	></div>
</div>

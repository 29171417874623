import { Component, HostBinding, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { FieldOption } from "@core/app/form/field";

let nextIdNum = 0;

@Component({
	selector: "cm-field-select",
	templateUrl: "./field-select.component.html",
	styles: [":host { display: block; } select{width:auto; max-width: 100%;}"],
})
export class FieldSelectComponent {
	@Input() name!: string;
	@Input() options: FieldOption[] | null = null;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() required: boolean = false;
	@Input() value: string = "";
	@Input() multiple: boolean = false;

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("select", { static: false }) select!: ElementRef<HTMLSelectElement>;

	idNum: number = 0;
	error: string | null = null;
	validated: boolean = false;

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id() {
		return `field-select-${this.idNum}`;
	}

	validate() {
		if (!this.select.nativeElement.checkValidity()) {
			this.error = this.select.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}
}

<ng-container>
	<div><cm-label *ngIf="label" [text]="label"></cm-label></div>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<select class="form-control" name="{{ name }}_year" [disabled]="disabled" (change)="changed()" [(ngModel)]="month">
		<option value="01">January</option>
		<option value="02">February</option>
		<option value="03">March</option>
		<option value="04">April</option>
		<option value="05">May</option>
		<option value="06">June</option>
		<option value="07">July</option>
		<option value="08">August</option>
		<option value="09">September</option>
		<option value="10">October</option>
		<option value="11">November</option>
		<option value="12">December</option>
	</select>
	<select class="form-control" name="{{ name }}_month" [disabled]="disabled" (change)="changed()" [(ngModel)]="year">
		<option *ngFor="let listYear of yearList" [value]="listYear">
			{{ listYear }}
		</option>
	</select>
</ng-container>

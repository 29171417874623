import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AdminService } from "@core/app/admin.service";
import { iterObj, tuple } from "@core/app/common/iter";
import { cloneDeep } from "@core/app/common/util";
import { ConfigService } from "@core/app/config.service";
import { DealerService } from "@core/app/dealer.service";
import { GTMService } from "@core/app/gtm.service";
import { ImageService } from "@core/app/image.service";
import { UserService } from "@core/app/user.service";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faSignOut, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { fromEvent, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

@Component({
	selector: "cm-asc-header",
	templateUrl: "./asc-header.component.html",
	styleUrls: ["./asc-header.component.scss"],
})
export class AscHeaderComponent implements OnDestroy, OnInit, OnChanges {
	@Input() obj: any;

	appToken: any = null;
	headerData: any = null;
	watcher: any = {};
	managePageButton: any = {};
	header: any = {};
	onLoggedIn: Subscription;
	onLoggedOut: Subscription;
	scroll: Subscription | null = null;
	routeTitle: string | null = null;
	faSignOut = faSignOut;
	faUsers = faUsers;

	constructor(
		@Inject(ConfigService) private config: ConfigService,
		@Inject(DealerService) private dealerService: DealerService,
		private imageService: ImageService,
		@Inject(AdminService) public adminService: AdminService,
		@Inject("PAGE_DATA") private pageData: IPageData,
		private userService: UserService,
		route: ActivatedRoute,
		router: Router,
		private gtmService: GTMService,
	) {
		router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((event) => {
					let child = route.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data && child.snapshot.data.routeData) {
							return tuple(event as NavigationEnd, child.snapshot.data);
						} else {
							return tuple(event as NavigationEnd, null);
						}
					}
					return tuple(event as NavigationEnd, null);
				}),
			)
			.subscribe(([event, data]) => {
				this.routeTitle = data!.bodyClass;
				this.setupHeader();
			});
		this.appToken = this.config.getAppToken();
		this.headerData = this.pageData.headerData;
		this.onLoggedIn = userService.loggedIn$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => this.setupUser());

		this.onLoggedOut = userService.loggedIn$.pipe(filter((loggedIn) => !loggedIn)).subscribe(() => {
			this.header.user = { first_name: "GUEST" };
			this.header.loggedIn = false;
			this.header.menu.buttons.buttons = [];
			this.header.menu = cloneDeep(this.header.menu);
		});
		this.managePageButton = {
			text: "Manage Page",
			css: { classes: "btn btn-highlight" },
			clicked: () => this.openAdmin(),
		};
	}

	openAdmin() {
		this.adminService.openModal();
	}

	updateHeaderStyleClosure = () => this.updateHeaderStyle();

	ngOnInit() {
		this.header = {
			logo: this.imageService.make({
				src: this.appToken.siteLogo.url,
				size: "o",
				altText: this.appToken.siteName + " Logo",
				link: "/",
			}),
			loggedIn: false,
			menu: {
				type: "mobile",
				buttons: {
					buttons: [],
				},
				items: [],
				socialLinks: { buttons: [] },
			},
			phone: this.dealerService.getPhone(),
			user: {
				first_name: "GUEST",
			},
		};

		for (const item of this.headerData.queries.GetMainMenu) {
			if (item.of_depth === 0 && item.app_menu_item_ofid === null) {
				this.addMenuItem(item, this.header.menu.items, this.headerData.queries.GetMainMenu);
			}
		}

		for (const site of this.headerData.queries.GetDealerSocialAffiliateLinks) {
			let icon = null;
			switch (site.affiliate_name) {
				case "Facebook":
					icon = faFacebook;
					break;
				case "Instagram":
					icon = faInstagram;
					break;
				case "YouTube":
					icon = faYoutube;
					break;
			}
			this.header.menu.socialLinks.buttons.push({
				icon: icon,
				link: site.site_affiliate_url,
			});
		}
	}

	logout() {
		this.userService.logOut();
		this.header.user = { first_name: "GUEST" };
		this.header.loggedIn = false;
	}

	addMenuItem(item: any, menu: any, items: any) {
		const menuItem = {
			id: item.app_menu_itemid,
			text: item.app_menu_item,
			link: item.custom_url,
			icon: item.img_file ? null : item.icon ? "fa " + item.icon + " fa-fw" : null,
			image: item.img_file
				? this.imageService.make({
						src: item.img_dir + item.img_file,
						size: "i",
						altText: item.app_menu_item,
				  })
				: null,
			items: [],
		};

		this.findSubItems(item.app_menu_itemid, menuItem.items, items);

		menu.push(menuItem);
	}

	findSubItems(id: any, menuItems: any, items: any) {
		for (const item of items) {
			if (item.app_menu_item_ofid === id) {
				this.addMenuItem(item, menuItems, items);
			}
		}
	}

	updateHeaderStyle() {
		const scrollTop = window.pageYOffset || 0;

		if (scrollTop > 85) {
			this.header.small = true;
		} else if (scrollTop <= 85 && this.header.small) {
			this.header.small = false;
		}
	}

	setupHeader() {
		if (!this.routeTitle || this.routeTitle === "Home") {
			if (typeof window !== "undefined") {
				this.scroll = fromEvent(window, "scroll").subscribe(() => this.updateHeaderStyleClosure());
			}
		} else {
			if (this.scroll) {
				this.scroll.unsubscribe();
			}
			this.header.small = true;
		}
		this.setupUser();
	}

	setupUser() {
		const user = this.userService.get();

		if (user) {
			this.header.user = user;
			if (user.hasOwnProperty("img") && user.img) {
				this.header.user.imageObj = this.imageService.make(user.img, {
					size: "t",
					bg: true,
					link: "/my-account",
				});
			}
			this.header.loggedIn = true;
		}

		this.header.menu.buttons = [];
		if (this.userService.loggedIn() && !this.userService.isCustomer()) {
			if (
				this.userService.isAdmin() ||
				this.userService.isManager() ||
				this.routeTitle === "Inventory" ||
				this.routeTitle === "Listing" ||
				this.routeTitle === "Project"
			) {
				this.header.menu.buttons.push(this.managePageButton);
			}
		}

		this.header.menu = cloneDeep(this.header.menu);
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.obj && !changes.obj.isFirstChange()) {
			for (const [k, v] of iterObj(changes.obj.currentValue)) {
				this.header[k] = v;
			}
		}
	}

	ngOnDestroy() {
		this.onLoggedOut.unsubscribe();
		this.onLoggedIn.unsubscribe();
	}

	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}

import { parse } from "date-fns";
import { from, Observable } from "rxjs";

const loadedScripts: Map<string, Promise<string>> = new Map();

export function assertNever(x: never): never {
	throw new Error("Unexpected object: " + x);
}

export function capitalize(string: string): string {
	if (string !== "") {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}
	return string;
}

export function camelCase(string: string): string {
	if (string !== "") {
		return string.replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
			if (p2) {
				return p2.toUpperCase();
			}
			return p1.toLowerCase();
		});
	}
	return string;
}

export function cloneDeep<T>(obj: T): T {
	return cloneDeepImpl(obj);
}

export function download(doc: any, filename: string, mime: string, data: string) {
	const el = doc.createElement("a");
	el.href = `data:${mime};charset=utf-8,${encodeURIComponent(data)}`;
	el.download = filename;
	el.style.display = "none";

	doc.body.appendChild(el);
	el.click();
	doc.body.removeChild(el);
}

export function evalWithContext(js: string, context: any): any {
	// tslint:disable-next-line:no-eval
	return function() {
		return eval(js);
	}.call(context);
}

export async function loadScripts(doc: any, urls: Iterable<string>): Promise<void> {
	const promises: Promise<string>[] = [];

	for (const url of urls) {
		let promise = loadedScripts.get(url);
		if (promise) {
			promises.push(promise);
			continue;
		}

		promise = new Promise<string>((resolve, reject) => {
			const req = new XMLHttpRequest();
			req.onreadystatechange = () => {
				if (req.readyState === 4) {
					if (req.readyState === 4 && ((req.status >= 200 && req.status < 300) || req.status === 304)) {
						resolve(req.responseText);
					} else {
						reject(req.responseText);
					}
				}
			};
			req.open("GET", url);
			req.send();
		});

		loadedScripts.set(url, promise);
		promises.push(promise);
	}

	for (const promise of promises) {
		const script = doc.createElement("script");
		script.appendChild(doc.createTextNode(await promise));
		doc.body.appendChild(script);
	}

	return Promise.all(promises).then(() => undefined);
}

export function loadScripts$(doc: any, urls: Iterable<string>): Observable<void> {
	return from(loadScripts(doc, urls));
}

export function loadScript(scriptUrl: string) {
	if (!document.querySelectorAll(`[src="${scriptUrl}"]`).length) {
		const script = document.createElement("script");
		script.src = scriptUrl;
		document.body.appendChild(script);
	}
}

export function mod(lhs: number, rhs: number): number {
	return ((lhs % rhs) + rhs) % rhs;
}

export function elHeightWithMargins(el: HTMLElement) {
	const style = window.getComputedStyle(el);
	return el.offsetHeight + parseInt(style.marginTop || "0") + parseInt(style.marginBottom || "0");
}

export function parseUtc(date: string) {
	return parse(date + "Z");
}

export function round(num: number, decimals: number) {
	return Number(Math.round(Number(num + `e+${decimals}`)) + `e-${decimals}`);
}

export function unescapeHtml(doc: any, string: string) {
	const temp = doc.createElement("div");
	temp.innerHTML = string;
	const result = temp.childNodes[0].nodeValue;
	return result;
}

export function upperFirst(string: string): string {
	if (string !== "") {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return string;
}

function cloneDeepImpl<T>(obj: T, hash = new WeakMap<any>()): T {
	if (Object(obj) !== obj) {
		return obj; // primitives
	}

	if (obj instanceof Set) {
		return new Set(obj) as any; // don't clone keys
	}

	if (hash.has(obj)) {
		// cyclic reference
		return hash.get(obj);
	}

	let result: any;
	if (obj instanceof Date) {
		result = parse(obj);
	} else if (obj instanceof Function) {
		result = obj;
	} else if (obj instanceof RegExp) {
		result = new RegExp(obj.source, obj.flags);
	} else if (Array.isArray(obj)) {
		result = obj.map((x) => cloneDeepImpl(x));
	} else if (obj instanceof Map) {
		result = new Map();
		Array.from(obj, ([key, val]) => result.set(key, cloneDeepImpl(val, hash)));
	} else {
		result = Object.create((obj as any).prototype || Object.prototype);
		Object.assign(result, ...Object.keys(obj).map((key) => ({ [key]: cloneDeepImpl((obj as any)[key], hash) })));
	}

	hash.set(obj, result);

	return result;
}

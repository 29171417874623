import { Injectable, Inject } from "@angular/core";
import { ConfigService } from "./config.service";
import { cloneDeep } from "./common/util";
import { UploadService } from "./upload.service";
import { iterObj } from "./common/iter";

@Injectable({ providedIn: "root" })
export class ImageService {
	buckets = {
		dms: "dms/",
		img: "img/",
		wpContent: "wp-content/",
	};
	imgStaticUrl = "s3static/";

	constructor(
		@Inject(ConfigService) private config: ConfigService,
		@Inject(UploadService) private uploadService: UploadService,
	) {}

	makeImgSrc(image: any, size: any) {
		let src = image !== null && typeof image === "object" ? "" : image;

		const props = {
			id: ["id"],
			src: ["src"],
			img_dir: ["img_dir", "img_file"],
			img_dir2: ["img_dir2", "img_file2"],
			img_dir3: ["img_dir3", "img_file3"],
		};
		let index;

		if (src === undefined) {
			src = "";
		}

		for (const [k, v] of iterObj(props)) {
			if (image !== undefined && null !== image && image.hasOwnProperty(k)) {
				for (const prop of v) {
					if (image.hasOwnProperty(prop) && image[prop]) {
						src += image[prop];
					}
				}

				if (src !== "") {
					break;
				}
			}
		}

		src = src.replace(/-i\.|-t\.|-s\.|-m\.|-l\.|-o\.|-lc\./gi, ".");
		index = src.lastIndexOf(".");

		if (-1 !== index && size !== "a") {
			src = src.substr(0, index) + "-" + size + src.substr(index);
		}

		if (!src.startsWith("http")) {
			if (this.config.hasOwnProperty("cdnRootPath")) {
				// case where img url does not contain full path to S3 and is not an absolute img url reference
				if (!src || src === "cdnRootPath") {
					src = null;
				} else if (src.includes(this.buckets.dms) || src.includes(this.buckets.img)) {
					src = this.config.cdnRootPath + src;
				} else if (src.includes(this.buckets.wpContent)) {
					src = this.config.cdnRootPath + this.buckets.dms + src;
				} else if (!src.includes(this.config.imgStaticUrl)) {
					src = this.config.cdnRootPath + this.config.imgStaticUrl + src;
				} else {
					src = this.config.cdnRootPath + src;
				}
			} else {
				if (!src || src === "cdnRootPath") {
					src = null;
				} else if (src.includes(this.buckets.dms) || src.includes(this.buckets.img)) {
					src = "https://dms.rvimg.com/" + src;
				} else if (src.includes(this.buckets.wpContent)) {
					src = "https://dms.rvimg.com/" + this.buckets.dms + src;
				} else if (!src.includes(this.imgStaticUrl)) {
					src = "https://dms.rvimg.com/" + this.imgStaticUrl + src;
				} else {
					src = "https://dms.rvimg.com/" + src;
				}
			}
		}
		return src;
	}

	make(image: any, options?: any) {
		let size = "s";
		size = options ? options.size || image.size || "s" : image.size || "s";

		let src = this.makeImgSrc(image, size);

		if (src && image.cacheBuster) {
			src += "?cacheBuster=" + (Math.floor(Math.random() * 100000) + 1);
		}

		if (!src) {
			return null;
		}

		const bgSize = options ? options.bgSize || image.bgSize || "cover" : image.bgSize || "cover";

		const temp = src.split("/");
		const last = temp[temp.length - 1];

		return Object.assign(
			Object.assign(cloneDeep(image), {
				altText: image.img_alt || image.altText || null,
				titleText: image.img || image.titleText || image.title || image.img_alt || image.altText || null,
				bg: image.bg || false,
				bgFixed: image.bgFixed || false,
				bindOnce: image.bindOnce || false,
				captionText: image.img_caption || image.captionText || null,
				class: image.class || "",
				clicked: image.clicked || null,
				disabled: image.disabled || false,
				height: image.height || image.img_height || null,
				id: src,
				link: image.link || null,
				name: last,
				show: image.show || false,
				size: size,
				style:
					image.bg || (options && options.bg)
						? {
								background: "transparent url(" + src + ")",
								"background-repeat": "no-repeat",
								"background-position": "center center",
								"background-size": bgSize,
								"background-attachment": image.bgFixed ? "fixed" : "initial",
						  }
						: null,
				src: src,
				template: null,
				text: image.text || null,
				topImage: image.topImage || null,
				topText: image.topText || null,
				width: image.width || image.img_width || null,
			}),
			options,
		);
	}

	upload(files: any, options: any, callback: any) {
		this.uploadService.upload("/api/image-library/v1/images", files, options, callback);
	}
}

import { Injectable, Inject } from "@angular/core";
import { ConfigService } from "./config.service";
import { cloneDeep } from "./common/util";

@Injectable({ providedIn: "root" })
export class UploadService {
	crudService: any;

	constructor(@Inject(ConfigService) private config: ConfigService) {}

	doUpload(url: any, file: any, data: any, callback: any) {
		if (!data.hasOwnProperty("apiToken")) {
			data.appId = this.config.appToken[this.config.appTokenConfig.appIdKey];
			data.siteConfigId = this.config.appToken[this.config.appTokenConfig.siteConfigIdKey];
		}

		// Upload.upload({url: url, data: data}).then(function (response) {
		//     if (response.data.success) {
		//         if (callback) {
		//             callback({
		//                 type: 'Uploaded',
		//                 file: response.data.av || response.data.img || (response.data.avs && response.data.avs[0]) || (response.data.images && response.data.images[0]),
		//                 originalFile: file
		//             });
		//         }
		//     }

		//     if (response.data.errors && callback) {
		//         callback({
		//             type: 'Errors',
		//             errors: response.data.errors,
		//             file: file
		//         });
		//     }
		// }, null, function (event) {
		//     if (callback) {
		//         callback({
		//             type: 'Status',
		//             loaded: event.loaded,
		//             total: event.total,
		//             percentage: parseInt(100.0 * (event.loaded / event.total)),
		//             file: event.config.data.file,
		//             originalFile: file
		//         });
		//     }
		// });
	}

	upload(url: any, files: any, options: any, callback: any) {
		if (files && files.length) {
			for (const file of files) {
				if (!file.$error) {
					this.doUpload(url, file, Object.assign(cloneDeep(options), { files: [file] }), callback);
				}
			}
		} else if (options && options.urls && options.urls.length) {
			this.doUpload(url, null, cloneDeep(options), callback);
		} else {
			if (callback) {
				callback({
					type: "Errors",
					errors: [["No files specified."]],
					file: null,
				});
			}
		}
	}
}

<div class="row">
	<div class="col w-full">
		<cm-label *ngIf="label && type !== 'hidden'" [forid]="id" [text]="label" [required]="required"></cm-label>
		<div class="inline-block mx-1" *ngIf="intro" [innerHTML]="intro"></div>
		<div class="w-full mb-1">
			<input
				#input
				[type]="type"
				[id]="id"
				[name]="name"
				[disabled]="disabled"
				[required]="required && type !== 'hidden'"
				[attr.maxlength]="type == 'hidden' ? null : maxLen"
				[attr.minlength]="type == 'hidden' ? null : minLen"
				[attr.pattern]="pattern"
				[pattern]="pattern"
				[(ngModel)]="value"
				(ngModelChange)="onModelChange()"
				[ngStyle]="inputStyle"
				class="form-control"
				(blur)="validate()"
				(focus)="focused = true"
			/>
		</div>
		<div *ngIf="!hideCount && focused && maxLen && value" class="small">{{ value.length }} / {{ maxLen }}</div>
		<label class="text-xs text-red-700" *ngIf="error">{{ error }}</label>
	</div>
</div>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideWidgetComponent } from "./components/side-widget/side-widget.component";
import { SharedModule } from "../shared/shared.module";
import { SideWidgetItemComponent } from "./components/side-widget-item/side-widget-item.component";
import { SideWidgetItemsComponent } from "./components/side-widget-items/side-widget-items.component";
import { AthomeModule } from "../athome/athome.module";

@NgModule({
	declarations: [SideWidgetComponent, SideWidgetItemComponent, SideWidgetItemsComponent],
	imports: [CommonModule, SharedModule, AthomeModule],
	exports: [SideWidgetComponent],
})
export class SideWidgetModule {}

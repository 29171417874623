<div class="row">
	<div class="col w-full">
		<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
		<div *ngIf="intro" [innerHTML]="intro"></div>
		<div class="w-full mb-1">
			<select
				*ngIf="multiple"
				#select
				[name]="name"
				[id]="id"
				[disabled]="disabled"
				[required]="required"
				multiple
				class="form-control"
				(blur)="validate()"
				[(ngModel)]="value"
				(ngModelChange)="valueChange.emit($event)"
			>
				<option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</option>
			</select>
			<select
				*ngIf="!multiple"
				#select
				[name]="name"
				[id]="id"
				[disabled]="disabled"
				[required]="required"
				(blur)="validate()"
				[(ngModel)]="value"
				class="form-control"
				(ngModelChange)="valueChange.emit($event)"
			>
				<option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</option>
			</select>
		</div>
		<label class="text-xs text-red-700" *ngIf="error">{{ error }}</label>
	</div>
</div>

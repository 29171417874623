<ng-container>
	<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	<small *ngIf="displayFormat" class="text-muted ml-1">({{ format }})</small>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<div class="input-group">
		<input
			#input
			type="date"
			class="form-control"
			[id]="id"
			[name]="name"
			[disabled]="disabled"
			[required]="required"
			[(ngModel)]="value"
			(ngModelChange)="valueChange.emit($event)"
			(blur)="validate()"
			[attr.pattern]="pattern"
		/>
	</div>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>

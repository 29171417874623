import { Directive, ElementRef, OnInit, OnChanges, Inject, Input, SimpleChange } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";

@Directive({
	selector: "[cmHref]",
})
export class HrefDirective implements OnInit, OnChanges {
	@Input("cmHref") cmHref: string = "";
	@Input() cmHrefOptions?: any = null;

	constructor(
		@Inject(ElementRef) private el: ElementRef,
		@Inject(Location) private location: Location,
		@Inject(DOCUMENT) private document: any,
		private router: Router,
	) {}

	setup(): void {
		if (this.cmHref !== "" && this.cmHrefOptions && this.cmHrefOptions.hasOwnProperty("clicked")) {
			this.el.nativeElement.removeAllListeners("click");
		}
		this.el.nativeElement.removeAttribute("href");

		if (this.cmHref === "" || this.cmHref === "#" || this.cmHref === undefined || this.cmHref === null) {
			this.el.nativeElement.setAttribute("href", "javascript:void(0)");
		} else if (this.cmHref.indexOf("#") === 0) {
			this.el.nativeElement.addEventListener("click", () => {
				const element = this.document.getElementById(this.cmHref.replace("#", ""));
				if (element) {
					element.scrollIntoView();
				}
			});
		} else if (this.cmHref.indexOf("tel") !== -1 || this.cmHref.indexOf("mail") !== -1) {
			this.el.nativeElement.setAttribute("href", this.cmHref);
		} else {
			this.el.nativeElement.setAttribute("href", this.cmHref);
			if (
				!this.cmHrefOptions ||
				!this.cmHrefOptions.hasOwnProperty("preventClick") ||
				!this.cmHrefOptions.preventClick
			) {
				this.el.nativeElement.addEventListener("click", (event: any) => {
					if (this.cmHrefOptions && this.cmHrefOptions.hasOwnProperty("clicked")) {
						this.cmHrefOptions.clicked();
					}
					const url = this.cmHref.split("#");
					const extra: any = {};
					if (url[1]) {
						extra.fragment = url[1];
					}
					const parts = url[0].split("?");
					const object: any = {};
					if (parts[1]) {
						const subparts = parts[1].split("&");
						for (const subpart of subparts) {
							const temp = subpart.split("=");
							object[temp[0]] = temp[1];
						}
						extra.queryParams = object;
					}
					if (parts[0].startsWith("http")) {
						if (this.el.nativeElement.target) {
							window.open(this.cmHref, this.el.nativeElement.target);
						} else {
							window.location.href = this.cmHref;
						}
					} else {
						if (this.el.nativeElement.target) {
							window.open(this.cmHref, this.el.nativeElement.target);
						} else {
							this.router.navigate([parts[0]], extra);
						}
					}

					event.preventDefault();
					event.stopPropagation();
				});
			} else {
				if (!this.cmHref.startsWith("http")) {
					this.el.nativeElement.addEventListener("click", (event: any) => {
						const url = this.cmHref.split("#");
						const extra: any = {};
						if (url[1]) {
							extra.fragment = url[1];
						}
						const parts = url[0].split("?");
						const object: any = {};
						if (parts[1]) {
							const subparts = parts[1].split("&");
							for (const subpart of subparts) {
								const temp = subpart.split("=");
								object[temp[0]] = temp[1];
							}
							extra.queryParams = object;
						}
						if (parts[0].startsWith("http")) {
							if (this.el.nativeElement.target) {
								window.open(this.cmHref, this.el.nativeElement.target);
							} else {
								window.location.href = this.cmHref;
							}
						} else {
							if (this.el.nativeElement.target) {
								window.open(this.cmHref, this.el.nativeElement.target);
							} else {
								this.router.navigate([parts[0]], extra);
							}
						}
						event.preventDefault();
					});
				}
			}
		}
	}

	ngOnInit() {
		this.setup();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.cmHref && !changes.cmHref.isFirstChange()) {
			this.setup();
		} else if (changes.cmHrefOptions && !changes.cmHrefOptions.isFirstChange()) {
			this.setup();
		}
	}
}

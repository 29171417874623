import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { iter, tuple } from "@common/iter";
import { SMap } from "@common/map2";
import { UserService } from "@core/app/user.service";
import { IStmtResults } from "@model/stmt-results";
import { IGetUserAuthGroups } from "@model/stmt/GetUserAuthGroups";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

const metaGroups = new SMap([
	["Adwords", ["Social Media"]],
	["Banners", ["Data Entry", "Social Media"]],
	["Blog", ["Data Entry", "Manager", "Social Media"]],
	["CRM (Calendar)", ["Accounting", "Service"]],
	["CRM (Manager)", ["Data Entry", "Finance", "Manager"]],
	["CRM (Sales)", ["Salesperson", "Social Media"]],
	["Customer Reviews", ["Data Entry", "Manager"]],
	["Edit Others", ["Manager"]],
	["Human Resources", ["All Employees"]],
	["Human Resources (Manager)", ["Manager"]],
	["Inventory", ["Accounting", "Data Entry", "Finance", "Manager", "Salesperson", "Service", "Social Media"]],
	["Inventory Ordering", ["Manager"]],
	["Sales Board", ["Manager"]],
	["Service Tickets", ["Manager", "Service"]],
	["Transport", ["Manager", "Salesperson"]],
]);

@Component({
	selector: "cm-field-user-auth-groups",
	template: `
		<cm-label *ngIf="label" [text]="label"></cm-label>
		<div *ngIf="intro" [innerHTML]="intro"></div>
		<ng-container *ngIf="authGroups$ | async as authGroups">
			<ng-container *ngIf="authGroups.get('All Employees') as allEmps">
				<ng-container
					*ngTemplateOutlet="section; context: { title: 'All Employees', groups: allEmps }"
				></ng-container>
			</ng-container>

			<ng-container *ngFor="let metaGroup of authGroups | keyvalue">
				<ng-container *ngIf="!['Other', 'All Employees'].includes(metaGroup.key)">
					<ng-container
						*ngTemplateOutlet="
							section;
							context: { title: 'Recommended for ' + metaGroup.key, groups: metaGroup.value }
						"
					></ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="authGroups.get('Other') as other">
				<ng-container *ngTemplateOutlet="section; context: { title: 'Other', groups: other }"></ng-container>
			</ng-container>
		</ng-container>

		<ng-template #section let-title="title" let-groups="groups" let-rec="rec">
			<h5 class="mt-3">{{ title }}</h5>
			<div *ngFor="let group of groups" class="form-check">
				<input
					type="checkbox"
					class="form-check-input"
					[disabled]="disabled"
					[ngModel]="
						changes.get(group.auth_groupid) === undefined
							? !!group.site_user_auth_groupid
							: changes.get(group.auth_groupid)
					"
					(ngModelChange)="onCheck(group.auth_groupid, $event)"
					[ngModelOptions]="{ standalone: true }"
				/>
				<span class="form-check-label">{{ group.auth_group }}</span>
			</div>
		</ng-template>
	`,
})
export class FieldUserAuthGroupsComponent implements OnInit, OnChanges {
	@Input() userid?: number;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;

	@Output() valueChange = new EventEmitter<{ [key: string]: boolean }>();

	changes: Map<string, boolean> = new Map();

	authGroups$?: Observable<Map<string, IGetUserAuthGroups[]>>;

	constructor(private http: HttpClient, private user: UserService) {}

	ngOnInit(): void {
		if (!this.authGroups$) {
			this.getAuthGroups();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.userid) {
			this.getAuthGroups();
		}
	}

	onCheck(groupid: string, checked: boolean) {
		this.changes.set(groupid, checked);
		this.valueChange.emit(iter(this.changes).toObject());
	}

	reset(): void {
		this.changes = new Map();
		this.valueChange.emit({});
	}

	private getAuthGroups() {
		this.authGroups$ = combineLatest(
			this.user.permissions$,
			this.http.post("/api/statement/GetUserAuthGroups", {
				vars: { userid: this.userid },
			}),
		).pipe(
			map(([perms, res]) =>
				iter((res as IStmtResults<IGetUserAuthGroups>).results!)
					// statement returns all nodes under each group, so group by groupid
					.groupBy((row) => row.auth_groupid)
					// only display groups when the editing user has a superset of the group's permissions
					.filter(([_, groups]) => groups.all((group) => perms.hasNodeId(group.auth_nodeid)))
					.map(([_, groups]) => groups.nth(0).unwrap())
					// split into categories. most groups will be under multiple categories.
					.flatMap((group) =>
						iter(metaGroups.get(group.auth_group).unwrapOr(["Other"])).map((key) => tuple(key, group)),
					)
					.toGroupMap()
					.toMap(),
			),
			shareReplay(1),
		);
	}
}

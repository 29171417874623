import { Component, Input, Inject, ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";
import { upperFirst } from "@core/app/common/util";
import { MenuHelperService } from "@core/app/menu-helper.service";
import { IPageData } from "@model/page-data";
import { SMap } from "@common/map2";
import { IGetStateMenu } from "@model/stmt/GetStateMenu";

@Component({
	selector: "cm-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	@Input() template?: string;

	address: any = { addressTitle: "", streetLine1: "", streetLine2: "", city: "", state: "", zip: "", country: "" };
	currentYear: number = new Date().getFullYear();
	tagLine: string = "Making Family Memories Since 1986";
	menus: any = null;
	socialData: any = null;
	footerLinks: { text: string; link: string | null; target: string | null }[][] = [];
	termsLinks: { text: string; link: string }[] = [
		{ text: "Service Agreement", link: "/terms-of-service-agreement" },
		{ text: "Privacy Policy", link: "/privacy-policy" },
	];
	phoneNumber: string = "";
	dealer: string = "";
	copyright: string = "";
	copyrightYear: string = "2013-" + new Date().getFullYear();
	mapCenter: any;
	mapData: any;
	/**
	 * type -> hour range -> days
	 */
	hours: SMap<string, SMap<string, string[]>> = new SMap();

	states: IGetStateMenu[];

	constructor(menuHelperService: MenuHelperService, @Inject("PAGE_DATA") private pageData: IPageData) {
		this.states = this.pageData.queries.GetStateMenu;

		let index = 0;
		this.footerLinks[index] = [];
		const limit = Math.ceil(this.pageData.footerMenu.length / 2);
		for (const item of this.pageData.footerMenu) {
			this.footerLinks[index].push({
				text: item.app_menu_item,
				link: item.content_page_url,
				target: item.url_target,
			});
			if (this.footerLinks[index].length >= limit) {
				index++;
				this.footerLinks[index] = [];
			}
		}

		this.menus = menuHelperService.getMenu();
		this.socialData = pageData.headerData.queries.GetDealerSocialAffiliateLinks.map((row) => ({
			id: row.img,
			src: row.img_dir + row.img_file,
			desc: row.affiliate_name,
			link: row.site_affiliate_url,
		}));
		this.setupDealerInfo(pageData.headerData.queries.GetDealerInfo[0]);

		const data = this.pageData.locations[0];
		const latLng = { lat: Number(data.latitude), lng: Number(data.longitude) };
		this.mapCenter = latLng;
		this.mapData = [{ latLng }];

		for (const row of this.pageData.headerData.queries.GetOrgDeptHours) {
			let from = row.time_from;
			if (from && from.startsWith("0")) {
				from = from.substring(1);
			}

			let to = row.time_to;
			if (to && to.startsWith("0")) {
				to = to.substring(1);
			}

			const hourRange = from && to ? `${from} - ${to}` : "Closed";

			this.hours
				.getOrInsertWith(row.org_dept, () => new SMap())
				.getOrInsert(hourRange, [])
				.push(upperFirst(row.day_of_week.toLowerCase()).substr(0, 3));
		}
	}

	setupDealerInfo(results: any) {
		if (null !== results) {
			this.address = {
				streetLine1: results.address1,
				streetLine2: results.address2,
				city: results.city,
				state: results.stateprov,
				zip: results.postal_code,
				country: results.country,
			};
			this.phoneNumber = results.phone_number;
			this.tagLine = results.hasOwnProperty("dealer_tagline") ? results.dealer_tagline : this.tagLine;
			this.dealer = results.dealer;
			this.copyright = "&copy; " + this.copyrightYear + " " + results.dealer;
		}
	}
}

<div class="cm-card" (click)="options.clicked($event)">
	<meta *ngIf="options.link" itemprop="mainEntityOfPage" content="{{ options.link }}" />
	<a *ngIf="options.link" [cmHref]="options.link" class="theme">
		<ng-container *ngIf="template === 'card'">
			<ng-container *ngTemplateOutlet="card"></ng-container>
		</ng-container>
		<ng-container *ngIf="template === 'cardRow'">
			<ng-container *ngTemplateOutlet="cardRow"></ng-container>
		</ng-container>
	</a>
	<div class="theme" *ngIf="!options.link">
		<ng-container *ngIf="template === 'card'">
			<ng-container *ngTemplateOutlet="card"></ng-container>
		</ng-container>
		<ng-container *ngIf="template === 'cardRow'">
			<ng-container *ngTemplateOutlet="cardRow"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #card>
	<div [cmAttr]="options.attrs">
		<div class="image-holder" (mouseover)="showQuick = true" (mouseleave)="showQuick = false">
			<div>
				<cm-image *ngIf="options.image" [obj]="options.image"></cm-image>
				<cm-image *ngIf="options.image2" [obj]="options.image2"></cm-image>
			</div>
			<div class="theme-primary-card hover-image-button" *ngIf="showQuick && options.quickButtons">
				<cm-button *ngFor="let button of options.quickButtons" [obj]="button"></cm-button>
			</div>
		</div>
		<meta itemprop="itemReviewed" *ngIf="options.quote" content="{{ options.headingText }}" />
		<meta *ngIf="options.image" itemprop="image" content="{{ options.image.id }}" />
		<meta itemprop="datePublished" content="{{ options.datePublished }}" />
		<meta itemprop="dateModified" content="{{ options.dateModified }}" />
		<meta *ngIf="options.author && !options.quote" itemprop="author" content="{{ siteName }}" />
		<span itemprop="publisher" itemscope itemtype="http://schema.org/Organization" *ngIf="options.publisher">
			<meta itemprop="name" content="{{ siteName }}" />
			<span itemprop="logo" itemscope itemtype="http://schema.org/ImageObject">
				<meta itemprop="url" content="{{ siteUrl }}" />
				<meta itemprop="width" content="{{ siteLogo.width }}px" />
				<meta itemprop="height" content="{{ siteLogo.height }}px" />
				<meta itemprop="image" content="{{ siteLogo.url }}" />
			</span>
		</span>
		<div class="cm-card-title">
			<span *ngIf="options.headingText" class="cm-card-headline" itemprop="headline">{{
				options.headingText
			}}</span>
		</div>
		<div class="cm-card-content">
			<blockquote *ngIf="options.quote" class="quote">
				<p [innerHTML]="options.bodyText" itemprop="description"></p>
			</blockquote>
			<p *ngIf="!options.quote" [innerHTML]="options.bodyText" itemprop="description"></p>
		</div>
		<div *ngIf="options.quote" class="author" itemprop="author" itemscope itemtype="http://schema.org/Person">
			<span itemprop="name">{{ options.quote }}</span>
		</div>
		<div *ngIf="options.rating" itemprop="reviewRating" itemscope itemtype="http://schema.org/Rating">
			<meta itemprop="worstRating" content="1" />
			<meta itemprop="bestRating" content="5" />
			<meta itemprop="ratingValue" content="{{ options.rating }}" />
		</div>
		<meta itemprop="inLanguage" content="en-US" *ngIf="options.rating" />
		<div *ngIf="options.buttons" class="cm-card-actions theme-primary-card {{ options.alignButtons }}">
			<cm-button *ngFor="let button of options.buttons" [obj]="button"></cm-button>
		</div>
	</div>
</ng-template>

<ng-template #cardRow>
	<div class="row">
		<div class="col-md-3 col-12">
			<a [cmHref]="options.link">
				<cm-image *ngIf="options.image" [obj]="options.image"></cm-image>
			</a>
		</div>
		<div class="col-md-9 col-12">
			<div class="review-content-main col-12">
				<div class="col-lg-4 col-md-6 col-12 order-2">
					<div class="cm-card-title d-md-none">
						<a [cmHref]="options.link">
							<span class="cm-card-headline">{{ options.headingText }}</span>
						</a>
					</div>
				</div>
				<div class="col-lg-8 col-md-6 col-12 order-1">
					<div class="cm-card-title d-none d-md-block">
						<a [cmHref]="options.link">
							<span class="cm-card-headline">{{ options.headingText }}</span>
						</a>
					</div>
					<div class="cm-card-content">
						<p>{{ options.bodyText }}</p>
						<p class="quote-attr col-12" *ngIf="options.quote">- {{ options.quote }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

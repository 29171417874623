<div class="promotions-holder animate d-flex align-items-end" *ngIf="!mobile && totalCount > 0">
	<ng-container *ngFor="let promotion of promotions">
		<div *ngIf="!promotion.hide" class="single-promotion">
			<div class="theme-highlight promotion-heading d-flex" role="button" (click)="toggleVisible(promotion)">
				<cm-image [obj]="promotion.iconImg"></cm-image>
				<div class="flex-grow-1 ml-2 mr-3">{{ promotion.promotion }}</div>
				<fa-icon [icon]="promotion.opened ? faMinus : faPlus" class="mr-3"></fa-icon>
			</div>
			<div class="promotion-body funky-show-hide d-flex flex-column" *ngIf="promotion.opened">
				<div>
					<cm-image [obj]="promotion.mainImg"></cm-image>
				</div>
				<div class="text-center mt-2">
					{{ promotion.content }}
				</div>
				<div class="text-center mt-2 mb-3">
					<a
						cmHref="{{ promotion.button.link }}"
						[cmHrefOptions]="{ clicked: promotion.toggleVisible }"
						class="{{ promotion.button.css.classes }}"
						>{{ promotion.button.text }}</a
					>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<ng-template #promotionPopup let-dismiss="dismiss">
	<div class="modal-header theme-highlight">
		<h4 class="modal-title theme-highlight" id="promotionPopup">{{ promotions[0].title }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<cm-image [obj]="promotions[0].popupImg"></cm-image>
		</div>
		<div class="text-center mt-2">
			{{ promotions[0].desc }}
		</div>
		<div class="text-center mt-2 mb-3">
			<a
				cmHref="{{ promotions[0].button.link }}"
				[cmHrefOptions]="{ clicked: dismiss }"
				class="{{ promotions[0].button.css.classes }}"
				>{{ promotions[0].button.text }}</a
			>
		</div>
	</div>
</ng-template>

import { Component, Inject } from "@angular/core";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header-meta",
	template: `
		<ng-container [ngSwitch]="headerTemplate">
			<cm-asc-header *ngSwitchCase="'asc'"></cm-asc-header>
			<cm-header-athome *ngSwitchCase="'athome'"></cm-header-athome>
			<cm-header-design-force *ngSwitchCase="'designforce'"></cm-header-design-force>
			<cm-header-anthony *ngSwitchCase="'anthony'"></cm-header-anthony>
			<cm-header *ngSwitchDefault [template]="headerTemplate"></cm-header>
		</ng-container>
	`,
})
export class HeaderMetaComponent {
	headerTemplate = this.pageData.headerTemplate || "default";

	constructor(@Inject("PAGE_DATA") private pageData: any) {}
}

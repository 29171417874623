<div class="filter-column">
	<div class="d-flex align-items-center">
		<select class="form-control" [ngModel]="value" (ngModelChange)="doChange($event)">
			<option value="">All</option>
			<option *ngFor="let result of filter.results" [value]="result.value">
				{{ result.label || result.value }}
			</option>
		</select>
	</div>
</div>

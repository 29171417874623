import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "mhs2go",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#83031f",
				bgHover: "#960424",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#7d7d7d",
					bgHover: "#898989",
					fg: "#FFF",
				},
				homeUrl: "https://dms.rvimg.com/s3static/8/homepage-background.jpg",
				footerUrl: "s3static/8/footer-img-s.png",
			},
		},
	},
};

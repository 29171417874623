import { Component, Input, SimpleChange, Inject } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { debounceTime, distinctUntilChanged, map, tap, filter } from "rxjs/operators";
import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import levenshtein from "fast-levenshtein";
import { iter } from "@common/iter";

interface ISearchItem {
	text: string;
	link?: string;
	value?: string;
}

interface IButton {
	text: string;
	clicked: any; // function?
}

interface IOptions {
	buttons: IButton;
	changed: any; // function?
	disabled: boolean;
	enterKeySubmit: boolean;
	focus: any; // function?
	focusFirst: boolean;
	icon: string;
	placeholder: string;
}

let idNum = 0;

@Component({
	selector: "cm-autocomplete",
	template: `
		<div
			class="cm-autocomplete input-group theme-fg-before theme-border-before theme-fg-after theme-border-after"
			itemprop="potentialAction"
			itemscope
			itemtype="http://schema.org/SearchAction"
		>
			<div class="cm-autocomplete-container">
				<meta itemprop="target" content="{{ host }}/search?s={s}" />
				<span class="input-group-addon" *ngIf="options && options.hasOwnProperty('icon')">
					<fa-icon [icon]="options.icon" aria-hidden="true"></fa-icon>
				</span>
				<label class="sr-only" for="typeahead-{{ id }}">{{ options.placeholder }}</label>
				<input
					class="form-control cm-text-field theme-bg-as-fg theme-border"
					itemprop="query-input"
					type="text"
					name="s"
					id="typeahead-{{ id }}"
					[placeholder]="options.placeholder"
					(keyup)="options.changed(model, $event)"
					(selectItem)="options.changed($event)"
					(focus)="options.focus && options.focus()"
					[(ngModel)]="model"
					[ngbTypeahead]="search"
					[resultTemplate]="rt"
					[inputFormatter]="formatter"
				/>
				<div class="input-group-btn" *ngIf="options && options.hasOwnProperty('buttons')">
					<button
						*ngFor="let button of options.buttons"
						type="button"
						class="btn btn-default theme-primary-button"
						(click)="button.clicked(model, $event)"
					>
						{{ button.text }}
					</button>
				</div>
				<fa-icon *ngIf="searching" [pulse]="true" [fixedWidth]="true" [icon]="faSpinner"></fa-icon>
			</div>
		</div>
		<ng-template #rt let-r="result" let-t="term">
			<span tabindex="-1" title="{{ r.text }}">
				<ngb-highlight [result]="r.text" [term]="t"></ngb-highlight>
			</span>
		</ng-template>
	`,
	styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent {
	id = idNum++;
	// TODO: get real host
	host = "";
	searching = false;

	faSpinner = faSpinner;

	@Input() model: any;
	@Input() options!: IOptions;
	@Input() searchItems!: ISearchItem[];

	constructor(router: Router, @Inject(DOCUMENT) private document: Document) {
		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
			this.model = null;
			this.document.body.click();
		});
	}

	// Map to observable?
	search = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(600),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			map((term) => {
				if (term.length < 2 || !this.searchItems) return [];
				const maxLen = iter(this.searchItems)
					.map((item) => item.text.length)
					.max()
					.unwrapOr(0);
				return this.searchItems
					.filter((v) =>
						term
							.toLowerCase()
							.split(" ")
							.some((x) => v.text.toLowerCase().includes(x)),
					)
					.sort(
						(a, b) =>
							levenshtein.get(
								a.text.toLowerCase().padEnd(maxLen, " "),
								term.toLowerCase().padEnd(maxLen, " "),
							) -
							levenshtein.get(
								b.text.toLowerCase().padEnd(maxLen, " "),
								term.toLowerCase().padEnd(maxLen, " "),
							),
					);
			}),
			tap(() => (this.searching = false)),
		);

	formatter = (x: { text: string }) => x.text;
}

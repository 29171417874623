<ng-container *ngFor="let f of filter.results; let index = index; trackBy: tracker">
	<div class="filter-row">
		<div
			class="filter-column"
			placement="right"
			[ngbTooltip]="filter.imageStyle === FilterImageStyle.OnHover ? hoverImage : null"
			tooltipClass="checkbox-selector-tooltip"
			container="body"
		>
			<div class="form-group form-check">
				<label
					for="cm-cb-{{ filter.name }}-{{ index }}"
					class="form-check-label d-block"
					*ngIf="images[f.id] && filter.imageStyle === FilterImageStyle.Above"
				>
					<cm-image [obj]="images[f.id]" class="d-inline"></cm-image>
				</label>
				<input
					type="checkbox"
					id="cm-cb-{{ filter.name }}-{{ index }}"
					class="form-check-input"
					[ngClass]="{ 'position-relative': images[f.id] && filter.imageStyle === FilterImageStyle.Above }"
					[(ngModel)]="boxes[f.id]"
					(ngModelChange)="doChange(f, $event)"
				/>
				<label for="cm-cb-{{ filter.name }}-{{ index }}" class="form-check-label">
					<span class="filter-text" [ngClass]="{ 'img-above': filter.imageStyle === FilterImageStyle.Above }">
						<span>{{ f.label }} {{ f.listing_count ? "(" + f.listing_count + ")" : "" }}</span>
					</span>
					<cm-image
						*ngIf="images[f.id] && filter.imageStyle === FilterImageStyle.Show"
						[obj]="images[f.id]"
						class="d-none d-md-inline img-side"
					></cm-image>
				</label>
			</div>
		</div>
	</div>
	<ng-template #hoverImage>
		<cm-image *ngIf="images[f.id]" [obj]="images[f.id]"></cm-image>
	</ng-template>
</ng-container>

<div
	class="cm-image {{ image.class }}"
	(click)="image.clicked && image.clicked($event, image)"
	*ngIf="image.rootType === 'div' && image.link"
>
	<a [cmHref]="image.link">
		<p *ngIf="image.topText" [innerHTML]="image.topText" class="cm-image-text-top"></p>
		<img
			loading="lazy"
			class="{{ image.attrs.class }}"
			[src]="image.attrs.src"
			src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
			alt="{{ image.attrs.alt }}"
			title="{{ image.attrs.title }}"
		/>
		<noscript>
			<img src="{{ image.attrs.src }}" alt="{{ image.attrs.alt }}" title="{{ image.attrs.title }}" />
		</noscript>
		<p *ngIf="image.text" [innerHTML]="image.text" class="cm-image-text"></p>
	</a>
</div>
<div
	class="cm-image {{ image.class }}"
	(click)="image.clicked && image.clicked($event, image)"
	*ngIf="image.rootType === 'div' && !image.link"
>
	<p *ngIf="image.topText" [innerHTML]="image.topText" class="cm-image-text-top"></p>
	<img
		loading="lazy"
		[src]="image.attrs.src"
		src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
		class="{{ image.attrs.class }}"
		alt="{{ image.attrs.alt }}"
		title="{{ image.attrs.title }}"
	/>
	<noscript>
		<img src="{{ image.attrs.src }}" alt="{{ image.attrs.alt }}" title="{{ image.attrs.title }}" />
	</noscript>
	<p *ngIf="image.text" [innerHTML]="image.text" class="cm-image-text"></p>
</div>
<span
	class="cm-image {{ image.class }}"
	(click)="image.clicked && image.clicked($event, image)"
	*ngIf="image.rootType === 'span' && image.link"
>
	<a [cmHref]="image.link">
		<p *ngIf="image.topText" [innerHTML]="image.topText" class="cm-image-text-top"></p>
		<img
			loading="lazy"
			[src]="image.attrs.src"
			src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
			class="{{ image.attrs.class }}"
			alt="{{ image.attrs.alt }}"
			title="{{ image.attrs.title }}"
		/>
		<noscript>
			<img src="{{ image.attrs.src }}" alt="{{ image.attrs.alt }}" title="{{ image.attrs.title }}" />
		</noscript>
		<p *ngIf="image.text" [innerHTML]="image.text" class="cm-image-text"></p>
	</a>
</span>
<span
	class="cm-image {{ image.class }}"
	(click)="image.clicked && image.clicked($event, image)"
	*ngIf="image.rootType === 'span' && !image.link"
>
	<p *ngIf="image.topText" [innerHTML]="image.topText" class="cm-image-text-top"></p>
	<img
		loading="lazy"
		[src]="image.attrs.src"
		src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
		class="{{ image.attrs.class }}"
		alt="{{ image.attrs.alt }}"
		title="{{ image.attrs.title }}"
	/>
	<noscript>
		<img src="{{ image.attrs.src }}" alt="{{ image.attrs.alt }}" title="{{ image.attrs.title }}" />
	</noscript>
	<p *ngIf="image.text" [innerHTML]="image.text" class="cm-image-text"></p>
</span>

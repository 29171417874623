<header
	class="cm-header theme-primary-bg {{ template }}"
	[ngClass]="{ 'fixate-header': fixed$ | async, smallLogo: small$ | async }"
	itemscope
	itemtype="http://schema.org/WPHeader"
>
	<ng-container *ngIf="template === 'default'">
		<div class="cm-header-container" itemscope itemtype="http://schema.org/Organization">
			<meta *ngIf="logo" itemprop="logo" [content]="logo.src" />
			<meta itemprop="url" [content]="siteUrl" />
			<meta itemprop="name" [content]="dealer" />
			<span *ngIf="address" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
				<meta itemprop="streetAddress" content="{{ address.address1 }} {{ address.address2 }}" />
				<meta itemprop="addressLocality" [content]="address.city" />
				<meta itemprop="addressRegion" [content]="address.stateprov" />
				<meta itemprop="postalCode" [content]="address.postal_code" />
			</span>
			<div class="container">
				<div class="row">
					<!--Logo-->
					<div class="col-12 col-md-3 logo">
						<a routerLink="/"
							><cm-image2 *ngIf="logo; else noLogo" [image]="logo"></cm-image2>
							<ng-template #noLogo
								><span>{{ dealer }}</span></ng-template
							>
						</a>
					</div>

					<!--Phone and Social-->
					<div class="col-12 col-md-9 phone-social">
						<div class="row">
							<!--Phone Number-->
							<div
								class="col-12 col-md-7 phone-number"
								itemprop="contactPoint"
								itemscope
								itemtype="http://schema.org/ContactPoint"
							>
								<meta itemprop="areaServed" content="US" />
								<meta itemprop="availableLanguage" content="English" />
								<strong class="theme-bg-as-fg">
									<cm-phone-number *ngIf="phoneNo" [phone]="phoneNo"></cm-phone-number>
								</strong>
								<meta itemprop="telephone" content="+1-{{ phoneNo }}" />
								<meta itemprop="contactType" content="sales" />
							</div>
							<!--Social Links / Login-->
							<div *ngIf="!(mobile$ | async)" class="col-5 social d-print-none">
								<cm-social-links [socialLinks]="socialData" class="cm-social-links"></cm-social-links>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container menu-row">
			<div class="row">
				<div id="cm-global-header-menu" class="col-sm-9">
					<cm-menu
						[imgMenuData]="menus"
						[imgMenuAutocompleteData]="searchValues$ | async"
						[imgMenuAutocompleteOptions]="searchOptions"
					>
					</cm-menu>
				</div>
				<div *ngIf="!(mobile$ | async)" class="col-md-3 d-print-none">
					<cm-autocomplete [searchItems]="searchValues$ | async" [options]="searchOptions"></cm-autocomplete>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="template === 'one-line'">
		<div class="cm-header-container" itemscope itemtype="http://schema.org/Organization">
			<meta *ngIf="logo" itemprop="logo" [content]="logo.src" />
			<meta itemprop="url" [content]="siteUrl" />
			<meta itemprop="name" [content]="dealer" />
			<div class="container-fluid">
				<div class="row">
					<!--Logo-->
					<div class="col-12 col-md-3 logo">
						<a routerLink="/">
							<cm-image2 *ngIf="logo" [image]="logo"></cm-image2>
							<span *ngIf="!logo">{{ dealer }}</span>
						</a>

						<cm-menu
							*ngIf="mobile$ | async"
							[imgMenuData]="menus"
							[imgMenuAutocompleteData]="searchValues$ | async"
							[imgMenuAutocompleteOptions]="searchOptions"
						></cm-menu>
					</div>
					<ng-container *ngIf="!(mobile$ | async)">
						<div class="col-md-4">
							<div id="cm-global-header-menu">
								<cm-menu
									[imgMenuData]="menus"
									[imgMenuAutocompleteData]="searchValues$ | async"
									[imgMenuAutocompleteOptions]="searchOptions"
									itemscope
									itemtype="http://schema.org/SiteNavigationElement"
								></cm-menu>
							</div>
						</div>
						<div class="col-md-3 phone-number">
							<span class="label d-none d-lg-inline-block">Questions?</span>
							<strong class="theme-bg-as-fg">
								<cm-phone-number *ngIf="phoneNo" [phone]="phoneNo"></cm-phone-number>
							</strong>
						</div>
						<div class="col-md-2 d-print-none">
							<cm-autocomplete [searchItems]="searchValues$ | async" [options]="searchOptions">
							</cm-autocomplete>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</header>

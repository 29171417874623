import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "halls",
		homeBreadcrumb: "Boat Dealer",
		themeData: {
			dealerAccent: {
				bg: "#003a5d",
				bgHover: "#002840",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#e43d30",
					bgHover: "#c1352a",
					fg: "#FFF",
				},
				homeUrl: "",
				footerUrl: "",
			},
		},
	},
	font: {
		family: "'Roboto Condensed', sans-serif",
	},
};

import { DOCUMENT } from "@angular/common";
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { ISearchFilterOption } from "@model/search-filter-option";
import { combineLatest, Observable, Subscription } from "rxjs";
import { debounceTime, first, map, tap } from "rxjs/operators";
import { IFilterSetting, SearchFilterService, SearchFilterState } from "./search-filter.service";

@Component({
	selector: "cm-search-filter",
	templateUrl: "./search-filter.component.html",
	styleUrls: ["./search-filter.component.scss"],
})
export class SearchFilterComponent implements OnInit, OnChanges, OnDestroy {
	@Input() filters: any;
	@Input() salesPerson?: boolean = false;
	@Input() showButtons?: boolean = true;
	@Input() buttonsAbove?: boolean = true;
	@Input() defaultOpenCount: number = 2;
	@Input() filterSettings?: IFilterSetting[];
	@Input() searchUrl?: string;
	@Output() search = new EventEmitter();

	faMinusCircle = faMinusCircle;
	faPlusCircle = faPlusCircle;

	state: SearchFilterState;
	showFilter$: Observable<boolean>;
	initiallyExpandedSections$: Observable<string>;

	filterTrackByName = (i: number, f: ISearchFilterOption) => f.name;
	mobile$ = this.viewportService.mobile$;
	subs: Subscription[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private viewportService: ViewportService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		searchFilterService: SearchFilterService,
		@Inject("PAGE_DATA") pageData: IPageData,
	) {
		this.state = searchFilterService.createState(route);

		this.showFilter$ = combineLatest(this.viewportService.mobile$, this.state.filters$).pipe(
			map(([isMobile, filters]) => {
				if (isMobile) {
					return filters.hasOwnProperty("showFilter");
				} else {
					return true;
				}
			}),
		);

		this.initiallyExpandedSections$ = combineLatest(this.state.usedFiltersOptions$, this.state.filters$).pipe(
			debounceTime(300),
			map(([filterOptions, filters]) => {
				const filterKeys = Object.keys(filters);
				const activeFilterOptions = filterOptions.reduce<Set<string>>((acc, filterOption, i) => {
					const nameInQueryKeys = filterKeys.find((key) => key.indexOf(filterOption.name) >= 0);
					if (nameInQueryKeys) {
						acc.add(filterOption.key);

						// Add the next section too, so that it expands too
						const nextFilter = filterOptions[i + 1];
						if (nextFilter) {
							acc.add(nextFilter.key);
						}
					}
					return acc;
				}, new Set<string>());

				if (!activeFilterOptions.size) {
					activeFilterOptions.add(filterOptions[0].key);
					let count = 1;
					let index = 1;
					while (count < this.defaultOpenCount) {
						if (filterOptions[index]) {
							activeFilterOptions.add(filterOptions[index].key);
						}
						count++;
						index++;
					}
				}
				return Array.from(activeFilterOptions).join(",");
			}),
		);

		if (pageData.filterMode) {
			this.state.setFilterSettings(JSON.parse(pageData.filterMode));
		}

		this.subs.push(
			this.state.filters$
				.pipe(
					map((filters) => {
						if (filters.hasOwnProperty("showFilter")) {
							this.toggleFilterVisibilityForMobile(true);
						}
					}),
				)
				.subscribe(),
			combineLatest(this.showFilter$, this.viewportService.mobile$)
				.pipe(
					tap(([showFilter, isMobile]) => {
						this.doToggleFilterVisibilityForMobile(showFilter, isMobile);
					}),
				)
				.subscribe(),
		);
	}

	ngOnInit() {
		this.subs.push(this.state.search$.subscribe(() => this.search.emit()));
		this.state.search();
	}

	ngOnDestroy(): void {
		this.subs.forEach((s) => s.unsubscribe());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filterSettings) {
			this.state.setFilterSettings(this.filterSettings || null);
		}
		if (changes.filters) {
			this.state.setFilters(this.filters);
		}
		if (changes.salesPerson) {
			this.state.setSalesPerson(this.salesPerson || false);
		}
		if (changes.searchUrl) {
			this.state.setSearchUrl(this.searchUrl);
		}
	}

	doChange(filter: ISearchFilterOption, event: any) {
		this.state.pushToQueryParams(filter, event);
	}

	toggleFilterVisibilityForMobile(show: boolean) {
		this.route.queryParams
			.pipe(
				first(),
				tap((queryParams) => {
					const params = { ...queryParams };
					if (show) {
						if (!params.showFilter) {
							params.showFilter = true;
							this.router.navigate([this.state.prepareUrl()], { queryParams: params });
						}
					} else {
						if (params.showFilter) {
							delete params.showFilter;
							this.router.navigate([this.state.prepareUrl()], { queryParams: params });
						}
					}
				}),
			)
			.subscribe();
	}

	doToggleFilterVisibilityForMobile(show: boolean, isMobile: boolean) {
		if (isMobile) {
			this.renderer[show ? "addClass" : "removeClass"](this.document.body, "open-filter");
		} else {
			this.renderer.removeClass(this.document.body, "open-filter");
		}
	}

	doResetFilters() {
		this.toggleFilterVisibilityForMobile(false);
		this.state.resetQueryParams();
	}
}

import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { iter, tuple } from "@core/app/common/iter";
import { SMap } from "@core/app/common/map2";
import { RestService } from "@core/app/rest.service";
import { UserService } from "@core/app/user.service";
import { ToastrService } from "ngx-toastr";

let nextIdNum = 0;

@Component({
	selector: "cm-field-canned-response",
	templateUrl: "./field-canned-response.component.html",
	styleUrls: ["./field-canned-response.component.scss"],
})
export class FieldCannedResponseComponent {
	@Input() name!: string;
	@Input() disabled: boolean = false;
	@Input() hideCount: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() minLen?: number;
	@Input() maxLen?: number;
	@Input() required: boolean = false;
	@Input() value: string = "";

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	idNum: number;
	error: string | null = null;
	focused: boolean = false;
	cannedResponseCats: string[] = [];
	cannedResponses: SMap<string, ICannedResponse[]> | null = null;
	validated: boolean = false;

	constructor(toastrService: ToastrService, rest: RestService, userService: UserService) {
		this.idNum = nextIdNum++;

		this.loadCannedResponses(toastrService, rest, userService);
	}

	get id(): string {
		return `field-textarea-${this.idNum}`;
	}

	onCannedResponseClick(value: string) {
		this.value = value;
		this.valueChange.emit(this.value);
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}

	private async loadCannedResponses(toastrService: ToastrService, rest: RestService, userService: UserService) {
		let response;
		try {
			response = await rest
				.init("statement")
				.post(
					"getCannedResponses",
					{ vars: { userid: userService.get("userid") } },
					`CannedResponses:${userService.get("userid")}`,
				);
		} catch (ex: any) {
			toastrService.error("Failed to load canned responses", ex.error.message);
			return;
		}

		this.cannedResponses = iter<any>(response.results)
			.map((row) => tuple(row.cat as string, { text: row.text as string, value: row.value as string }))
			.toGroupMap();
		this.cannedResponseCats = iter(this.cannedResponses.keys()).toArray();
	}
}

interface ICannedResponse {
	text: string;
	value: string;
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ISearchFilterOption } from "@model/search-filter-option";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";

@Component({
	selector: "cm-predictive-selector",
	templateUrl: "./predictive-selector.component.html",
	styleUrls: ["./predictive-selector.component.scss"],
})
export class PredictiveSelectorComponent implements OnInit {
	// @ts-ignore
	@Input() filter: ISearchFilterOption;
	@Output() select = new EventEmitter();

	value = "";

	ngOnInit(): void {
		const filterKey = `${this.filter.name}`;
		const filterQuery = this.filter.queryParams[filterKey];
		if (filterQuery) {
			this.value = this.filter.results.filter((v) => parseInt(v.value) === parseInt(filterQuery))[0];
		}
	}

	search(result: NgbTypeaheadSelectItemEvent) {
		if (result && result.item) {
			this.value = this.filter.results.filter((v) => parseInt(v.value) === parseInt(result.item.value))[0];
			this.select.emit(result.item.value);
		} else {
			this.value = "";
			this.select.emit("");
		}
	}

	doTypeaheadSearch = (text: string): Observable<any> => {
		if (!text) {
			this.select.emit("");
			return of(this.filter.results);
		}
		return of(this.filter.results.filter((v) => v.label.toLowerCase().indexOf(text.toLowerCase()) > -1));
	};
}

import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class ShortCodeService {
	parse(text: any) {
		const objects: any = [];

		if (text !== undefined) {
			// let elementsFound: any = text.match(/(\[[^/][^[\]]+\])(([^[\]]+)(\[\/[^[\]]+\]))?/gm);

			// if (elementsFound) {
			//     for (const elem of elementsFound) {
			//         const e: any = elem.match(/(\[[^/][^[\]]+\])(([^[\]]+)(\[\/[^[\]]+\]))?/);

			//         let innerText: any = '';

			//         if (e[3] !== undefined) {
			//             innerText = e[3];
			//         }

			//         const params: any = {};
			//         const paramsFound: any = e[1].match(/[\w-]+="[^"]*"/gi);
			//         if (paramsFound) {
			//             for (const param of paramsFound) {
			//                 const attr = param.match(/([a-zA-Z0-9\-_]+)="(.{0,})"/);
			//                 params[attr[1]] = attr[2];
			//             }
			//         }

			//         const element: any = e[1].match(/\[([a-zA-Z0-9\-_]+)\s/);
			//         const elType: any = element ? element[1] : null;

			//         objects.push({
			//             type: elType,
			//             params: params,
			//             innerText: innerText,
			//             original: elem,
			//             image: false
			//         });
			//     }
			// }

			const elementsFound = text.match(/(<img [^/][^[>]+>)(([^[>]+)(\[\/[^[>]+))?/gm);

			if (elementsFound) {
				for (const elem of elementsFound) {
					const e: any = elem.match(/(<img [^/][^[>]+>)(([^[>]+)(\[\/[^[>]+>))?/);

					let innerText: any = "";

					if (e[3] !== undefined) {
						innerText = e[3];
					}

					const params: any = {};
					const paramsFound: any = e[1].match(/[\w-]+="[^"]*"/gi);

					if (paramsFound) {
						for (const param of paramsFound) {
							const attr: any = param.match(/([a-zA-Z0-9\-_]+)="(.{0,})"/);
							params[attr[1]] = attr[2];
						}
					}

					const element: any = e[1].match(/\[([a-zA-Z0-9\-_]+)\s/);

					objects.push({
						type: element ? element[1] : null,
						params: params,
						innerText: innerText,
						original: elem,
						image: true,
					});
				}
			}
		}

		return objects;
	}
}

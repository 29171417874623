<ng-container [ngSwitch]="state">
	<ng-container *ngSwitchCase="FormState.Loading">Loading form...</ng-container>
	<form #frm *ngSwitchCase="FormState.Loaded" class="cm-form" (ngSubmit)="onSubmitInternal()">
		<h2 class="form-title" *ngIf="formTitle" [innerHTML]="formTitle"></h2>
		<div class="form-desc mb-1" *ngIf="formIntro" [innerHTML]="formIntro"></div>

		<div *ngIf="paginated" class="btn-toolbar justify-center text-center mb-3">
			<mat-button-toggle-group>
				<mat-button-toggle
					class="{{ pageI < availablePage ? 'btn-success' : 'btn-dark' }}"
					[ngClass]="{ 'btn-active': pageI === activePage }"
					[disabled]="pageI > availablePage"
					value="{{ pageI }}"
					*ngFor="let _page of pages; index as pageI"
					[disabled]="pageI > availablePage"
					(click)="onPageClick(pageI)"
				>
					{{ pageI + 1 }}
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>

		<div *ngFor="let page of pages | slice: 0:maxPage; index as pageI" [hidden]="paginated && pageI !== activePage">
			<h2 *ngIf="page.title">{{ page.title }}</h2>
			<p *ngIf="page.intro" [innerHTML]="page.intro"></p>
			<ng-container *ngFor="let item of page.formItems">
				<ng-container *ngIf="item.isFormRow(); then formRow; else fieldset"></ng-container>

				<ng-template #formRow>
					<div class="form-row row">
						<div
							*ngFor="let field of item.val.fieldsVisible()"
							class="col w-full lg:w-{{ Math.floor(12 / item.val.fields.length) }}/12"
							[ngClass]="{ 'mb-3': !field.field.type || field.field.type !== 'hidden' }"
						>
							<cm-field *ngIf="field.visible" [field]="field.field"></cm-field>
						</div>
					</div>
				</ng-template>

				<ng-template #fieldset>
					<cm-fieldset
						[legend]="item.val.legend"
						[additive]="item.val.additive"
						[rows]="item.val.rows"
						[entries]="item.val.entries"
						[disabled]="item.val.disabled"
					></cm-fieldset>
				</ng-template>
			</ng-container>
		</div>

		<div class="form-row">
			<div *ngIf="paginated && availablePage === 0" class="form-group w-full text-center">
				<button
					type="button"
					class="border border-solid"
					color="warn"
					mat-raised-button
					(click)="onNextClick()"
				>
					{{ startText || nextText }}
				</button>
			</div>

			<div *ngIf="availablePage > 0" class="form-group w-1/2 text-center">
				<button
					type="button"
					class="border border-solid"
					color="warn"
					mat-raised-button
					[disabled]="activePage === 0"
					(click)="onPrevClick()"
				>
					{{ prevText }}
				</button>
			</div>

			<div *ngIf="availablePage > 0 && activePage < pages.length - 1" class="form-group w-1/2 text-center">
				<button
					type="button"
					class="border border-solid"
					color="warn"
					mat-raised-button
					(click)="onNextClick()"
				>
					{{ nextText }}
				</button>
			</div>

			<div
				*ngIf="activePage === pages.length - 1 || !paginated"
				class="form-group my-3 {{ activePage > 0 ? 'w-1/2' : 'w-full' }}"
			>
				<button mat-raised-button type="submit" color="warn" [class]="submitClass" [disabled]="submitDisabled">
					{{ submitTextOverride || submitText }}
				</button>
			</div>
		</div>
	</form>

	<ng-container *ngSwitchCase="FormState.Submitted"><div [innerHTML]="formThankYou"></div></ng-container>
</ng-container>

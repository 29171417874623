import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LogoutButtonComponent } from "./components/logout-button/logout-button.component";
import { SubmitButtonComponent } from "./components/submit-button/submit-button.component";
import { ButtonListComponent } from "../button-list/button-list.component";
import { ButtonComponent } from "./button.component";
import { ImageModule } from "../image/image.module";

@NgModule({
	declarations: [LogoutButtonComponent, SubmitButtonComponent, ButtonListComponent, ButtonComponent],
	imports: [CommonModule, FontAwesomeModule, ImageModule],
	exports: [LogoutButtonComponent, SubmitButtonComponent, ButtonListComponent, ButtonComponent],
})
export class ButtonModule {}

import { Component, Input, OnInit, OnChanges, SimpleChange, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "cm-button",
	templateUrl: "./button.component.html",
	styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit, OnChanges {
	buttonType: string = "button-only";
	@Input() obj: any = null;
	@Input() form: any = null;
	@Output() buttonClicked: EventEmitter<any> = new EventEmitter();

	setupButton(): void {
		if (this.obj && this.obj.hasOwnProperty("link") && this.obj.link) {
			this.buttonType = "linked-button";
		} else if (this.obj && this.obj.hasOwnProperty("type") && this.obj.type === "submit") {
			this.buttonType = "submit-button";
		} else {
			this.buttonType = "button-only";
		}
	}

	buttonClick(event: any) {
		if (this.obj.clicked) {
			if (this.obj.type === "submit") {
				this.obj.clicked(event, this.form, this.obj);
			} else {
				this.obj.clicked(event, this.obj);
			}
		}
		this.buttonClicked.emit(this.obj);
	}

	ngOnInit() {
		this.setupButton();
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.obj) {
			this.obj = changes.obj.currentValue;
			this.setupButton();
		}
		if (changes.form) {
			this.form = changes.form.currentValue;
		}
	}
}

<cm-field-text
	*ngIf="isText()"
	[type]="field.type"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[autocomplete]="field.autocomplete"
	[disabled]="field.disabled"
	[hideCount]="field.hideCount"
	[maxLen]="field.maxLen"
	[pattern]="field.pattern"
	[(value)]="field.value"
></cm-field-text>
<cm-field-text-multiple
	*ngIf="isTextMultiple()"
	[type]="field.type"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[autocomplete]="field.autocomplete"
	[disabled]="field.disabled"
	[hideCount]="field.hideCount"
	[maxLen]="field.maxLen"
	[pattern]="field.pattern"
	[(value)]="field.value"
></cm-field-text-multiple>
<cm-field-number
	*ngIf="isNumber()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[max]="field.max"
	[pattern]="field.pattern"
	[(value)]="field.value"
></cm-field-number>
<cm-field-textarea
	*ngIf="isTextarea()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[hideCount]="field.hideCount"
	[maxLen]="field.maxLen"
	[(value)]="field.value"
></cm-field-textarea>
<cm-field-canned-response
	*ngIf="isCannedResponse()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[hideCount]="field.hideCount"
	[maxLen]="field.maxLen"
	[(value)]="field.value"
></cm-field-canned-response>
<cm-field-month-year
	*ngIf="isMonthYear()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[disabled]="field.disabled"
	[(value)]="field.value"
></cm-field-month-year>
<cm-field-checkbox
	*ngIf="isCheckbox()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[options]="field.options"
	[disabled]="field.disabled"
	[(value)]="field.value"
></cm-field-checkbox>
<cm-field-radio
	*ngIf="isRadio()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[options]="field.options"
	[disabled]="field.disabled"
	[required]="field.required"
	[(value)]="field.value"
></cm-field-radio>
<cm-field-select
	*ngIf="isSelect()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[options]="field.options"
	[disabled]="field.disabled"
	[multiple]="field.multiple"
	[(value)]="field.value"
></cm-field-select>
<cm-field-file
	*ngIf="isFile()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	(valueChange)="onChangeFile($event)"
></cm-field-file>
<cm-field-file-multiple
	*ngIf="isFileMultiple()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	(valueChange)="onChangeFile($event)"
></cm-field-file-multiple>
<cm-field-date
	*ngIf="isDate()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[(value)]="field.value"
	[pattern]="field.pattern"
></cm-field-date>
<cm-field-datetime
	*ngIf="isDateTime()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[(value)]="field.value"
></cm-field-datetime>
<cm-field-line-item
	*ngIf="isListItem()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[required]="field.required"
	[disabled]="field.disabled"
	[(value)]="field.value"
></cm-field-line-item>
<cm-field-service-issue *ngIf="isServiceIssue()" (valueChange)="field.value = $event"></cm-field-service-issue>
<cm-field-user-auth-groups
	*ngIf="isUserAuthGroups()"
	[label]="field.label"
	[intro]="field.intro"
	[disabled]="field.disabled"
	(valueChange)="field.value = $event"
></cm-field-user-auth-groups>
<cm-field-compare
	*ngIf="isCompare()"
	[name]="field.name"
	[label]="field.label"
	[intro]="field.intro"
	[disabled]="field.disabled"
	(valueChange)="field.value = $event"
></cm-field-compare>

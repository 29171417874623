<div *ngIf="(showFilter$ | async)" class="cm-filter-list">
	<div *ngIf="showButtons && buttonsAbove" class="container-fluid">
		<div class="row filter-buttons mb-2">
			<div class="col-6 d-lg-none cm-toggle-filter">
				<button type="button" class="btn btn-primary w-100" (click)="toggleFilterVisibilityForMobile(false)">
					Apply Filter
				</button>
			</div>
			<div class="cm-filter-list-reset col-6 col-lg-12 text-right">
				<button
					type="button"
					class="btn btn-outline-secondary"
					[ngClass]="{ 'w-100': mobile$ | async }"
					(click)="doResetFilters()"
				>
					Clear Filter
				</button>
			</div>
		</div>
	</div>

	<ngb-accordion
		#accordion
		class="filter-options"
		[ngClass]="{ loading: loading$ | async }"
		[activeIds]="(initiallyExpandedSections$ | async) || ''"
	>
		<ngb-panel
			*ngFor="let filter of (state.usedFiltersOptions$ | async); trackBy: filterTrackByName"
			[id]="filter.key"
			[ngClass]="{ 'filter-open': filter.open, 'filter-closed': !filter.open }"
		>
			<ng-template ngbPanelTitle>
				<span class="filter-title" [innerHTML]="filter.label"></span>
				<span class="filter-toggle">
					<ng-container *ngIf="accordion.isExpanded(filter.key); then collapse; else expand"></ng-container>
					<ng-template #collapse>
						<span class="collapse-title mr-2">collapse</span>
						<fa-icon [icon]="faMinusCircle"></fa-icon>
					</ng-template>
					<ng-template #expand>
						<span class="collapse-title mr-2">expand</span>
						<fa-icon [icon]="faPlusCircle"></fa-icon>
					</ng-template>
				</span>
			</ng-template>
			<ng-template ngbPanelContent>
				<ng-container [ngSwitch]="filter.type">
					<cm-range-selector *ngSwitchCase="'range'" [range]="filter" (select)="doChange(filter, $event)">
					</cm-range-selector>
					<cm-range-input-selector
						*ngSwitchCase="'priceRange'"
						[range]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-range-input-selector>
					<cm-predictive-selector
						*ngSwitchCase="'predictive'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-predictive-selector>
					<cm-checkbox-selector
						*ngSwitchCase="'checkbox'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-checkbox-selector>
					<cm-keyword-selector
						*ngSwitchCase="'searchBox'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-keyword-selector>
					<cm-select-selector *ngSwitchCase="'select'" [filter]="filter" (select)="doChange(filter, $event)">
					</cm-select-selector>
					<ng-container *ngSwitchDefault>{{ filter | json }}</ng-container>
				</ng-container>
			</ng-template>
		</ngb-panel>
	</ngb-accordion>
	<div *ngIf="showButtons && !buttonsAbove" class="container-fluid">
		<div class="row filter-buttons mb-2">
			<div class="col-6 d-lg-none cm-toggle-filter">
				<button type="button" class="btn btn-primary w-100" (click)="toggleFilterVisibilityForMobile(false)">
					Apply Filter
				</button>
			</div>
			<div class="cm-filter-list-reset col-6 col-lg-12 text-right">
				<button
					type="button"
					class="btn btn-outline-secondary"
					[ngClass]="{ 'w-100': mobile$ | async }"
					(click)="doResetFilters()"
				>
					Clear Filter
				</button>
			</div>
		</div>
	</div>
</div>

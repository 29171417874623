import { Component, Input, OnChanges, SimpleChange, ViewEncapsulation } from "@angular/core";

@Component({
	selector: "cm-button-list",
	templateUrl: "./button-list.component.html",
	styleUrls: ["./button-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ButtonListComponent implements OnChanges {
	@Input() buttons: any = [];

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.buttons) {
			this.buttons = changes.buttons.currentValue;
		}
	}
}

import { Component, HostBinding, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";

let nextIdNum = 0;

@Component({
	selector: "cm-field-number",
	templateUrl: "./field-number.component.html",
	styles: [":host { display: block; }"],
})
export class FieldNumberComponent {
	@Input() name!: string;
	@Input() autocomplete?: string;
	@Input() disabled: boolean = false;
	@Input() hideCount: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() max?: number;
	@Input() required: boolean = false;
	@Input() pattern?: string | null;
	@Input() value?: string;

	@Output() valueChange = new EventEmitter<string>();

	@HostBinding("class.was-validated")
	get hostClassHasSuccess(): boolean {
		return this.validated;
	}

	@ViewChild("input", { static: true }) input!: ElementRef<HTMLInputElement>;

	idNum: number = 0;
	error: string | null = null;
	validated: boolean = false;

	constructor() {
		this.idNum = nextIdNum++;
	}

	get id(): string {
		return `field-number-${this.idNum}`;
	}

	get inputStyle(): { [key: string]: string } {
		// return { "max-width": `${this.max ? Math.round(Math.ceil(Math.log10(this.max)) * 1.1) : 24}ch` };
		return { "max-width": "24ch" };
	}

	validate() {
		if (!this.input.nativeElement.checkValidity()) {
			this.error = this.input.nativeElement.validationMessage;
		} else {
			this.error = null;
		}

		this.validated = true;
	}
}

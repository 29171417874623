import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
	faFacebook,
	faPinterest,
	faTwitter,
	faInstagram,
	faYoutube,
	faGoogle,
	faTumblr,
	IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { ISocialLink } from "@model/social-link";

@Component({
	selector: "cm-social-links",
	template: `
		<a
			*ngFor="let linkIcon of linkIcons"
			target="_blank"
			rel="noopener"
			itemprop="sameAs"
			class="social-link"
			[href]="linkIcon.link.link"
			[title]="linkIcon.link.desc"
			(click)="linkIcon.link.clicked && linkIcon.link.clicked($event, linkIcon)"
		>
			<span class="cm-fab">
				<fa-icon [icon]="linkIcon.icon" class="social-image-size"></fa-icon>
			</span>
		</a>
	`,
	styleUrls: ["./social-links.component.scss"],
})
export class SocialLinksComponent implements OnChanges {
	@Input() socialLinks!: ISocialLink[];

	linkIcons: ISocialLinkIcon[] = [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes.socialLinks) {
			this.linkIcons = (changes.socialLinks.currentValue as ISocialLink[]).map((link) => {
				let icon;

				switch (link.desc) {
					case "Facebook":
						icon = faFacebook;
						break;
					case "Pinterest":
						icon = faPinterest;
						break;
					case "Twitter":
						icon = faTwitter;
						break;
					case "Tumblr":
						icon = faTumblr;
						break;
					case "Instagram":
						icon = faInstagram;
						break;
					case "Youtube":
					case "YouTube":
						icon = faYoutube;
						break;
					case "Google":
					case "Google +":
						icon = faGoogle;
						break;
				}

				return { link, icon };
			});
		}
	}
}

interface ISocialLinkIcon {
	link: ISocialLink;
	icon?: IconDefinition;
}
